import React from 'react'

//All this nonsense because react-rte is not ssr
//compatible
const ClientSideOnlyLazy = React.lazy(props => import('./rte-editor.js'))
export default props => {
    const isSSR = typeof window === 'undefined'

    return (
        <>
            {!isSSR && (
                <React.Suspense
                    fallback={<textarea className='form-textarea w-full' defaultValue={props.value} />}
                >
                    <ClientSideOnlyLazy {...props} />
                </React.Suspense>
            )}
        </>
    )
}
