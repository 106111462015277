import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import FeedUrl from '../../components/feed-url.js'
import Icon from '../../components/icon'
import { formatDistanceToNow } from 'date-fns'
import Spinner2 from '../../components/spinner.js'
import ShowNotFound from '../../components/show-404.js'
import { formatUrl } from '../../util.js'
import ImportProgress from '../../components/import-progress.js'
import { useTranslation } from 'react-i18next'

const Overview = props => {
    const { show, episodes, loading } = useSelector(s => ({
        loading: s.shows.loading,
        show: s.shows.data[props.id],
        episodes: s.showEpisodes[props.id],
    }))
    const { t } = useTranslation()

    let epList = []
    let epLength = 0
    if (episodes) {
        epLength = Object.values(episodes.data).length
    }
    if (episodes && !episodes.loading) epList = Object.values(episodes.data).slice(0, 5)

    if (loading) return <Spinner2 />

    return (
        <div className=''>
            <h4 className='text-xl font-medium ml-2'>{t('Show Details')}</h4>
            <div className='bg-white shadow-xl border-t-4 border-gray-600 rounded-md mt-4 px-2 sm:px-8 pt-6 pb-12'>
                <div className='flex justify-end'>
                    <Link
                        replace
                        to={`/shows/${props.id}/settings`}
                        className='flex items-center border border-gray-400 hover:border-gray-900 rounded px-3 py-1 font-medium text-sm transition duration-150'
                    >
                        <Icon
                            className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-800'
                            name='pencil-alt'
                        />
                        Edit Show
                    </Link>
                </div>
                {show ? (
                    <div className='px-2'>
                        {show.showIsHidden && (
                            <div className='flex-center'>
                                <div className='py-px mx-auto rounded flex-center px-3 bg-yellow-100'>
                                    <svg
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                        className='eye-off w-4 h-4 text-yellow-500'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z'
                                            clipRule='evenodd'
                                        ></path>
                                        <path d='M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z'></path>
                                    </svg>
                                    <span className='inline-block text-center text-sm font-medium rounded px-3'>
                                        Show is Hidden
                                    </span>
                                </div>
                            </div>
                        )}
                        <ImportProgress show={show} />
                        <FeedUrl show={show} />
                        <div className='sm:flex sm:space-x-8 pt-8'>
                            <div className='flex flex-col items-center'>
                                <img
                                    className={`${
                                        !show.artworkUrl && 'opacity-0'
                                    } max-w-sm w-full h-full sm:w-56 sm:h-56 shadow-2xl rounded object-cover`}
                                    alt={show.title}
                                    src={show.artworkUrl}
                                />

                                <a
                                    className='text-sm text-gray-700 hover:text-teal-600 mt-4 italic font-medium'
                                    href={formatUrl(show.website)}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {show.website}
                                </a>
                                <span className='text-sm mt-2'>{show.copyright}</span>
                            </div>
                            <div className='flex-1 space-y-4'>
                                <h2 className='font-medium text-xl'>{show.title}</h2>
                                <div className='sm:flex justify-between items-center'>
                                    <span className='text-xs font-medium'>By: {show.author}</span>
                                    <ul className='text-xs font-medium flex items-center flex-wrap'>
                                        <span className='mr-2'>{t('Categories')}:</span>
                                        {show.category1 !== 'None' && (
                                            <li className='text-xs rounded-full leading-none text-black mr-1'>
                                                {show.category1}
                                            </li>
                                        )}
                                        {show.category2 !== 'None' && (
                                            <li className='text-xs rounded-full leading-none text-black mr-1'>
                                                , {show.category2}
                                            </li>
                                        )}
                                        {show.category3 !== 'None' && (
                                            <li className='text-xs rounded-full leading-none text-black mr-1'>
                                                , {show.category3}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className='pb-4 space-y-1'>
                                    <h3 className='uppercase tracking-wide text-xs font-medium text-gray-600'>
                                        {t('Description')}
                                    </h3>
                                    <div
                                        className='editor border border-gray-100 rounded py-3 px-4 text-sm'
                                        dangerouslySetInnerHTML={{
                                            __html: show.description || 'No description',
                                        }} //@Todo sanitize description
                                    ></div>
                                </div>
                                <div className='pb-8'>
                                    <h3 className='uppercase tracking-wide text-xs font-medium text-gray-600'>
                                        {t('Latest Episodes')}{' '}
                                        <span className='text-xs normal-case'>
                                            (Total {epLength})
                                        </span>
                                    </h3>
                                    <ul className='mt-1 space-y-1'>
                                        {epList.map(ep => {
                                            return (
                                                <li key={ep.id}>
                                                    <Link
                                                        to={`/shows/${props.id}/episodes/${ep.id}`}
                                                        className='max-w-xl flex justify-between hover:shadow text-xs py-2 bg-white border border-gray-100 shadow-sm rounded px-3'
                                                    >
                                                        <span>{ep.title}</span>
                                                        <span>
                                                            {formatDistanceToNow(
                                                                new Date(ep.releaseDate),
                                                                { addSuffix: true },
                                                            )}
                                                        </span>
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                        {episodes && epList.length === 0 && !episodes.loading && (
                                            <li>
                                                <Link
                                                    to={`/shows/${props.id}/create`}
                                                    className='my-4 mx-auto max-w-sm border border-gray-800 rounded px-6 py-2 hover:text-gray-800 text-white text-sm hover:bg-white bg-gray-800 group flex-center transition duration-150'
                                                >
                                                    <svg
                                                        fill='currentColor'
                                                        viewBox='0 0 20 20'
                                                        className='w-4 h-4 mr-1 text-gray-200 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                                                            clipRule='evenodd'
                                                        />
                                                    </svg>
                                                    <span>Add First Episode</span>
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <ShowNotFound />
                )}
            </div>
        </div>
    )
}

export default Overview
