import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { subscribeTeams } from '../state/actions.js'

//Subscribes to teams data
export const useTeams = props => {
    const auth = useSelector(s => s.auth)
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (!auth || !auth.data) return
        dispatch(subscribeTeams())
        return () => dispatch({ type: 'listener/cleanup' })
    }, [dispatch, auth])
    return null
}
