import React from 'react'
export default props => {
    return (
        <div className='text-sm'>
            <ul className=''>
                {props.value.map((v, i) => {
                    let title = v.title
                    if (props.formatTitle) title = props.formatTitle(title)

                    let value = v.value
                    if (props.formatValue) value = props.formatValue(value)

                    return (
                        <li
                            key={i}
                            className='hover:bg-gray-100 border-b border-gray-100 pl-6 pr-4 py-3 text-xs flex justify-between'
                        >
                            <div>{title}</div>
                            <div>
                                {value}
                                {props.unit}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
