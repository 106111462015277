import React from 'react'
import { Link } from 'gatsby'
import Icon from '../components/icon.js'
import PopupText from '../components/popup-text.js'
import SEO from '../components/seo.js'
import NoPermissions from '../components/no-permissions.js'
import prettyBytes from 'pretty-bytes'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../state/actions.js'
import { formatUnix } from '../util.js'
import { usePermissions } from '../hooks/permissions.js'
import { useTranslation } from 'react-i18next'

const nullUsage = {
    periodEnd: Math.round(new Date().getTime() / 1000),
    periodStart: Math.round(new Date().getTime() / 1000),
    bytesSent: 0,
}

export default props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { currentUsage, pastInvoices } = useSelector(s => ({
        currentUsage: s.currentUsage,
        pastInvoices: s.pastInvoices,
        showEpisodes: s.showEpisodes,
    }))

    const canView = usePermissions('usage:read')

    if (!currentUsage.data) currentUsage.data = nullUsage
    let billUSD = 0
    billUSD = Math.ceil((currentUsage.data.bytesSent || 0) / (1024 * 1024 * 1024 * 50))

    return (
        <div className='relative bg-white rounded-md shadow-xl mt-8 border-t-4 border-gray-600 pt-8 pb-12'>
            <SEO title={'Account Usage'} />
            <Link
                to='/settings/billing'
                className='flex items-center absolute top-0 right-0 mr-4 mt-4 text-sm border-b-2 border-gray-400 hover:border-teal-500 text-gray-500 hover:text-gray-800'
            >
                <Icon name='help' className='w-4 h-4 text-gray-500 mr-1' />
                {t('View Pricing')}
            </Link>
            <div className='mx-auto max-w-3xl text-center'>
                <h1 className='text-3xl'>{t('Usage')}</h1>
                <p className='text-sm text-gray-600'>
                    {t('Summary of the usage on your shows and invoices')}
                </p>
                {currentUsage.loading ? (
                    <div className='w-full mt-2 flex-center mx-auto'>
                        <svg className='h-8 w-8 spin' viewBox='0 0 24 24'>
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </div>
                ) : canView ? (
                    <>
                        <div>
                            <span className='inline-block text-sm text-red-500 uppercase tracking-wide mt-6'>
                                {formatUnix(currentUsage.data.periodStart)} -{' '}
                                {formatUnix(currentUsage.data.periodEnd)}
                                <span className='pl-1 text-xs uppercase tracking-wide'>
                                    ({t('Current Period')})
                                </span>
                            </span>
                            <ul className='flex justify-center items-center mt-4 '>
                                <li className='flex flex-col w-48 border-r'>
                                    <span className='text-2xl tracking-wide uppercase'>
                                        {prettyBytes(currentUsage.data.bytesSent || 0)}
                                    </span>
                                    <span className='text-sm text-gray-600'>
                                        {t('Bandwidth (Downloads)')}
                                    </span>
                                </li>
                                <div className='flex flex-col w-48'>
                                    <span className='text-2xl tracking-wide uppercase'>
                                        ${billUSD}
                                    </span>
                                    <span className='flex-center text-sm text-gray-600'>
                                        {t('Estimated Bill')}
                                        <PopupText
                                            position='right'
                                            textClass='text-left'
                                            text={t('estimate-explainer')}
                                            iconClass='ml-1 mt-2 h-4 w-4'
                                        />
                                    </span>
                                </div>
                            </ul>
                        </div>
                        <div className='mt-16 px-2'>
                            <h1 className='font-medium text-sm'>{t('Past Usage and Invoices')}</h1>
                            <table className='text-sm w-full mt-3 text-left'>
                                <thead className='border-b border-gray-300'>
                                    <tr>
                                        <th className='py-2 font-normal uppercase tracking-wide text-xs'>
                                            {t('Period')}
                                        </th>
                                        <th className='py-2 font-normal uppercase tracking-wide text-xs text-center'>
                                            {t('Bandwidth Used')}
                                        </th>
                                        <th className='py-2 font-normal uppercase tracking-wide text-xs text-center'>
                                            {t('Bill Amount')}
                                        </th>
                                        <th className='py-2 font-normal uppercase tracking-wide text-xs text-center'>
                                            {t('Invoice Link')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values(pastInvoices.data).map((u, index) => (
                                        <tr key={index}>
                                            <td className='py-4 border-b border-gray-200'>
                                                {formatUnix(u.periodEnd)} -{' '}
                                                {formatUnix(u.periodStart)}
                                            </td>
                                            <td className='py-3 border-b border-gray-200 text-sm text-center'>
                                                {u.quantity} GB
                                            </td>
                                            <td className='py-3 border-b border-gray-200 text-sm text-center'>
                                                ${u.billUSD}
                                            </td>
                                            <td className='py-3 border-b border-gray-200 text-center'>
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href={u.invoiceUrl}
                                                    className='block sm:inline border border-gray-200 sm:px-2 sm:py-1 rounded text-sm transition duration-150 hover:border-gray-900'
                                                >
                                                    {t('View Invoice')}
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='flex-center'>
                                <button
                                    onClick={() => dispatch(actions.loadPastInvoices())}
                                    disabled={
                                        pastInvoices.loading ||
                                        pastInvoices.loadingMore ||
                                        !pastInvoices.moreAvailable
                                    }
                                    className={`${
                                        pastInvoices.loadingMore ||
                                        pastInvoices.loading ||
                                        !pastInvoices.moreAvailable
                                            ? 'pointer-events-none'
                                            : ''
                                    } ${
                                        pastInvoices.moreAvailable ? '' : 'opacity-50'
                                    } relative border mt-3 px-4 py-1 focus:outline-none focus:border-gray-700 rounded text-sm`}
                                >
                                    {pastInvoices.loadingMore && (
                                        <div className='absolute inset-0 w-full flex-center mx-auto'>
                                            <svg className='h-6 w-6 spin' viewBox='0 0 24 24'>
                                                <path
                                                    className='text-gray-600'
                                                    fill='currentColor'
                                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                                />
                                                <path
                                                    className='text-gray-400'
                                                    fill='currentColor'
                                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                                />
                                            </svg>
                                        </div>
                                    )}
                                    <span
                                        className={`${
                                            pastInvoices.loadingMore ? 'opacity-0' : 'opacity-100'
                                        }`}
                                    >
                                        {t('Load More')}
                                    </span>
                                </button>
                            </div>
                            <div className='flex-center'>
                                {!pastInvoices.moreAvailable && (
                                    <span className='text-xs text-orange-500 pt-3'>
                                        {t('No more items to show')}
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <NoPermissions />
                )}
            </div>
        </div>
    )
}
