import React from 'react'
import { createEpisode, setEpisodeMedia, setEpisodeArtwork } from '../state/actions.js'
import { navigate } from 'gatsby'
import Editor from '../components/rich-text-editor.js'
import DatePicker from '../components/date-picker.js'
import SEO from '../components/seo.js'
import { useSelector } from 'react-redux'
import { noop, preventEnterSubmit } from '../util.js'
import { useLocalStorage } from '../hooks/localstorage.js'
import { useTranslation } from 'react-i18next'
import { isFuture } from 'date-fns'

//@Todo copy paste getExtension
const getExtension = file => {
    if (!file) return null
    const ext = file.name.split('.').pop()
    return ext
}

const CreateEpisode = props => {
    const { t } = useTranslation()
    const { activeTeamId, auth, show, episodesCount } = useSelector(s => {
        const result = {
            activeTeamId: s.activeTeamId,
            auth: s.auth,
            show: s.shows.data[props.id],
            episodesCount: s.showEpisodes[props.id],
        }
        if (s.showEpisodes[props.id]) {
            result.episodesCount = Object.keys(s.showEpisodes[props.id].data).length
        }
        return result
    })
    const [lastUsedSeasonNumber, setLastUsedSeasonNumber] = useLocalStorage(
        'lastUsedSeasonNumber',
        1,
    )
    const [data, setData] = React.useState({
        title: '',
        description: '',
        isExplicit: (show && !!show.isExplicit) || false,
        episodeType: 'full',
        seasonNumber: lastUsedSeasonNumber,
        episodeNumber: episodesCount + 1,
        episodeIsHidden: false,
    })

    const [advancedOptionsOpen, setAdvancedOptionsOpen] = React.useState(false)
    const [artworkUrl, setArtworkUrl] = React.useState(null)
    const [artworkFile, setArtworkFile] = React.useState(null)
    const [artworkError, setArtworkError] = React.useState(null)

    const [loading, setLoading] = React.useState(false)
    //@Todo new Date() should be set to closest clock hour as thats the
    //max precision that we support
    const [releaseDate, setReleaseDate] = React.useState(new Date())
    const [mediaFile, setMediaFile] = React.useState(null)
    const [mediaFileError, setMediaFileError] = React.useState(null)
    const [createEpisodeError, setCreateEpisodeError] = React.useState(null)
    const [mediaLocalUrl, setMediaLocalUrl] = React.useState(null)
    const [uploadProgress, setUploadProgress] = React.useState(0)
    const [uploadingArtwork, setUploadingArtwork] = React.useState(false)
    const mediaRef = React.useRef()

    const toggleAdvancedOptions = () => setAdvancedOptionsOpen(!advancedOptionsOpen)

    const handleDescriptionChange = value => {
        setData({ ...data, description: value.toString('html') })
    }

    const handleDateChange = date => setReleaseDate(date)

    const isReleaseInFuture = () => {
        try {
            return isFuture(releaseDate)
        } catch (err) {}
        return false
    }

    const handleChange = e => {
        if (e.target.name === 'mediaFile') {
            if (e.target.files && e.target.files.length) {
                const f = e.target.files[0]
                if (f.size > 200 * 1024 * 1024) {
                    setMediaFileError('File size must be less than 200MB')
                } else {
                    setMediaLocalUrl(URL.createObjectURL(f))
                    setMediaFile(f)
                    setMediaFileError(null)
                }
            }
        } else {
            let val = e.target.value
            if (e.target.type === 'checkbox') val = e.target.checked
            setData({ ...data, [e.target.name]: val })
        }
    }

    const handleArtworkChange = e => {
        if (e.target.name === 'artwork') {
            if (e.target.files && e.target.files.length) {
                const theFile = e.target.files[0]
                if (theFile.size > 2 * 1024 * 1024) {
                    setArtworkError('Image size must be less than 2MB')
                } else {
                    setArtworkUrl(URL.createObjectURL(theFile))
                    console.log('Setting artwork file:', theFile)
                    setArtworkFile(theFile)
                    setArtworkError(null)
                }
            }
        } else {
            let val = e.target.value
            if (e.target.type === 'checkbox') val = e.target.checked
            setData({ ...data, [e.target.name]: val })
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()

        if (!activeTeamId) return

        if (e.keyCode === 13) return false //Prevent accidental enter to create a new show

        //Set the duration before submitting
        if (mediaRef.current && mediaRef.current.duration) {
            data.mediaDuration = parseInt(mediaRef.current.duration)
        }

        if (mediaFile) {
            data.mediaSize = parseInt(mediaFile.size)
            data.mediaExtension = getExtension(mediaFile)
        }

        try {
            data.seasonNumber = parseInt(data.seasonNumber)
            setLastUsedSeasonNumber(data.seasonNumber)
            data.episodeNumber = parseInt(data.episodeNumber)
        } catch (err) {
            console.error('ERR parsing season data:', data)
            //@Todo handle error case
        }

        data.releaseDate = releaseDate.toISOString()

        data.createdBy = auth.data.id

        setLoading(true)
        setCreateEpisodeError(null)
        try {
            const episode = await createEpisode(activeTeamId, props.id, data)
            setUploadingArtwork(true)
            //@Todo error still keeps showing spinner
            await setEpisodeMedia(activeTeamId, props.id, episode.id, mediaFile, v =>
                setUploadProgress(v * 100),
            )

            await setEpisodeArtwork(activeTeamId, props.id, episode.id, artworkFile, v => {
                //@Todo set progress for artwork
            })
            setLoading(false)
            navigate(`/shows/${props.id}/episodes/${episode.id}`, { replace: true })
        } catch (err) {
            console.error('ERR creating episode:', err)
            setLoading(false)
            setCreateEpisodeError(err.message)
        }
        setLoading(false)
    }

    return (
        <div className='px-1'>
            <SEO title={t('Create Episode')} />
            <h2 className='text-xl font-medium'>{t('Create New Episode')}</h2>
            <form
                onKeyPress={preventEnterSubmit}
                onSubmit={handleSubmit}
                className='mt-4 rounded shadow-lg bg-white border-t-4 border-gray-800 pt-3 pb-6'
            >
                <div className='md:flex'>
                    <div className='flex-1 px-2 sm:px-0 sm:pl-6'>
                        <div className='flex flex-col'>
                            <label htmlFor='title' className='text-sm font-medium'>
                                {t('Title')}
                            </label>
                            <input
                                onBlur={noop}
                                name='title'
                                value={data.title}
                                onChange={handleChange}
                                className='mt-1 form-input'
                                placeholder={t('Episode Title')}
                                spellCheck='false'
                            />
                        </div>
                        <div className='mt-5 flex flex-col'>
                            <label htmlFor='description' className='text-sm font-medium'>
                                {t('Description')}
                            </label>
                            <Editor
                                className='mt-1 form-textarea px-1'
                                value={data.description}
                                height='h-48'
                                placeholder={t('Episode Description')}
                                onChange={handleDescriptionChange}
                                name='description'
                            />
                        </div>
                    </div>

                    <div className='flex flex-col px-6 flex-1 md:max-w-sm mx-auto'>
                        <label htmlFor='artwork' className='text-sm font-medium'>
                            {t('Audio')}
                        </label>

                        <div className='mt-2'>
                            <audio
                                ref={mediaRef}
                                src={mediaLocalUrl}
                                controls={true}
                                className={`${
                                    mediaLocalUrl ? '' : 'hidden'
                                } text-center text-sm font-medium w-full px-3 bg-gray-100 rounded overflow-hidden flex-center`}
                            >
                                <track kind='captions' />
                            </audio>

                            <div
                                className={`${
                                    mediaLocalUrl
                                        ? 'underline text-gray-600 hover:text-gray-900 font-medium'
                                        : 'rounded border border-dashed'
                                } overflow-hidden relative`}
                            >
                                <label
                                    className={`group text-center text-sm flex flex-col justify-center items-center px-4 ${
                                        mediaLocalUrl ? 'py-2' : 'py-6 rounded-lg'
                                    } shadow-lg cursor-pointer`}
                                >
                                    {mediaLocalUrl ? (
                                        <span className=''>{t('Change')}</span>
                                    ) : (
                                        <>
                                            <span className='flex-center ml-2 text-sm'>
                                                <svg
                                                    fill='none'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='2'
                                                    stroke='currentColor'
                                                    viewBox='0 0 24 24'
                                                    className={`${
                                                        mediaLocalUrl && 'hidden'
                                                    } text-gray-500 group-hover:text-gray-800 w-5 h-5 mr-2`}
                                                >
                                                    <path d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'></path>
                                                </svg>
                                                {t('Upload audio file')}
                                            </span>
                                            <span className='italic'>
                                                ({t('Recommended type .mp3 or .aac')})
                                            </span>
                                        </>
                                    )}
                                    <input
                                        onBlur={noop}
                                        onChange={handleChange}
                                        onClick={() => setMediaFileError(null)}
                                        name='mediaFile'
                                        type='file'
                                        className='hidden'
                                        accept='.mp3,.m4a,.aac,.mp4,.m4p,.m4r,.ogg,.oga,.wma'
                                    />
                                </label>
                            </div>
                            {mediaFileError && (
                                <p className='bg-red-100 shake text-red-800 text-sm rounded m-1 text-center'>
                                    {mediaFileError}
                                </p>
                            )}
                        </div>

                        <div className='mt-5 flex flex-col'>
                            <label htmlFor='releaseDate' className='text-sm font-medium'>
                                {t('Release Date')}
                            </label>
                            <DatePicker
                                className='border px-3 py-2 text-sm rounded mt-1 w-full'
                                name='releaseDate'
                                date={releaseDate}
                                onChange={handleDateChange}
                            />
                            {isReleaseInFuture() && (
                                <div className='rounded-sm mt-1 flex-center bg-yellow-50 text-gray-900 py-1'>
                                    <svg
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                        className='mx-2 w-9 h-9 text-yellow-300'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
                                            clipRule='evenodd'
                                        ></path>
                                    </svg>
                                    <span className='text-xs font-medium leading-4'>
                                        {t('scheduled-episode-explainer')}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className='mt-5 flex flex-col'>
                            <span className='text-sm font-medium'>{t('Parental Advisory')}</span>
                            <div className='mt-1 flex items-center'>
                                <label className='flex items-center'>
                                    <input
                                        onBlur={noop}
                                        className='mr-2 h-5 w-5 form-checkbox'
                                        name='isExplicit'
                                        checked={data.isExplicit}
                                        onChange={handleChange}
                                        type='checkbox'
                                    />
                                    {t('Contains Explicit Language')}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-3 pt-2 pb-4'>
                    <div className='flex justify-end'>
                        <button
                            type='button'
                            onClick={toggleAdvancedOptions}
                            className='px-2 py-1 text-sm focus:outline-none hover:text-blue-800 font-medium text-blue-500 flex-center leading-none'
                        >
                            <span>{t('Advanced Options')}</span>
                            <svg
                                className='w-4 h-4 ml-1'
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                            >
                                {advancedOptionsOpen ? (
                                    <path d='M5 15l7-7 7 7'></path>
                                ) : (
                                    <path d='M19 9l-7 7-7-7'></path>
                                )}
                            </svg>
                        </button>
                    </div>
                    {advancedOptionsOpen && (
                        <div className='md:flex px-3 md:space-x-4'>
                            <div className='flex-1'>
                                <div className='flex flex-col'>
                                    <label htmlFor='title' className='text-sm font-medium'>
                                        {t('Season No.')}
                                    </label>
                                    <input
                                        onBlur={noop}
                                        name='seasonNumber'
                                        type='number'
                                        value={data.seasonNumber}
                                        onChange={handleChange}
                                        className='mt-1 form-input'
                                        placeholder={t('1')}
                                    />
                                </div>
                                <div className='mt-5 flex flex-col'>
                                    <label htmlFor='title' className='text-sm font-medium'>
                                        {t('Episode No.')}
                                    </label>
                                    <input
                                        onBlur={noop}
                                        name='episodeNumber'
                                        type='number'
                                        value={data.episodeNumber}
                                        onChange={handleChange}
                                        className='mt-1 form-input'
                                        placeholder={t('1')}
                                    />
                                </div>
                                <div className='mt-5 flex flex-col'>
                                    <label htmlFor='episodeType' className='text-sm font-medium'>
                                        {t('Episode Type')}
                                    </label>
                                    <select
                                        onBlur={noop}
                                        name='episodeType'
                                        value={data.episodeType}
                                        onChange={handleChange}
                                        className='form-select text-sm mt-1'
                                    >
                                        <option value='full'>{t('Full')}</option>
                                        <option value='trailer'>{t('Trailer')}</option>
                                        <option value='bonus'>{t('Bonus')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex-shrink-0 w-full max-w-sm flex flex-col'>
                                <label htmlFor='artwork' className='text-sm font-medium'>
                                    {t('Episode Artwork')}
                                </label>

                                <div className='mt-1'>
                                    {artworkUrl && (
                                        <img
                                            name='artwork'
                                            alt='artwork'
                                            className='mx-auto h-56 w-56 object-cover'
                                            src={artworkUrl}
                                        />
                                    )}

                                    <div
                                        className={`${
                                            artworkUrl ? '' : 'border border-dashed h-56'
                                        } mx-auto overflow-hidden relative rounded`}
                                    >
                                        <label
                                            className={`${
                                                artworkUrl ? '' : 'py-6'
                                            } h-full text-center text-sm flex flex-col justify-center items-center rounded-lg shadow-lg cursor-pointer`}
                                        >
                                            {artworkUrl ? (
                                                <span className='block ml-2 text-sm'>
                                                    {t('Change')}
                                                </span>
                                            ) : (
                                                <>
                                                    <svg
                                                        fill='currentColor'
                                                        viewBox='0 0 20 20'
                                                        className='text-teal-500 w-8 h-8'
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z'
                                                            clipRule='evenodd'
                                                        />
                                                    </svg>
                                                    <span className='mt-2'>
                                                        Upload a file or drag and drop
                                                    </span>
                                                    <span className='block ml-2 text-sm'>
                                                        PNG or JPG <br />(
                                                        {t('Recommended size 3000X3000')})
                                                        <br /> ({t('Size must be less than 2MB')})
                                                    </span>
                                                </>
                                            )}
                                            <input
                                                onBlur={noop}
                                                onChange={handleArtworkChange}
                                                name='artwork'
                                                type='file'
                                                className='hidden'
                                                accept='.png,.jpg,.jpeg'
                                            />
                                        </label>
                                    </div>
                                    {artworkError && (
                                        <p className='bg-red-100 shake text-red-800 text-sm rounded m-1 text-center'>
                                            {artworkError}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {createEpisodeError && (
                    <p className='max-w-xl mx-auto bg-red-100 px-4 shake font-medium text-sm rounded mx-1 my-2 text-center'>
                        {createEpisodeError}
                    </p>
                )}
                <div className='border-t border-gray-200 flex justify-center sm:justify-between items-center px-6 pt-4'>
                    <div className='hidden sm:flex justify-center items-center text-xs'>
                        <svg
                            className='w-5 h-5 text-teal-400 mr-1'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                        </svg>
                        <span>{t('You can change all this later in settings too.')}</span>
                    </div>
                    <div>
                        <button
                            type='button'
                            onClick={() => window.history.back()}
                            disabled={loading}
                            className={`${loading ? 'pointer-events-none text-gray-300' : ''}
                                transition duration-150 font-medium mr-3 rounded px-6 py-2 focus:outline-none focus:shadow-outline text-sm hover:bg-gray-100`}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            type='submit'
                            disabled={loading}
                            className={`${
                                loading ? 'pointer-events-none' : ''
                            } relative transition duration-150 font-medium border rounded px-6 py-2 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white ${
                                uploadProgress > 99.9
                                    ? 'border-green-400 bg-green-400'
                                    : 'border-gray-800 bg-gray-800'
                            }`}
                        >
                            <span className='absolute inset-0 w-full h-full flex-center'>
                                <svg
                                    className={`h-6 w-6 spin ${
                                        loading ? 'opacity-100' : 'opacity-0'
                                    }`}
                                    viewBox='0 0 24 24'
                                >
                                    <path
                                        className='text-gray-600'
                                        fill='currentColor'
                                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                    />
                                    <path
                                        className='text-gray-400'
                                        fill='currentColor'
                                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                    />
                                </svg>
                            </span>
                            <span>
                                {uploadingArtwork
                                    ? `${t('Uploading')}: ${uploadProgress.toFixed(0)}%`
                                    : isReleaseInFuture()
                                    ? t('Schedule Episode')
                                    : t('Create Episode')}
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateEpisode
