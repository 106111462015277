import { useStaticQuery, graphql } from 'gatsby'
export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    links {
                        twitter
                    }
                }
            }
        }
    `)

    return data.site.siteMetadata
}
