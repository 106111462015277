import React from 'react'
import { truncate } from '../../util.js'
const ReferrerLabel = ({ title }) => {
    return (
        <a className='hover:border-gray-700 border-b border-transparent' href={title}>
            {truncate(title, 40)}
        </a>
    )
}
export default ReferrerLabel
