import React from 'react'
import Header from './header.js'
import Footer from './footer.js'
import BillingWarning from '../components/billing-warning.js'
import EmailWarning from '../components/email-warning.js'
import { useTranslation } from 'react-i18next'

export default props => {
    const { i18n } = useTranslation()
    let fontClass = ''
    if (i18n.language === 'jp') {
        fontClass = 'font-jp'
    }
    return (
        <div className={`${fontClass} flex flex-col min-h-screen w-full font-body`}>
            <div className='mb-36'>
                <Header />
            </div>
            <div className='space-y-2'>
                <BillingWarning />
                <EmailWarning />
            </div>
            <main className='flex-1 min-h-55vh h-full w-full max-w-5xl mx-auto'>
                {props.children}
            </main>
            <Footer />
        </div>
    )
}
