import React from 'react'
import { Router } from '@reach/router'
import Settings from '../p/settings'
import Show from '../p/show.js'
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'
import Spinner from '../components/spinner.js'
import NotFoundPage from '../components/404.js'
import ShowAnalytics from '../p/show-analytics.js'
import EpisodeAnalytics from '../p/episode-analytics.js'
import ShowUsage from '../p/show-usage.js'
import ShowOverview from '../components/show/overview.js'
import Shows from '../p/shows.js'
import Episode from '../p/episode.js'
import EpisodeSettings from '../p/episode-settings'
import ShowSettings from '../p/show-settings'
import CreateShow from '../p/create-show.js'
import ImportShow from '../p/import-show.js'
import ImportShowSuccess from '../p/import-show-success.js'
import CreateEpisode from '../p/create-episode.js'
import Episodes from '../components/episode/episodes.js'
import Usage from '../p/usage.js'
import Activity from '../p/activity.js'
import { useSelector } from 'react-redux'
import { useLoginRedirect } from '../hooks/auth.js'
import { useTeams } from '../hooks/teams.js'
import { useAccountData } from '../hooks/account.js'
import { useShowDeleted } from '../hooks/show.js'
import '../lib/i18n.js'

export default props => {
    const auth = useSelector(s => s.auth)

    useTeams()
    useAccountData()
    useLoginRedirect(props)

    //@Todo this should ideally be in the delete show page components???
    useShowDeleted(props)

    if (auth.initting || auth.loading || !auth.data) return <Spinner />
    return (
        <Layout>
            <SEO title='App' />
            <Router>
                <ImportShow path='/import' />
                <ImportShowSuccess path='/import-success' />
                <CreateShow path='/create' />
                <Show path='/shows/:id'>
                    <Episodes path='/episodes' />
                    <ShowAnalytics path='/analytics' />
                    <ShowUsage path='/usage' />
                    <ShowSettings path='/settings'>
                        <ShowSettings.General path='/' />
                        <ShowSettings.Website path='/website' />
                        <ShowSettings.Advanced path='/advanced' />
                    </ShowSettings>
                    <CreateEpisode path='/create' />
                    <ShowOverview path='/' />
                    <Episode path='/episodes/:epId'>
                        <EpisodeAnalytics path='/analytics' />
                        <EpisodeSettings path='/settings'>
                            <EpisodeSettings.General path='/' />
                            <EpisodeSettings.Advanced path='/advanced' />
                        </EpisodeSettings>
                        <Episode.Overview path='/' />
                    </Episode>
                </Show>

                <Activity path='/activity' />

                <Usage path='/usage' />

                <Settings path='/settings'>
                    <Settings.General path='/' />
                    <Settings.Teams path='/teams' />
                    <Settings.Billing path='/billing' />
                </Settings>
                <Shows path='/' />
                <NotFoundPage default />
            </Router>
        </Layout>
    )
}
