import React from 'react'
import { useSelector } from 'react-redux'
import { isUrl } from '../util'
import ParsedEpisode from '../components/import-episode.js'
import Error from '../components/error.js'

const ParseFeedResult = ({ loading, feed, onReset, onImport, error }) => {
    const auth = useSelector(s => s.auth)
    const isSameOwner = () => {
        try {
            return feed.itunes.owner.email === auth.data.email
        } catch (err) {}
        return false
    }
    return (
        <div className='mt-4 flex flex-col rounded shadow-lg bg-white border-t-4 border-gray-800 py-3'>
            <Error msg={error} />
            <div className='text-right mx-4 py-1'>
                {!isSameOwner() && (
                    <p className='inline-block py-1 text-teal-500 text-sm italic'>
                        By importing you confirm that you have the right to import this show's
                        content.
                    </p>
                )}
                <div className='flex justify-end items-center rounded'>
                    <button
                        disabled={!feed || loading}
                        onClick={onReset}
                        className={`${!feed || loading ? 'pointer-events-none' : ''} ${
                            loading ? 'opacity-0' : 'opacity-100'
                        } font-medium rounded px-6 mr-2 py-2 hover:text-gray-900 text-gray-700 text-sm hover:bg-gray-200 group flex-center transition duration-150`}
                    >
                        <span>Reset</span>
                    </button>
                    <button
                        disabled={loading}
                        onClick={onImport}
                        className={`${
                            loading ? 'pointer-events-none' : ''
                        } relative border border-gray-800 rounded px-6 py-2 hover:text-gray-800 text-white text-sm hover:bg-white bg-gray-800 group flex-center transition duration-150`}
                    >
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${loading ? 'opacity-100' : 'opacity-0'}`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                        <span
                            className={`${loading ? 'opacity-0' : 'opacity-100'} flex items-center`}
                        >
                            <svg
                                fill='currentColor'
                                viewBox='0 0 20 20'
                                className='w-4 h-4 mr-1 text-gray-200 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                            >
                                <path
                                    fillRule='evenodd'
                                    d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                                    clipRule='evenodd'
                                />
                            </svg>
                            <span>Import This Show</span>
                        </span>
                    </button>
                </div>
            </div>
            <div className='mt-3'>
                <div className='flex'>
                    <div className='flex flex-col items-center'>
                        {feed.image && (
                            <img
                                className='ml-4 mt-2 w-64 object-cover rounded-lg h-64'
                                src={feed.image.url}
                                alt={feed.image.title}
                            />
                        )}
                        <a
                            className='w-64 text-center truncate text-sm text-gray-700 hover:text-teal-600 mt-2 italic font-medium'
                            href={feed.link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {feed.link}
                        </a>
                        {isUrl(feed.copyright) ? (
                            <a href={feed.copyright} className='text-sm mt-2'>
                                {feed.copyright}
                            </a>
                        ) : (
                            <span className='text-sm mt-2'>{feed.copyright}</span>
                        )}
                    </div>
                    <div className='flex-1 pl-4 overflow-hidden'>
                        <h2 className='font-semibold text-xl py-1'>{feed.title}</h2>
                        {feed.itunes && (
                            <>
                                <h3 className='text-sm font-medium'>{feed.itunes.subtitle}</h3>
                                <span className='inline-block text-xs font-medium pb-2'>
                                    By: {feed.itunes.author}
                                </span>
                            </>
                        )}
                        <p
                            className='text-sm pt-3 pb-5 max-w-xl'
                            dangerouslySetInnerHTML={{ __html: feed.description }}
                        />
                        <ul className='text-xs font-medium flex items-center'>
                            <span className='mr-2'>Categories:</span>
                            {feed.itunes &&
                                feed.itunes.categories &&
                                feed.itunes.categories.map((c, i) => (
                                    <li
                                        key={`${c}-${i}`}
                                        className='text-xs rounded-full leading-none bg-green-100 text-green-900 mr-1 py-1 px-3'
                                    >
                                        {c}
                                    </li>
                                ))}
                        </ul>
                        <div className='overflow-hidden mt-3 space-y-2'>
                            <h4 className='pl-1 font-medium'>
                                Episodes <span className='text-sm'>({feed.items.length})</span>
                            </h4>
                            <ul className='rounded h-128 space-y-1 pb-1 border border-gray-100 overflow-auto'>
                                {feed.items.map(i => (
                                    <li
                                        key={i.guid}
                                        className='text-xs py-2 border border-gray-100 shadow-sm rounded px-3'
                                    >
                                        <ParsedEpisode episode={i} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParseFeedResult
