import React from 'react'
import * as d3 from 'd3-scale'

const Map = props => {
    const { series = [] } = props
    const map = React.useRef()
    var dataset = {}

    var onlyValues = Object.values(series)
    let minValue = Math.min.apply(null, onlyValues)
    let maxValue = Math.max.apply(null, onlyValues)

    var paletteScale = d3.scaleLinear().domain([minValue, maxValue]).range(['#dadafb', '#02386F']) // blue color
    Object.entries(series).forEach(item => {
        let [iso, value] = item
        dataset[iso] = { value, fillColor: paletteScale(value) }
    })

    React.useEffect(() => {
        if (!map || map.current) return
        //Nested import coz datamaps requires
        //window which makes gatsby mad
        import('datamaps').then(module => {
            const Datamap = module.default
            try {
                map.current = new Datamap({
                    element: document.getElementById('map-container'),
                    responsive: true,
                    fills: {
                        defaultFill: '#e3e4ece6',
                    },
                    data: dataset,
                    geographyConfig: {
                        borderWidth: 1,
                        borderOpacity: 1,
                        borderColor: '#FDFDFD',
                        popupTemplate: function (geography, data) {
                            //@Todo fix clipping on the edge of the border
                            return `
                            <div class="absolute bottom-0 right-0 bg-white shadow px-3 py-1 rounded-sm text-xs">
                            <div style="width: 150px;" class="flex w-full justify-between items-center">
                                <span class="mr-6">${geography.properties.name}</span>
                                <strong class="ml-6">${data.value}</strong>
                            </div>
                            </div>
                        `
                        },
                        popupOnHover: true, // True to show the popup while hovering
                        highlightOnHover: true,
                        highlightFillColor: '#FC8D59',
                        highlightBorderColor: 'rgba(250, 15, 160, 0.2)',
                        highlightBorderWidth: 2,
                        highlightBorderOpacity: 1,
                    },
                })
            } catch (err) {
                //Something this things crashes for no reason
            }
        })
    }, [map, dataset])
    return <div id='map-container' className='h-full w-full relative' />
}

export default Map
