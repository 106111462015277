import React from 'react'
import { useOnClickOutside } from '../../hooks/on-click-outside.js'
const Container = props => {
    const [helpOpen, setHelpOpen] = React.useState(false)
    const ref = React.useRef()
    useOnClickOutside(ref, () => {
        setHelpOpen(false)
    })
    return (
        <div
            className={`bg-white border border-gray-200 shadow-lg ${props.className} rounded overflow-hidden`}
        >
            {props.title && (
                <div className='bg-gray-50 px-5 py-3 flex justify-between'>
                    <h3 className='font-medium text-sm'>{props.title}</h3>
                    {props.helpText && (
                        <div ref={ref} className='group text-gray-400 relative'>
                            {helpOpen && (
                                <p
                                    style={{ minWidth: '200px' }}
                                    className={`inline absolute right-0 mr-6 bg-gray-800 px-3 py-2 text-white text-xs rounded z-40 transition duration-300`}
                                >
                                    {props.helpText}
                                </p>
                            )}
                            <button
                                className='h-5 w-5 active:text-teal-500 hover:text-teal-500 focus:outline-none'
                                onClick={() => setHelpOpen(!helpOpen)}
                            >
                                <svg
                                    className='h-5 w-5'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                >
                                    <path d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
            )}
            {props.children}
        </div>
    )
}
export default Container
