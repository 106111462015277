import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SEO from '../components/seo.js'
import NoPermissions from '../components/no-permissions.js'
import * as actions from '../state/actions.js'
import { usePermissions } from '../hooks/permissions.js'
import { useTranslation } from 'react-i18next'
import { formatDistanceToNow, format } from 'date-fns'
import groupBy from 'lodash.groupby'

const makeDate = str => {
    let dateStr = ''
    try {
        dateStr = formatDistanceToNow(new Date(str), { addSuffix: true })
    } catch (err) {
        dateStr = Date.parse(str)
    }
    return dateStr
}

const groupItems = list => {
    let result = list
    try {
        result = groupBy(list, item => item.created.substring(0, 7))
    } catch (err) {}
    return result
}

export default props => {
    const { t } = useTranslation()
    const canView = usePermissions('account:manage')
    const { activity, auth } = useSelector(s => ({ auth: s.auth, activity: s.activity }))

    const dispatch = useDispatch()

    const rawList = Object.values(activity.data).sort((a, b) => (a.created < b.created ? 1 : -1))
    let actList = groupItems(rawList)
    const lastItem = rawList[rawList.length - 1]
    const renderList = () => {
        if (!canView) return <NoPermissions />
        try {
            return (
                <div>
                    {Object.entries(actList).map(([month, list]) => {
                        return (
                            <div className='mt-8' key={month}>
                                <h4 className='font-semibold text-base py-2'>
                                    {format(new Date(month), 'MMMM YYY')}
                                </h4>
                                <ul>
                                    {list.map(e => {
                                        return (
                                            <li
                                                key={e.id}
                                                className='my-2 border-b border-gray-200 pb-2 text-sm'
                                            >
                                                <div className=''>
                                                    <div className='sm:flex justify-between sm:px-4'>
                                                        <div>
                                                            <span className='font-medium'>
                                                                {e.actorId === auth.data.id
                                                                    ? 'You'
                                                                    : e.actorName}
                                                            </span>
                                                            <span className='pl-1'>{e.text}</span>
                                                        </div>
                                                        <div>
                                                            <span className='text-xs text-gray-500'>
                                                                {makeDate(e.created)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            )
        } catch (err) {
            return (
                <ul className='mt-8'>
                    {rawList.map(e => {
                        return (
                            <li key={e.id} className='my-2 border-b border-gray-200 pb-2 text-sm'>
                                <div className=''>
                                    <div className='flex justify-between px-4'>
                                        <div>
                                            <span className='font-medium'>
                                                {e.actorId === auth.data.id ? 'You' : e.actorName}
                                            </span>
                                            <span className='pl-1'>{e.text}</span>
                                        </div>
                                        <div>
                                            <span className='text-xs text-gray-500'>
                                                {makeDate(e.created)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            )
        }
    }

    return (
        <div className='mt-8 border-t-4 border-gray-600 shadow-xl bg-white rounded-md px-2 sm:px-10 py-8'>
            <SEO title={t('Account Activity')} />
            <h1 className='text-3xl text-center'>{t('Activity')}</h1>
            <p className='text-sm text-gray-600 text-center'>
                {t('Recent activity from your account')}
            </p>
            {activity.loading ? (
                <div className='w-full mt-2 flex-center mx-auto'>
                    <svg className='h-6 w-6 spin' viewBox='0 0 24 24'>
                        <path
                            className='text-gray-600'
                            fill='currentColor'
                            d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                        />
                        <path
                            className='text-gray-400'
                            fill='currentColor'
                            d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                        />
                    </svg>
                </div>
            ) : (
                renderList()
            )}
            {canView && (
                <div className='flex-center'>
                    <button
                        onClick={() => dispatch(actions.loadMoreActivity(lastItem))}
                        disabled={
                            activity.loading || activity.loadingMore || !activity.moreAvailable
                        }
                        className={`${
                            activity.loadingMore || activity.loading || !activity.moreAvailable
                                ? 'pointer-events-none'
                                : ''
                        } ${
                            activity.moreAvailable ? '' : 'opacity-50'
                        } relative border mt-3 px-4 py-1 focus:outline-none focus:border-gray-700 rounded text-sm`}
                    >
                        {activity.loadingMore && (
                            <div className='absolute inset-0 w-full flex-center mx-auto'>
                                <svg className='h-6 w-6 spin' viewBox='0 0 24 24'>
                                    <path
                                        className='text-gray-600'
                                        fill='currentColor'
                                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                    />
                                    <path
                                        className='text-gray-400'
                                        fill='currentColor'
                                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                    />
                                </svg>
                            </div>
                        )}
                        <span className={`${activity.loadingMore ? 'opacity-0' : 'opacity-100'}`}>
                            {t('Load More')}
                        </span>
                    </button>
                </div>
            )}
            <div className='flex-center'>
                {!activity.moreAvailable && (
                    <span className='text-xs text-orange-500 pt-3'>
                        {t('No more items to show')}
                    </span>
                )}
            </div>
        </div>
    )
}
