import React from 'react'
import { formatDate, sanitizeHtml } from '../util'

const ParsedEpisode = ({ episode }) => {
    const [open, setOpen] = React.useState(false)
    const epHtml = sanitizeHtml(episode['content:encoded'])
    return (
        <div>
            <button
                onClick={() => setOpen(!open)}
                className='flex justify-between w-full focus:outline-none'
            >
                <span>{episode.title}</span>
                <span>
                    <svg
                        className='w-4 h-4 text-gray-300 hover:text-gray-700'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                    >
                        {open ? <path d='M5 15l7-7 7 7'></path> : <path d='M19 9l-7 7-7-7'></path>}
                    </svg>
                </span>
            </button>
            <div className={`${open ? 'block' : 'hidden'}`}>
                <span className='inline-block py-1'>Published: {formatDate(episode.pubDate)}</span>
                <p className='ml-1' dangerouslySetInnerHTML={{ __html: epHtml }} />
            </div>
        </div>
    )
}

export default ParsedEpisode
