import React from 'react'
import '../../node_modules/react-vis/dist/style.css'
import { useLocalStorage } from '../hooks/localstorage.js'
import { useOnClickOutside } from '../hooks/on-click-outside.js'
import * as actions from '../state/actions.js'
import { Spinner2 } from '../components/spinner.js'
import SEO from '../components/seo.js'
import ListView from '../components/charting/listview.js'
import { usePermissions } from '../hooks/permissions.js'
import prettyBytes from 'pretty-bytes'
import NoPermissions from '../components/no-permissions.js'
import { formatDistanceToNow } from 'date-fns'
import { useTranslation } from 'react-i18next'
import countryCodes from '../data/countries.js'
import { truncate, noop } from '../util.js'
import { FlexibleXYPlot, XAxis, YAxis, HorizontalGridLines, AreaSeries, Hint } from 'react-vis'

const Container = props => {
    const [helpOpen, setHelpOpen] = React.useState(false)
    const ref = React.useRef()
    useOnClickOutside(ref, () => setHelpOpen(false))
    return (
        <div
            className={`bg-white border border-gray-200 shadow-lg ${props.className} rounded overflow-hidden`}
        >
            {props.title && (
                <div className='bg-gray-50 px-5 py-3 flex justify-between'>
                    <h3 className='font-medium text-sm'>{props.title}</h3>
                    {props.helpText && (
                        <div ref={ref} className='group text-gray-400 relative'>
                            {helpOpen && (
                                <p
                                    style={{ minWidth: '200px' }}
                                    className={`inline absolute right-0 mr-6 bg-gray-800 px-3 py-2 text-white text-xs rounded z-40 transition duration-300`}
                                >
                                    {props.helpText}
                                </p>
                            )}
                            <button
                                className='h-5 w-5 active:text-teal-500 hover:text-teal-500 focus:outline-none'
                                aria-label='toggle help'
                                onClick={() => setHelpOpen(!helpOpen)}
                            >
                                <svg
                                    className='h-5 w-5'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                >
                                    <path d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
            )}
            {props.children}
        </div>
    )
}

const emptyAnalytics = {
    totalDownloads: 0,
    bytesSent: 0,
    topDevices: [],
    topReferrers: [],
    topCountries: [],
    downloadsSeries: [],
    bandwidthSeries: [],
}

export default props => {
    const canView = usePermissions('analytics:read')
    const { t } = useTranslation()
    const [range, setRange] = useLocalStorage('analyticsRange', 'last30')
    const { analytics, loading } = actions.useEpisodeAnalytics(props.id, props.epId, range)

    let data = emptyAnalytics
    if (analytics) data = analytics

    let topCountries = data.topCountries || []
    topCountries = topCountries.sort((a, b) => b.value - a.value)
    const [hovered, setHovered] = React.useState(null)
    const yMax = React.useMemo(() => {
        if (!data.downloadSeries) return 1000
        const max = data.downloadSeries.reduce((acc, row) => Math.max(acc, row.y), -Infinity)
        return max + max * 0.1 //Add some top buffer
    }, [data.downloadSeries])

    const renderReferrer = title => {
        return (
            <a className='hover:border-gray-700 border-b border-transparent' href={title}>
                {truncate(title, 40)}
            </a>
        )
    }

    let updatedAt = new Date()
    if (analytics && analytics.updatedAt) updatedAt = new Date(analytics.updatedAt)

    return (
        <div className='mx-4 space-y-3'>
            <SEO title={t('Episode Analytics')} />
            <div className='sm:flex justify-between items-center'>
                <h4 className='text-xl font-medium ml-2'>
                    {t('Episode Analytics')}{' '}
                    <sup className='text-xs uppercase tracking-wide text-teal-500'>Beta</sup>
                </h4>
                <div className='flex items-center justify-end'>
                    <span className='text-xs text-gray-500 px-3 italic'>
                        Updated {formatDistanceToNow(updatedAt, { addSuffix: true })}
                    </span>
                    <select
                        onBlur={noop}
                        onChange={e => setRange(e.target.value)}
                        value={range}
                        className='form-select text-sm py-2'
                    >
                        <option value='last30'>{t('Last 30 Days')}</option>
                        <option value='last7'>{t('Last 7 Days')}</option>
                        <option value='last6m'>{t('Last 6 Months')}</option>
                        <option value='last1y'>{t('Last 1 Year')}</option>
                        <option value='lifetime'>{t('Lifetime')}</option>
                    </select>
                </div>
            </div>
            {loading ? (
                <Spinner2 />
            ) : canView ? (
                <div className='sm:grid grid-cols-12 grid-rows-1 gap-2'>
                    <Container className='col-span-12'>
                        <div className='px-4 py-0 flex-center divide-x divide-gray-200'>
                            <div className='flex-1 text-sm py-4 text-center'>
                                <span className='uppercase tracking-wide text-xs font-medium text-gray-600'>
                                    {t('Unique Downloads')}
                                </span>
                                <h4 className='text-2xl sm:text-5xl text-gray-700'>
                                    {data.totalDownloads}
                                </h4>
                            </div>
                            <div className='flex-1 text-sm py-4 text-center'>
                                <span className='uppercase tracking-wide text-xs font-medium text-gray-600'>
                                    {t('Bandwidth Used')}
                                </span>
                                <h4 className='flex-center text-2xl sm:text-5xl text-gray-700'>
                                    {prettyBytes(data.bytesSent)}
                                </h4>
                            </div>
                        </div>
                    </Container>
                    <Container
                        className='col-span-8'
                        title={t('Unique Downloads')}
                        helpText={t('Total number of downloads in given time range')}
                    >
                        <div className='px-3 h-96'>
                            {data.downloadSeries && data.downloadSeries.length > 0 ? (
                                <FlexibleXYPlot
                                    onMouseLeave={event => {
                                        setHovered(null)
                                    }}
                                    yDomain={[0, yMax]}
                                    getX={d => new Date(d.x)}
                                    xType='time-utc'
                                >
                                    <HorizontalGridLines />
                                    <XAxis tickLabelAngle={0} tickTotal={3} />
                                    <YAxis
                                        tickFormat={val => (Math.round(val) === val ? val : '')}
                                    />
                                    <AreaSeries
                                        onNearestX={(datapoint, event) => {
                                            setHovered(datapoint)
                                        }}
                                        data={data.downloadSeries}
                                    />
                                    {hovered && <Hint value={hovered} />}
                                </FlexibleXYPlot>
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='col-span-4' title={t('Top Countries')}>
                        <div className='h-full'>
                            {topCountries.length > 0 ? (
                                <ListView
                                    value={topCountries}
                                    unit='%'
                                    formatTitle={t => countryCodes[t] || 'N/A'}
                                    formatValue={v => v.toFixed(1)}
                                />
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='col-span-6 h-80' title={t('Top Referrers')}>
                        <div className='h-full overflow-y-auto'>
                            {data.topReferrers && data.topReferrers.length > 0 ? (
                                <ListView
                                    value={data.topReferrers}
                                    unit=''
                                    formatTitle={t => renderReferrer(t)}
                                />
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='col-span-6 h-80' title={t('Top Devices')}>
                        <div className='h-full overflow-y-auto'>
                            {data.topDevices && data.topDevices.length > 0 ? (
                                <ListView
                                    value={data.topDevices}
                                    unit='%'
                                    formatValue={v => v.toFixed(2)}
                                />
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='col-span-12' title={t('Bandwidth Used (MB)')}>
                        <div className='px-3 h-80'>
                            {data.bandwidthSeries && data.bandwidthSeries.length > 0 ? (
                                <FlexibleXYPlot
                                    onMouseLeave={event => {
                                        setHovered(null)
                                    }}
                                    getX={d => new Date(d.x)}
                                    getY={d => d.y / (1024 * 1024)}
                                    xType='time-utc'
                                >
                                    <HorizontalGridLines />
                                    <XAxis tickLabelAngle={0} tickTotal={3} />
                                    <YAxis
                                        tickFormat={val => (Math.round(val) === val ? val : '')}
                                    />
                                    <AreaSeries
                                        onNearestX={(datapoint, event) => {
                                            setHovered(datapoint)
                                        }}
                                        data={data.bandwidthSeries}
                                    />
                                    {hovered && (
                                        <Hint value={hovered}>
                                            <div className='grid grid-cols-2 row-gap-1 col-gap-1 items-end bg-gray-900 text-white text-xs space-y-1 px-2 py-1 rounded'>
                                                <h3 className='text-right'>Bandwidth:</h3>
                                                <h3>{prettyBytes(hovered.y)}</h3>
                                                <p className='text-right'>Date:</p>
                                                <p>{hovered.x}</p>
                                            </div>
                                        </Hint>
                                    )}
                                </FlexibleXYPlot>
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            ) : (
                <NoPermissions />
            )}
        </div>
    )
}
