import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { formatDistanceToNow } from 'date-fns'
import ExplicitTag from '../components/explicit-tag'
import NoPermissions from '../components/no-permissions.js'
import { usePermissions } from '../hooks/permissions.js'
import { useTranslation } from 'react-i18next'

const LoadingState = props => {
    return (
        <div className='relative bg-white shadow-xl border-t-4 border-gray-600 rounded-md mt-8 mx-2 px-8 py-12 flex flex-col justify-center items-center'>
            <span className='absolute inset-0 w-full h-full flex-center'>
                <svg className={`h-6 w-6 spin opacity-100`} viewBox='0 0 24 24'>
                    <path
                        className='text-gray-600'
                        fill='currentColor'
                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                    />
                    <path
                        className='text-gray-400'
                        fill='currentColor'
                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                    />
                </svg>
            </span>
        </div>
    )
}

const EmptyShows = props => {
    return (
        <div className='bg-white shadow-xl border-t-4 border-gray-600 rounded-md mt-8 mx-2 px-8 py-12 flex flex-col items-center'>
            <div className='flex items-center'>
                <div className='flex-center w-16 h-16 rounded-full bg-gray-100 text-gray-500 mr-1'>
                    <svg fill='currentColor' viewBox='0 0 20 20' className='w-6 h-6'>
                        <path
                            fillRule='evenodd'
                            d='M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z'
                            clipRule='evenodd'
                        />
                    </svg>
                </div>
                <span className='text-xl text-gray-600 ml-1 '>No Shows</span>
            </div>
            <p className='text-center text-sm text-gray-600 mt-2'>
                You haven't added any shows yet.
                <br /> Try creating one or import an existing podcast
            </p>
            <div className='block w-full sm:flex justify-center items-center mt-8'>
                <Link
                    to='/import'
                    className='mb-2 sm:mb-0 sm:mr-3 border border-gray-300 rounded px-6 py-2 hover:border-gray-800 text-sm text-gray-600 hover:text-gray-900 group flex-center transition duration-150'
                >
                    <svg
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        className='w-4 h-4 mr-1 text-gray-400 group-hover:text-gray-500 transition duration-300 transform group-hover:rotate-90'
                    >
                        <path
                            fillRule='evenodd'
                            d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                            clipRule='evenodd'
                        />
                    </svg>
                    <span>Import Show</span>
                </Link>
                <Link
                    to='/create'
                    className='border border-gray-800 rounded px-6 py-2 hover:text-gray-800 text-white text-sm hover:bg-white bg-gray-800 group flex-center transition duration-150'
                >
                    <svg
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        className='w-4 h-4 mr-1 text-gray-200 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                    >
                        <path
                            fillRule='evenodd'
                            d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                            clipRule='evenodd'
                        />
                    </svg>
                    <span>Create Show</span>
                </Link>
            </div>
        </div>
    )
}
const NotAllowed = props => {
    return (
        <div className='bg-white shadow-xl border-t-4 border-gray-600 rounded-md mt-8 mx-2 px-8 py-12 flex flex-col items-center'>
            <div className='mx-auto max-w-2xl text-center'>
                <h1 className='text-3xl'>Shows</h1>
            </div>
            <NoPermissions />
        </div>
    )
}

export default props => {
    const canView = usePermissions('shows:manage')
    const shows = useSelector(s => s.shows)
    const { t } = useTranslation()

    if (shows.loading) return <LoadingState />
    if (!canView) return <NotAllowed />

    const showsList = Object.values(shows.data)

    if (!showsList.length) return <EmptyShows />

    const formatUpdatedAt = show => {
        if (!show || !show.updatedAt) return null

        try {
            const ago = formatDistanceToNow(new Date(show.updatedAt), { addSuffix: true })
            return `Updated ${ago}`
        } catch (err) {}

        return null
    }

    return (
        <>
            <ul className='px-3 sm:px-0 flex flex-col sm:flex-row items-center justify-center sm:space-x-6 mt-2 sm:mt-8 mb-8'>
                {showsList.map(show => (
                    <li
                        key={show.id}
                        className='my-3 border border-gray-200 rounded overflow-hidden shadow max-w-sm sm:max-w-xs w-full hover:shadow-xl transition duration-150 flex flex-col'
                    >
                        <Link
                            to={`/shows/${show.id}`}
                            className='flex-1 overflow-hidden w-full h-full relative pb-2/3'
                        >
                            <img
                                alt={show.title}
                                src={show.artworkUrl}
                                className='absolute inset-0 w-full h-full object-cover'
                            />
                        </Link>
                        <div className='pt-6 pb-3'>
                            <div className='px-4'>
                                <h2 className='font-medium text-lg flex flex-wrap items-center'>
                                    <Link
                                        to={`/shows/${show.id}`}
                                        className='border-b border-transparent hover:border-gray-700'
                                    >
                                        {show.title}
                                    </Link>
                                    <ExplicitTag isExplicit={show.isExplicit} className='ml-1' />
                                </h2>
                                <p className='text-sm'>By {show.author}</p>
                            </div>
                            <div className='px-4 flex justify-end'>
                                <p className='text-xs'>{formatUpdatedAt(show)}</p>
                            </div>
                        </div>
                        <div className='text-sm py-3 px-4 flex justify-center bg-gray-200'>
                            <a
                                href={`https://podcasts.mono.fm/${show.websiteEndpoint}`}
                                className='flex-1 text-xs font-medium transition duration-150 group hover:text-white hover:bg-gray-800 text-gray-600 bg-gray-200 px-3 py-1 rounded flex-center'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <svg
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                    className='w-5 h-5 mr-1 text-gray-400 transition duration-300 transform group-hover:rotate-45'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                <span className='pr-1'>{t('View Website')}</span>
                            </a>
                            <a
                                href={show.feedUrl}
                                className='flex-1 text-xs font-medium transition duration-150 group hover:text-white hover:bg-gray-800 text-gray-600 bg-gray-200 px-3 py-1 rounded flex-center'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <svg
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                    className='w-5 h-5 mr-1 text-gray-400 transition duration-300 transform group-hover:rotate-45'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                <span className='pr-1'>{t('View RSS Feed')}</span>
                            </a>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}
