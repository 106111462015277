import React from 'react'
import { Link } from 'gatsby'
import Icon from '../components/icon.js'
import { useTranslation } from 'react-i18next'
const EpisodeNotFound = props => {
    const { t } = useTranslation()
    return (
        <div className='flex flex-col items-center'>
            <div className='flex items-center'>
                <div className='flex-center w-16 h-16 rounded-full bg-gray-100 text-gray-600 mr-1'>
                    404
                </div>
                <span className='text-xl text-gray-600 ml-1 '>{t('Episode Not Found')}</span>
            </div>
            <p className='text-center text-sm text-gray-600 mt-2'>
                We couldn't find this episode. Please recheck the url or
                <br />
                try changing your teams on top-left.
            </p>
            <div className='flex-center mt-8'>
                <Link
                    to='/'
                    replace
                    className='mr-3 border border-gray-300 rounded px-6 py-2 hover:border-gray-800 text-sm text-gray-600 hover:text-gray-900 group flex-center transition duration-150'
                >
                    <Icon
                        className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-800'
                        name='home'
                    />
                    <span>{t('Back Home')}</span>
                </Link>
            </div>
        </div>
    )
}
export default EpisodeNotFound
