import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import ExplicitTag from '../../components/explicit-tag'
import Icon from '../../components/icon'
import { isScheduledEpisode } from '../../util.js'
import { format } from 'date-fns'
const EpisodeCard = ({ id, episode }) => {
    const { t } = useTranslation()
    let dur = ''
    try {
        dur = new Date(episode.mediaDuration * 1000).toISOString().substr(11, 8)
    } catch (err) {
        dur = episode.mediaDuration
    }
    return (
        <Link
            to={`/shows/${id}/episodes/${episode.id}`}
            className='relative bg-gray-50 inline-block rounded border-l-4 border-gray-500 hover:border-gray-800 shadow hover:shadow-xl transition duration-150 px-4 py-3 w-full overflow-hidden'
        >
            <div className='flex items-center justify-between'>
                <h2 className='font-medium flex items-center'>
                    {episode.title} <ExplicitTag isExplicit={episode.isExplicit} className='ml-1' />
                </h2>
                <Link
                    to={`/shows/${id}/episodes/${episode.id}/settings`}
                    className='flex items-center border border-gray-200 hover:border-gray-900 rounded px-2 text-xs transition duration-150'
                >
                    <Icon
                        className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-800'
                        name='pencil-alt'
                    />
                    Edit
                </Link>
            </div>
            <div className='flex items-center justify-between'>
                <p className='text-sm'>
                    {isScheduledEpisode(episode) ? 'Releasing on ' : 'Released on '}{' '}
                    {format(new Date(episode.releaseDate), 'PPp')}
                    {isScheduledEpisode(episode) && (
                        <span className='ml-1 mx-2 bg-green-100 px-2 py-1 rounded-full leading-none font-medium text-xs text-black'>
                            {t('Scheduled')}
                        </span>
                    )}
                </p>
                <span className='text-sm'>{dur}</span>
            </div>
        </Link>
    )
}

export default EpisodeCard
