import React from 'react'
import copy from 'copy-to-clipboard'

export default (opts = {}) => {
    const [copied, setCopied] = React.useState(false)

    const timeout = opts.timeout || 2000

    React.useEffect(() => {
        if (copied && timeout > 0) {
            const _timer = setTimeout(() => {
                setCopied(false)
            }, timeout)
            return () => clearTimeout(_timer)
        }
    }, [copied, timeout])

    const copyToClipboard = text => {
        const didCopy = copy(text)
        setCopied(didCopy)
    }

    return [copied, copyToClipboard]
}

/*
 Usage:
    import useClipboard from './clipboard.js'
    const [copied, setCopied] = useClipboard()
*/
