import React from 'react'
import { categoryForOptions } from '../data/categories.js'
import languages from '../data/languages.js'
import { createShow, setShowArtwork } from '../state/actions.js'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import Editor from '../components/rich-text-editor.js'
import SEO from '../components/seo.js'
import Error from '../components/error.js'
import { noop, preventEnterSubmit, getExtensionFromFileObject } from '../util.js'
import { useTranslation } from 'react-i18next'

const CreateShow = props => {
    const categories = categoryForOptions()
    const { t } = useTranslation()
    const { activeTeamId, auth } = useSelector(s => ({
        activeTeamId: s.activeTeamId,
        auth: s.auth,
    }))

    const handleSubmit = async e => {
        e.preventDefault()

        if (!activeTeamId) return

        if (e.keyCode === 13) return false //Prevent accidental enter to create a new show

        setCreateShowError(null)
        setLoading(true)
        try {
            if (artworkFile) {
                data.artworkExtension = getExtensionFromFileObject(artworkFile)
            }
            data.createdBy = auth.data.id
            const show = await createShow(data, activeTeamId)
            setUploadingArtwork(true)
            //@Todo do something with this value (Progress bar?)
            await setShowArtwork(activeTeamId, show.id, artworkFile, value =>
                setUploadProgress(value * 100),
            )
            setLoading(false)
            navigate(`/shows/${show.id}`, { replace: true })
        } catch (err) {
            setCreateShowError(err.message)
        }
        setLoading(false)
    }

    const [data, setData] = React.useState({
        title: '',
        description: '',
        language: 'en',
        showType: 'episodic',
        category1: 'Arts',
        category2: 'None',
        category3: 'None',
        isExplicit: true,
        website: '',
        copyright: '',
        isRedirected: false,
        author: auth.data.name,
        email: auth.data.email,
        showIsHidden: false,
    })

    const [advancedOptionsOpen, setAdvancedOptionsOpen] = React.useState(false)
    const [artworkUrl, setArtworkUrl] = React.useState(null)
    const [artworkFile, setArtworkFile] = React.useState(null)
    const [createShowError, setCreateShowError] = React.useState(null)
    const [artworkError, setArtworkError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [uploadingArtwork, setUploadingArtwork] = React.useState(false)
    const [uploadProgress, setUploadProgress] = React.useState(0)

    const toggleAdvancedOptions = () => setAdvancedOptionsOpen(!advancedOptionsOpen)
    const handleDescriptionChange = value => {
        setData({ ...data, description: value.toString('html') })
    }
    const handleChange = e => {
        if (e.target.name === 'artwork') {
            if (e.target.files && e.target.files.length) {
                const theFile = e.target.files[0]
                if (theFile.size > 2 * 1024 * 1024) {
                    setArtworkError('Image size must be less than 2MB')
                } else {
                    setArtworkUrl(URL.createObjectURL(theFile))
                    setArtworkFile(theFile)
                    setArtworkError(null)
                }
            }
        } else {
            let val = e.target.value
            if (e.target.type === 'checkbox') val = e.target.checked
            setData({ ...data, [e.target.name]: val })
        }
    }
    return (
        <div className='px-1'>
            <SEO title='Create Show' />
            <h2 className='text-xl font-medium'>{t('Create New Show')}</h2>
            <form
                onKeyPress={preventEnterSubmit}
                onSubmit={handleSubmit}
                className='mt-4 flex flex-col rounded shadow-lg bg-white border-t-4 border-gray-800 pt-3 pb-6'
            >
                <div className='md:flex px-2 sm:px-6 py-2'>
                    <div className='flex-1'>
                        <div className='md:flex items-center md:space-x-8'>
                            <div className='flex-1 mt-3 md:mt-0 flex flex-col'>
                                <label htmlFor='title' className='text-sm font-medium'>
                                    {t('Show Title')}
                                </label>
                                <input
                                    onBlur={noop}
                                    name='title'
                                    spellCheck='false'
                                    value={data.title}
                                    onChange={handleChange}
                                    className='mt-1 form-input'
                                    placeholder={t('Show Title')}
                                />
                            </div>
                            <div className='flex-1 mt-3 md:mt-0 flex flex-col'>
                                <label htmlFor='category1' className='text-sm font-medium'>
                                    {t('Category')}
                                </label>
                                <select
                                    onBlur={noop}
                                    name='category1'
                                    value={data.category1}
                                    onChange={handleChange}
                                    className='mt-1 form-select'
                                >
                                    {categories.map(cat => (
                                        <option key={cat}>{cat}</option>
                                        //@Todo translate category names
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='mt-3 flex flex-col'>
                            <label htmlFor='description' className='text-sm font-medium'>
                                {t('Description')}
                            </label>

                            <Editor
                                className='mt-1 form-textarea px-1'
                                value={data.description}
                                height='h-48'
                                placeholder={t('Show Description')}
                                onChange={handleDescriptionChange}
                                name='description'
                            />
                        </div>
                    </div>
                </div>

                <div className='px-3 pt-2 pb-4'>
                    <div className='flex justify-end'>
                        <button
                            type='button'
                            onClick={toggleAdvancedOptions}
                            className='px-2 py-1 text-sm focus:outline-none hover:text-blue-800 font-medium text-blue-500 flex-center leading-none'
                        >
                            <span>{t('Advanced Options')}</span>
                            <svg
                                className='w-4 h-4 ml-1'
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                            >
                                {advancedOptionsOpen ? (
                                    <path d='M5 15l7-7 7 7'></path>
                                ) : (
                                    <path d='M19 9l-7 7-7-7'></path>
                                )}
                            </svg>
                        </button>
                    </div>
                    {advancedOptionsOpen && (
                        <div className='md:flex px-3 md:space-x-8'>
                            <div className='md:w-1/2 flex flex-col'>
                                <label htmlFor='artwork' className='text-sm font-medium'>
                                    {t('Artwork')}
                                </label>

                                <div className='mt-1'>
                                    {artworkUrl && (
                                        <img
                                            name='artwork'
                                            alt='artwork'
                                            className='mx-auto h-56 w-56 object-cover'
                                            src={artworkUrl}
                                        />
                                    )}

                                    <div
                                        className={`${
                                            artworkUrl ? '' : 'border border-dashed h-56'
                                        } mx-auto overflow-hidden relative rounded`}
                                    >
                                        <label
                                            className={`${
                                                artworkUrl ? '' : 'py-6'
                                            } h-full text-center text-sm flex flex-col justify-center items-center rounded-lg shadow-lg cursor-pointer`}
                                        >
                                            {artworkUrl ? (
                                                <span className='block ml-2 text-sm'>
                                                    {t('Change')}
                                                </span>
                                            ) : (
                                                <>
                                                    <svg
                                                        fill='currentColor'
                                                        viewBox='0 0 20 20'
                                                        className='text-teal-500 w-8 h-8'
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z'
                                                            clipRule='evenodd'
                                                        />
                                                    </svg>
                                                    <span className='mt-2'>
                                                        {t('Upload a file or drag and drop')}
                                                    </span>
                                                    <span className='block ml-2 text-sm'>
                                                        PNG or JPG <br />(
                                                        {t('Recommended size 3000X3000')})
                                                        <br /> {t('Size must be less than 2MB')}
                                                    </span>
                                                </>
                                            )}
                                            <input
                                                onBlur={noop}
                                                onChange={handleChange}
                                                name='artwork'
                                                type='file'
                                                className='hidden'
                                                accept='.png,.jpg,.jpeg'
                                            />
                                        </label>
                                    </div>
                                    <Error msg={artworkError} />
                                </div>

                                <div className='flex mt-4'>
                                    <div className='w-full flex flex-col'>
                                        <label htmlFor='website' className='text-sm font-medium'>
                                            {t('Website')}
                                        </label>
                                        <div className='mt-1 rounded overflow-hidden border'>
                                            <input
                                                name='website'
                                                value={data.website}
                                                onChange={handleChange}
                                                className='w-full form-input text-sm rounded-none border-none'
                                                placeholder='https://yoursite.com'
                                                spellCheck='false'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-3 md:mt-0 md:w-1/2'>
                                <div className='flex flex-col'>
                                    <label htmlFor='language' className='text-sm font-medium'>
                                        {t('Language')}
                                    </label>
                                    <select
                                        onBlur={noop}
                                        name='language'
                                        className='w-full mt-1 text-sm form-select'
                                        value={data.language}
                                        onChange={handleChange}
                                    >
                                        {languages.map(l => (
                                            <option key={l.code} value={l.code}>
                                                {l.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='mt-4 flex flex-col'>
                                    <label htmlFor='showType' className='text-sm font-medium'>
                                        {t('Show Type')}
                                    </label>
                                    <div className='mt-1 flex items-center'>
                                        <select
                                            onBlur={noop}
                                            name='showType'
                                            value={data.showType}
                                            onChange={handleChange}
                                            className='w-full form-select text-sm mt-1'
                                        >
                                            <option value='episodic'>{t('Episodic')}</option>
                                            <option value='serial'>{t('Serial')}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='mt-4 flex flex-col'>
                                    <label htmlFor='author' className='text-sm font-medium'>
                                        {t('Author')}
                                    </label>
                                    <input
                                        onBlur={noop}
                                        name='author'
                                        value={data.author}
                                        onChange={handleChange}
                                        spellCheck='false'
                                        className='mt-1 form-input text-sm'
                                        placeholder={t("Author's Name")}
                                    />
                                </div>
                                <div className='mt-4 flex flex-col'>
                                    <label htmlFor='email' className='text-sm font-medium'>
                                        {t('Email')}
                                    </label>
                                    <input
                                        onBlur={noop}
                                        name='email'
                                        type='email'
                                        value={data.email}
                                        onChange={handleChange}
                                        className='mt-1 form-input text-sm'
                                        spellCheck='false'
                                        placeholder={t("Author's email")}
                                    />
                                </div>
                                <div className='mt-4 flex flex-col'>
                                    <span className='text-sm font-medium'>
                                        {t('Parental Advisory')}
                                    </span>
                                    <div className='mt-1 flex items-center'>
                                        <label className='flex items-center'>
                                            <input
                                                onBlur={noop}
                                                className='mr-2 h-5 w-5 form-checkbox'
                                                name='isExplicit'
                                                checked={data.isExplicit}
                                                onChange={handleChange}
                                                type='checkbox'
                                            />
                                            {t('Is Explicit')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Error msg={createShowError} />
                <div className='flex justify-center sm:justify-between items-center px-6 pt-4 border-t border-gray-300'>
                    <div className='hidden sm:flex justify-center items-center text-xs'>
                        <svg
                            className='w-5 h-5 text-teal-400 mr-1'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                        </svg>
                        <span>{t('change-later-explainer')}</span>
                    </div>
                    <div>
                        <button
                            type='button'
                            onClick={() => window.history.back()}
                            disabled={loading}
                            className={`${loading ? 'pointer-events-none text-gray-300' : ''}
                                transition duration-150 font-medium mr-3 rounded px-6 py-2 focus:outline-none focus:shadow-outline text-sm hover:bg-gray-100`}
                        >
                            {t('Cancel')}
                        </button>
                        <button
                            type='submit'
                            disabled={loading}
                            className={`${
                                loading ? 'pointer-events-none' : ''
                            } relative transition duration-150 font-medium border rounded px-6 py-2 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white ${
                                uploadProgress > 99.9
                                    ? 'border-green-400 bg-green-400'
                                    : 'border-gray-800 bg-gray-800'
                            }`}
                        >
                            <span className='absolute inset-0 w-full h-full flex-center text-xs'>
                                <svg
                                    className={`h-6 w-6 spin ${
                                        loading ? 'opacity-100' : 'opacity-0'
                                    }`}
                                    viewBox='0 0 24 24'
                                >
                                    <path
                                        className='text-gray-600'
                                        fill='currentColor'
                                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                    />
                                    <path
                                        className='text-gray-400'
                                        fill='currentColor'
                                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                    />
                                </svg>
                            </span>
                            <span>
                                {uploadingArtwork
                                    ? `${t('Uploading')}: ${uploadProgress.toFixed(0)}%`
                                    : t('Submit')}
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateShow
