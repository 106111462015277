import React from 'react'
import { Link } from 'gatsby'
import useClipboard from '../hooks/clipboard.js'
import SEO from '../components/seo.js'
import ImportProgress from '../components/import-progress.js'
import useQueryParams from '../hooks/query.js'
import { useShow } from '../hooks/show.js'

export default props => {
    let { feedUrl, showId } = props

    const qParams = useQueryParams()
    if (qParams.feedUrl) feedUrl = qParams.feedUrl
    if (qParams.id) showId = qParams.id

    const show = useShow(showId)
    const [copied, setCopied] = useClipboard()
    const handleCopy = () => feedUrl && setCopied(feedUrl)

    return (
        <div className='mt-4 flex flex-col rounded shadow-lg bg-white border-t-4 border-gray-800 py-5 px-2'>
            <SEO title='Import Show' />
            <div className='px-4'>
                <ImportProgress show={show} />
            </div>
            <h3 className='text-lg text-teal-700 text-center py-3'>
                Congrats! Your show is being imported. <br />
                Follow these steps to finish setup.
            </h3>
            <div className='flex flex-col items-center'>
                <ul className='mt-6 mb-8 space-y-8 w-full max-w-3xl mx-auto'>
                    <li>
                        <h4 className='font-medium flex items-center text-lg'>
                            <svg
                                className='w-7 h-7 text-teal-400 mr-2'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                            >
                                <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                    clipRule='evenodd'
                                ></path>
                            </svg>
                            <span>Redirect your feed</span>
                        </h4>
                        <div className='px-8 space-y-2'>
                            <p>
                                Ask your current podcast provider to redirect your feed to the
                                following url. You can usually find this redirect option in your
                                podcast settings. <br />
                            </p>

                            <div className='border rounded overflow-hidden mt-1 sm:flex'>
                                <input
                                    readOnly
                                    placeholder='No Feed URL Found'
                                    defaultValue={feedUrl}
                                    type='text'
                                    className='form-input rounded-none border-none flex-1 w-full text-sm text-gray-800'
                                />
                                <button
                                    aria-label='copy to clipboard'
                                    onClick={handleCopy}
                                    className={`${
                                        copied
                                            ? 'bg-green-500 text-white'
                                            : 'text-gray-500 hover:text-gray-800'
                                    } w-full sm:w-24 transition duration-300 border-t sm:border-t-0 sm:border-l px-1 py-2 text-sm focus:outline-none `}
                                >
                                    <div className='flex-center w-full'>
                                        {!copied && (
                                            <svg
                                                className='w-4 h-4 mr-1'
                                                fill='none'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='2'
                                                stroke='currentColor'
                                                viewBox='0 0 24 24'
                                            >
                                                <path d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2'></path>
                                            </svg>
                                        )}
                                        <span className=''>{copied ? 'Copied!' : 'Copy'}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h4 className='font-medium flex items-center text-lg'>
                            <svg
                                className='w-7 h-7 text-teal-400 mr-2'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                            >
                                <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                    clipRule='evenodd'
                                ></path>
                            </svg>
                            <span>Setup Billing</span>
                        </h4>
                        <div className='px-8 space-y-2'>
                            <p>
                                Add a payment method in billing settings. Skip this if you have
                                already added one. You can add a payment method in Account Settings
                                > Billing page.
                            </p>
                            <div className='py-1 flex flex-col items-center'>
                                <Link
                                    to='/settings/billing'
                                    className='w-full flex-center text-center px-6 py-2 border border-gray-400 hover:border-gray-700 group text-sm rounded font-medium'
                                >
                                    Go to Billing
                                    <svg
                                        className='w-5 h-5 transition duration-150 transform group-hover:translate-x-1 text-gray-500 group-hover:text-gray-700'
                                        fill='currentColor'
                                        viewBox='0 0 20 20'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                                            clipRule='evenodd'
                                        ></path>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className='px-8 mt-4 text-right'>
                            <Link
                                to='/'
                                className='inline-flex items-center text-center px-1 py-1 border-b-2 border-gray-200 hover:border-gray-700 group text-sm text-sm'
                            >
                                Go To Dashboard
                                <svg
                                    className='w-5 h-5 transition duration-150 transform group-hover:translate-x-1 text-gray-500 group-hover:text-gray-700'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                                        clipRule='evenodd'
                                    ></path>
                                </svg>
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
