import React from 'react'
import firebase from '../lib/firebase'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import Icon from '../components/icon.js'
import { useTranslation } from 'react-i18next'

export default props => {
    const auth = useSelector(s => s.auth)
    const { t } = useTranslation()

    const handleLogout = e => {
        firebase.auth().signOut()
        navigate('/')
    }

    if (!auth.data || !auth.data.id) return null

    return (
        <div className='text-sm'>
            <div className='bg-gray-50 rounded flex items-center justify-center'>
                <div className='inline-flex flex-shrink-0 justify-center h-8 w-8 rounded-full overflow-hidden shadow-solid text-gray-600 hover:text-gray-800 focus:outline-none focus:border-gray-300 focus:shadow-outline active:text-gray-900 transition ease-in-out duration-150'>
                    {auth.data.photoUrl ? (
                        <img
                            className='inline-block h-full w-full text-white'
                            width='24'
                            height='24'
                            src={auth.data.photoUrl}
                            alt=''
                        />
                    ) : (
                        <svg
                            className='my-auto h-5 w-5 text-gray-500'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
                            />
                        </svg>
                    )}
                </div>
                <p className='px-4 py-3 text-xs'>
                    {t('Logged in as')}:
                    <br />
                    <strong className='font-medium'>{auth.data.email} </strong>
                </p>
            </div>
            <button
                onClick={handleLogout}
                className='mt-1 border group flex justify-center items-center space-x-2 block w-full text-center px-2 py-2 rounded leading-5 hover:bg-gray-50 focus:outline-none font-medium focus:bg-gray-100 focus:text-gray-900'
            >
                <Icon name='logout' className='h-4 w-4 text-gray-400 group-hover:text-gray-500' />
                <span className=''>{t('Logout')}</span>
            </button>
        </div>
    )
}
