export default {
    en: {
        translation: {
            '404': 'Sorry, the page you accessed could not be found.',
            'change-later-explainer': 'You can change all this later in settings too.',
            'delete-confirmation': 'Confirm to start the deletion process for the episode.',
            'feed-explainer':
                "This is your show's unique URL. Use it to submit this podcast to Apple Podcasts, Spotify etc.",
            'how-to-submit-explainer': 'How to Submit your Podcast to Apple, Spotify etc.?',
            'estimate-explainer':
                'This estimated bill is calculated by using your current plan. You can check it in Settings > Billing > Your Plans section',
            'delete-show-explainer': `
                        This action will queue the removal of all of this show's data including:
                        Details, Episodes, Analytics
                    `,
            'please-follow-next-steps': `
                                    Please follow the instructions below to submit your podcast to
                                    Apple Podcasts and others.
                                `,
            'scheduled-episode-explainer': `
                                        You have selected a future date! Episode will stay private
                                        until then and will automatically release on the scheduled
                                        time
                                    `,
            'links-explainer': `
                        Enter all your social media and podcast urls you would like to show on the
                        website
                    `,
            'show-website-explainer': `
                        This is your podcast's website url. Share this with your audience as a
                        single location that contains all your relevant links
                    `,
            'redirect-show-explainer': `
                    Redirect all your traffic to provided url. Use this when you want to migrate
                    away to some other provider. Note that this does not automatically delete your
                    media stored here. To prevent associated cost delete this show here once
                    your new location is all setup.
                `,
            'limit-episode-explainer':
                'By turning this ON only the last N episodes will be visible in your feed. This can speed up your feed load time if you have lots of episodes.',
            'mark-show-complete-explainer':
                'By turning this ON you can indicate that this podcast is "complete" and you will never publish another episode. This could be useful to indicate that a podcast is finished.',
            'hide-show-explainer':
                'Hide this show from Apple Podcasts. This could be a good alternative to permanently deleting the show. Note that other podcast client might still be able to view this show.',
            'delete-episode-explainer':
                'This action will remove this episode and all of its data including: Details, Analytics, Settings',
            'hide-episode-explainer':
                'Hide this episode from Apple Podcasts. This could be a good alternative to permanently deleting the episode. Note that other podcast client might still be                    able to view this episode.',
            'Show Details': 'Show Details',
            'Trial Description': `
                    Try out all features for 30 days for free. After end of trial make sure to add a payment method to continue using our services.
                    `,
            'invite-member-explainer':
                'Invite other members to your account. You can also add specific permissions to each member.',

            'click-validate-apple': 'Click Validate.',
            'spotify-submit-explainer': 'Submit and wait for approval',
            'spotify-category-explainer':
                'Add podcast info like the category, language, and country.',
            'spotify-verify-email-explainer': `Spotify will send a verification email to the address that you put in while creating your podcast. Copy the 8-digit code from that email and paste in the submit form.`,
            'follow-next-instructions':
                'Follow the next instructions on screen such as verification etc.',
        },
    },
    es: {
        translation: {
            'Show Details': 'Ver Detalles',
            'Shows': 'Shows',
            'Usage': 'Uso',
            'Activity': 'Actividad',
            'Settings': 'Ajustes',
        },
    },
    jp: {
        translation: {
            '404': '申し訳ございません。ページが見つかりませんでした。',
            'Click Start Now': '"Start Now"をクリックしてください。',
            'follow-next-instructions': '情報を入力してください。',
            'spotify-submit-explainer': '登録してお待ちください。',
            'spotify-category-explainer':
                'ポッドキャストのカテゴリー、言語、国等の情報を入力してください。',
            'spotify-verify-email-explainer': `登録したメールアドレスにSpotifyから確認のため８桁コードがとどきます。 このコードを入力してSubmitしてください。`,
            'delete-show-explainer': 'クリックすると本ポッドキャストの全データが削除されます',
            'Show Details': 'ポッドキャスト詳細情報',
            'click-validate-apple': '[Validate]をクリックしてください。',
            'Click Submit and wait for approval (~1 week)':
                '[Submit]をクリックして一週間ぐらいお待ちください。',
            'Shows': 'ポッドキャスト',
            'Usage': '使用履歴',
            'Activity': 'アクティビティ',
            'Settings': '設定',
            'Trial': 'トライアル',
            '30 Day Trial': '30日トライアル',
            'Categories': 'カテゴリー',
            'Import Show': 'インポート',
            'Create Show': '新規作成',
            'No more items to show': 'これ以上ありません',
            'View Pricing': 'プランを見る',
            'Summary of the usage on your shows and invoices':
                'ポッドキャスト使用履歴及び請求書の概要',
            'Current Period': '今月分',
            'Bandwidth (Downloads)': 'ダウンロード量',
            'Estimated Bill': '請求額概算',
            'estimate-explainer':
                'この概算請求額はあなたの現在のプランに基づいています。設定→請求書→あなたのプラン',
            'Period': '期間',
            'Bandwidth Used': '使用データ量',
            'Bill Amount': '請求額概算',
            'Invoice Link': '請求書のリンク',
            'View Invoice': '請求書を見る',
            'Load More': 'もっと見る',
            'Account Activity': 'アカウントアクティビティ',
            'Recent activity from your account': 'あなたのアカウントの最近の活動',
            'Account Settings': 'アカウント設定',
            'Expiring': '期限切れ',
            'Set as Default': '標準として設定',
            'Delete Card': 'カード削除',
            'Are you Sure?': '本当に？',
            'Go Back': '戻る',
            'General': '一般',
            'Billing': '請求',
            'Advanced': '詳細',
            'Teams': 'チーム',
            'Team Name': 'チーム名',
            'Your Plans': 'あなたのプラン',
            'Payment Methods': '支払い方法',
            'Add New Card': 'カードを追加する',
            'Add Addons': 'プランを追加する',
            'Your Account Members': 'アカウントメンバー',
            '$1 per 50GB per month': '50GB当たり1米ドル(月額)',
            'Pay as You Go': 'Pay as You Go',
            'Create New Show': '新しいポッドキャストを作成する',
            'invite-member-explainer':
                '他のメンバーをあなたのアカウントに招待する。各人の作業可能範囲の設定を追加できます。',
            'Invite Member': 'メンバーを招待する',
            'Email': 'メール',
            "Invitee's email": '被招待者のメール',
            'Permissions': '許可',
            'Manage Shows': 'ポッドキャスト管理',
            'Can create/edit shows and episodes.':
                'ポッドキャスト及びエピソードの作成／編集ができます',
            'Manage Account': 'アカウント管理',
            'Can update your account info including billing.':
                'アカウント情報のアップデート／請求情報の編集ができます',
            'View Usage': '使用履歴を見る',
            "Can view your account's and show's usage data":
                'アカウントおよびポッドキャストの使用履歴を見れます',
            'View Analytics': 'アナリティクスを見る',
            "Can view show's analytics": 'ポッドキャストのアナリティクスを見れます',
            'Invite': '招待',
            'Your Teams': 'あなたのチーム',
            'All the teams where you have been invited.': 'あなたが招待された全てのチーム',
            "You haven't been invited to any team yet": 'まだどのチームにも招待されていません',
            'Go Home': 'ホームに戻る',
            'Sorry, the page you accessed could not be found.':
                'アクセスしたページは見つかりませんでした',
            'Important!': '重要！',
            'Your shows requires billing to be enabled.':
                'ポッドキャストが使用可能になるためには支払情報が必要です',
            'to add a payment method.': '支払い方法を追加する',
            'Done! Check your inbox, click the link and refresh this page once you are done.':
                '完了！届いたメールを確認しリンクをクリックしてください。その後このページを更新すれば全て完了です。',
            'Important! Email not verified. Check your inbox for verification link.':
                '重要！メール認証が確認できませんでした。メールを確認して認証リンクをクリックしてください。',
            'Resend Email': 'メールを再送する',
            'Episode Not Found': 'エピソードが見つかりません',
            'Episodes': 'エピソード',
            'Analytics': 'アナリティクス',
            'Overview': 'Overview',
            'Episode': 'エピソード',
            'Explicit Content': '不適切な内容',
            'Feed URL': 'フィードURL',
            'feed-explainer':
                'これはあなたのポッドキャスト固有のURLです。Apple PodcastsやSpotify等にポッドキャストを提出する際、このURLを使用してください。',
            'Active': 'アクティブ',
            'Pending': 'ペンディング',
            'Redirected': 'リダイレクト',
            'Copied': 'コピー完了',
            'Copied!': 'コピー完了！',
            'Copy': 'コピー',
            'how-to-submit-explainer':
                'このポッドキャストをどうやってApple PodcastsやSpotifyに提出するの？',
            'Your Feed URL': 'あなたのフィードURL',
            'Dismiss': '閉じる',
            'Company': '会社',
            'Product': '製品',
            'Help': 'ヘルプ',
            'Legal': 'ポリシー',
            'Home': 'ホーム',
            'About': '概要',
            'Blog': 'ブログ',
            'Pricing': '価格',
            'Documentation': '参照資料',
            'Support': 'サポート',
            'Privacy': 'プライバシー',
            'Terms of Service': '利用規約',
            'Trial Description':
                '30日間全機能が無料トライアル！トライアル期間後も使用継続する場合は支払情報を追加してください。',
            'Importing Episodes. This could take while.':
                'エピソードをインポートしています。しばらくお待ちください。',
            'Processing': 'プロセス中',
            'of': '/',
            'Docs': '文書',
            'Logged in as': 'ログインしたユーザー',
            'Logout': 'ログアウト',
            'You do not have permission to view this page': 'このページを閲覧する許可がありません',
            'No new notifications': '新しいお知らせはありません',
            'Notifications': '通知',
            'Show Not Found': 'ポッドキャストが見つかりません',
            'Back Home': 'ホームに戻る',
            'Switch Accounts': 'アカウントを切り替える',
            'Personal Accounts': '個人アカウント',
            'No teams available': '参加可能なチームがありません',
            'Submit to Apple Podcasts': 'Apple Podcastsに提出する',
            'Submit to Spotify': 'Spotifyに提出する',
            'Submit to Google Podcasts': 'Google Podcastsに提出する',
            'Recommended size 3000X3000': '推奨サイズ 3000X3000',
            'PNG or JPG': 'PNG 又は JPG',
            'Upload a file or drag and drop': 'ファイルをアップロード又はドラッグアンドドロップ',
            'Hide Episode In Apple Podcasts': 'Apple Podcastsに表示しない',
            'hide-episode-explainer':
                'このエピソードをApple Podcastsに表示しない。これはエピソードを完全削除したくない場合の良い代替手段です。他のポッドキャストアプリには表示される可能性があることに注意。',
            'Yes, hide this episode': 'はい、このエピソードを非表示にする',
            'Yes I understand, delete this Show': 'Yes I understand, delete this Show',
            'Redirect URL': 'Redirect URL',
            'Delete This Episode': 'このエピソードを削除する',
            'delete-episode-explainer':
                'この選択はアナリティクスや設定を含む本エピソードの全てのデータを削除します。',
            'delete-confirmation': '本エピソードの削除プロセスを開始します',
            'Delete Episode': 'エピソードを削除',
            'Delete Show': 'ポッドキャストを削除',
            'Episode Settings': 'エピソードの設定',
            'Episode Title': 'エピソードのタイトル',
            'Episode Type': 'エピソードの類型',
            'Full': 'フル',
            'Trailer': '予告編',
            'Bonus': 'ボーナス',
            'Episode Number': 'エピソード番号',
            'Season Number': 'シーズン番号',
            'Save': '保存',
            'Episode Media': 'エピソードのメディア',
            'Upload audio (Recommended type .mp3)':
                '音声データをアップロード (ファイル形式.mp3を推奨)',
            'Update': 'アップデート',
            'Show Artwork': 'アートワークを表示',
            'Hide Show In Apple Podcasts': 'Apple Podcastsにおいてアートワークを非表示にする',
            'hide-show-explainer':
                'このポッドキャストをApple Podcastsに表示しない。これはポッドキャストを完全削除したくない場合の良い代替手段です。他のポッドキャストアプリには表示される可能性があることに注意。',
            'Yes, Hide this show in Apple Podcasts':
                'はい、Apple Podcastsにおいてこのポッドキャストを非表示にする',
            'Mark Show as Completed': 'ポッドキャストを終了済みにする',
            'mark-show-complete-explainer':
                'これをONにすると、このポッドキャストは「終了済み」であり新しいエピソードのアップロードがないことを示します。このポッドキャストが終了したことを示す際に便利。',
            'Limit Episodes in Feed': 'フィードのエピソード数を限定する',
            'limit-episode-explainer':
                'これをONにすると、任意の数のエピソードのみがフィードに表示されます。多くのエピソードがある場合にローディング時間を早めることができます。',
            'Enable': '有効にする',
            'Redirect Show': 'ポッドキャストをリダイレクトする',
            'redirect-show-explainer':
                'URLを提供するためにあなたの全てのトラフィックをリダイレクトします。他のポッドキャストプロバイダーに移行させたい場合に使用します。この行為ではこのサイトに保存されたメディアは自動的に削除されません。料金発生を防ぐために、新たなプロバイダーに移行完了次第、「ポッドキャストを削除する」をクリックし全てのデータを削除してください。',
            "Show's Website URL": 'あなたのポッドキャストサイトURL',
            'show-website-explainer':
                'これはあなたのポッドキャストのウェブサイトURLです。関連する全リンク先を含む一元的なサイトとして、このURLをあなたの視聴者に共有することができます。',
            'Update Url': 'URLをアップデートする',
            'Links': 'リンク',
            'links-explainer':
                'ウェブサイトに表示させたい全てのSNS及びポッドキャストのURLを入力してください',
            'Create Episode': 'エピソードを作成する',
            'Create New Episode': '新しいエピソードを作成する',
            'Title': 'タイトル',
            'Description': '概要',
            'Audio': '音声',
            'Upload audio file': '音声データをアップロードする',
            'Recommended type .mp3 or .aac': '推奨ファイル形式 .mp3 又は .aac',
            'Release Date': 'リリース日',
            'scheduled-episode-explainer':
                '未来の日時を選択しています！エピソードはスケジュールされた日時にリリースされ、リリースされるまではあなたのみが見れます',
            'Contains Explicit Language': '不適切な内容を含む',
            'Advanced Options': '詳細選択',
            'Season No.': 'シーズン No.',
            'Episode No.': 'エピソード No.',
            'Episode Artwork': 'エピソードのアートワーク',
            'Size must be less than 2MB': 'サイズは必ず2MB以下',
            'change-later-explainer': 'これらは全て後で設定欄から変更できます',
            'Submit': '提出',
            'Overview': '概要',
            'Cancel': 'キャンセル',
            'Schedule Episode': 'エピソードアップ日の事前設定',
            'Show Title': 'ポッドキャストタイトル',
            'Show Description': 'ポッドキャスト概要',
            'Author': '作者',
            "Author's Name": '作者名',
            "Author's email": '作者のメール',
            'Website': 'ウェブサイト',
            'Artwork': 'アートワークを表示',
            'Change': '変更',
            'Language': '言語',
            'Category': 'カテゴリー',
            'Show Type': 'ポッドキャストの類型',
            'Episodic': 'エピソードごと',
            'Serial': 'シリーズごと',
            'Parental Advisory': '保護者の指導が必要',
            'Is Explicit': '不適切な内容を含む',
            'Uploading': 'アップロード中',
            'Episode Analytics': 'エピソードのアナリティクス',
            'Last 30 Days': '過去30日間',
            'Last 7 Days': '過去7日間',
            'Last 6 Months': '過去6か月間',
            'Last 1 Year': '過去1年間',
            'Lifetime': '全ての期間',
            'Unique Downloads': 'ユニークダウンロード',
            'Total number of downloads in given time range': '指定した期間中の合計ダウンロード数',
            'No data found': 'データが見つかりません',
            'Top Countries': 'トップ国',
            'Top Devices': 'トップ使用機器',
            'Bandwidth Used (MB)': '使用データ量 (MB)',
            'Episode Overview': 'エピソードの概要',
            'Congrats! You created your very first episode':
                'おめでとうございます！最初のエピソードを作成しました',
            'please-follow-next-steps':
                '作成されたポッドキャストをApple Podcastsや他のポッドキャストに提出するには以下の手順に従ってください',
            'Episode Details': 'エピソードの詳細',
            'Details': '詳細',
            'No description': '概要がありません',
            'Hidden': '非表示',
            'Visible': '表示',
            'Yes': 'はい',
            'No': 'いいえ',
            'N/A': '適用なし',
            'No Episodes': 'エピソードがありません',
            "You haven't created any episodes yet.": 'まだエピソードを作成していません',
            'Try creating one.': 'まずは１エピソード作成してみましょう',
            'Scheduled': '予定された',
            'Show Analytics': 'アナリティクスを表示する',
            'Total Episodes': '全てのエピソード',
            'Date': '日付',
            'Downloads': 'ダウンロード量',
            'Others': 'その他',
            'Top Episodes': 'トップエピソード',
            'Top Clients': 'トップクライアント',
            'Top Referrers': 'トップ参照先',
            'Bandwidth': 'データ量',
            'Summary of the usage on this show': 'このポッドキャスト使用履歴の要約',
            'Past Usage and Invoices': '過去の使用履歴及び請求書',
            'Latest Episodes': '最新のエピソード',
            'View Website': 'ウェブサイトを見る',
            'View RSS Feed': 'RSS Feedを見る',
        },
    },
}
