import React from 'react'
import { useTranslation } from 'react-i18next'
const ExplicitTag = ({ isExplicit = false, className = '' }) => {
    const { t } = useTranslation()
    if (!isExplicit) return null
    return (
        <div className={`group relative mb-3 ${className}`}>
            <span className='absolute left-0 w-32 text-center top-0 ml-5 mt-1 bg-gray-900 rounded-sm text-xs text-white inline px-1 group-hover:opacity-100 opacity-0 transition duration-150'>
                {t('Explicit Content')}
            </span>
            <svg
                className='inline w-3 h-3 text-gray-400 fill-current group-hover:text-gray-900 transition duration-150'
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                viewBox='0 0 384 384'
            >
                <path
                    d='M341.333,0H42.667C19.093,0,0,19.093,0,42.667v298.667C0,364.907,19.093,384,42.667,384h298.667
			C364.907,384,384,364.907,384,341.333V42.667C384,19.093,364.907,0,341.333,0z M256,128h-85.333v42.667H256v42.667h-85.333V256
			H256v42.667H128V85.333h128V128z'
                />
            </svg>
        </div>
    )
}

export default ExplicitTag
