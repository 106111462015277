import React from 'react'
import { useOnClickOutside } from '../hooks/on-click-outside.js'

export default props => {
    const { autoClose = true, position = 'left', inline = false } = props
    const [open, setOpen] = React.useState(false)
    const ref = React.useRef()
    useOnClickOutside(ref, () => autoClose && setOpen(false))
    let directionClass = 'right-0 mr-6'
    if (position === 'right') directionClass = 'left-0 ml-6'
    return (
        <div ref={ref} className='group text-gray-400 relative'>
            {open && (
                <p
                    style={{ minWidth: '200px' }}
                    className={`inline ${
                        inline && 'whitespace-no-wrap'
                    } absolute ${directionClass}  bg-gray-800 px-3 py-2 text-white text-xs rounded z-40 transition duration-300 ${props.textClass}`}
                >
                    {props.text}
                </p>
            )}
            <button
                aria-label='Toggle popup'
                className='active:text-teal-500 hover:text-teal-500 focus:outline-none'
                onClick={() => setOpen(!open)}
            >
                <svg
                    className={props.iconClass}
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                >
                    <path d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                </svg>
            </button>
        </div>
    )
}
