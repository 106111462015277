import React from 'react'
import Select from 'react-select'
const SortBox = ({ defaultValue, onChange, options }) => {
    const makeValue = key => {
        try {
            return options.filter(item => item.value === key)[0]
        } catch (err) {
            return options[0]
        }
    }

    const styles = {
        control: base => ({
            ...base,
            border: '0.5px solid lightgray',
            minHeight: '30px',
            width: '150px',
        }),
        menu: base => ({
            ...base,
            width: '150px',
        }),
        valueContainer: base => ({
            ...base,
        }),
        container: base => ({
            ...base,
            fontSize: '0.90rem',
        }),
        option: base => ({
            ...base,
            fontSize: '0.90rem',
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: '4px 8px',
        }),
    }
    const handleChange = v => onChange(v.value)
    return (
        <div className='flex items-center'>
            <span className='text-sm text-gray-600 mr-2 font-medium'>Sort By</span>
            <Select
                isSearchable={false}
                defaultValue={makeValue(defaultValue)}
                components={{ IndicatorSeparator: null }}
                classNamePrefx='lang'
                styles={styles}
                onChange={handleChange}
                options={options}
            />
        </div>
    )
}
export default SortBox
