import React from 'react'
import { Link, navigate } from 'gatsby'
import { api, updateEpisode } from '../../state/actions.js'
import { useOnClickOutside } from '../../hooks/on-click-outside.js'
import Modal from '../../components/modal.js'
import SettingPanel from '../../components/setting-panel.js'
import EpisodeNotFound from '../../components/episode-404.js'
import EpisodeArtworkSettings from './artwork.js'
import Spinner2 from '../../components/spinner.js'
import Editor from '../../components/rich-text-editor.js'
import Media from './media.js'
import { useSelector } from 'react-redux'
import DatePicker from '../../components/date-picker.js'
import SEO from '../../components/seo.js'
import { noop } from '../../util.js'
import { useTranslation } from 'react-i18next'

const Settings = props => {
    const { id, epId } = props
    const { t } = useTranslation()

    const routes = [
        { title: 'General', to: `/shows/${id}/episodes/${epId}/settings` },
        { title: 'Advanced', to: `/shows/${id}/episodes/${epId}/settings/advanced` },
    ]

    return (
        <div className='sm:mt-6'>
            <SEO title='Episode Settings' />
            <div className='sm:fixed sm:w-24 sm:ml-4 text-center sm:text-sm text-gray-600'>
                {routes.map(r => (
                    <Link
                        key={r.to}
                        to={r.to}
                        activeClassName='font-semibold'
                        replace
                        className={`block py-2 mb-2 hover:text-gray-900`}
                    >
                        {t(r.title)}
                    </Link>
                ))}
            </div>
            <div className='sm:ml-40'>{props.children}</div>
        </div>
    )
}

const HideEpisodePanel = props => {
    const { t } = useTranslation()
    const { episode, loading, activeTeamId } = useSelector(s => ({
        loading: s.showEpisodes.loading,
        episode: s.showEpisodes[props.id].data[props.epId],
        activeTeamId: s.activeTeamId,
    }))

    const [episodeIsHidden, setEpisodeIsHidden] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    React.useEffect(() => {
        if (!loading && episode) {
            setEpisodeIsHidden(!!episode.episodeIsHidden)
        }
    }, [episode, loading])

    const handleBlockSubmit = async e => {
        setSaving(true)
        setError(null)
        try {
            await updateEpisode(activeTeamId, props.id, props.epId, {
                episodeIsHidden: episodeIsHidden,
            })
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    return (
        <SettingPanel title={t('Hide Episode In Apple Podcasts')}>
            <SettingPanel.Content>
                <p className='text-sm max-w-xl text-gray-700 pb-2'>{t('hide-episode-explainer')}</p>

                <div className='flex items-stretch space-x-4 mt-2 text-sm'>
                    <div className='flex items-center'>
                        <label className='text-sm space-x-2 flex-1 flex items-center '>
                            <input
                                onBlur={noop}
                                name='episodeIsHidden'
                                className='w-5 h-5 form-checkbox'
                                checked={episodeIsHidden}
                                onChange={e => setEpisodeIsHidden(e.target.checked)}
                                type='checkbox'
                            />
                            <span>{t('Yes, hide this episode')}</span>
                        </label>
                    </div>
                </div>
            </SettingPanel.Content>

            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    onClick={handleBlockSubmit}
                    disabled={loading || episodeIsHidden === episode.episodeIsHidden}
                    className={`${saving ? 'pointer-events-none' : ''} ${
                        loading || episodeIsHidden === episode.episodeIsHidden
                            ? 'border-gray-200 bg-gray-200'
                            : 'border-gray-800 bg-gray-800'
                    } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                >
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>{t('Save')}</span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

const Advanced = props => {
    const { t } = useTranslation()
    const { episode } = useSelector(s => {
        const result = { episode: null, epLoading: false }

        const showEp = s.showEpisodes[props.id]
        if (showEp) {
            result.episode = showEp.data[props.epId]
            result.eploading = showEp.loading
        }
        return result
    })

    const [error, setError] = React.useState(null)
    const [confirmed, setConfirmed] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const [modalOpen, setModalOpen] = React.useState(false)

    const ref = React.useRef()
    useOnClickOutside(ref, () => !loading && handleCancel())
    const handleCancel = e => {
        setConfirmed(false)
        setModalOpen(false)
    }
    const handleDeleteSubmit = async e => {
        setLoading(true)
        setError(null)
        try {
            await api('episode_delete', { showId: props.id, id: props.epId })
            navigate(`/shows/${props.id}`, { replace: true })
        } catch (err) {
            setError(err.message)
        }
        setLoading(false)
        setModalOpen(false)
    }

    if (!episode) return <EpisodeNotFound />

    return (
        <div className='space-y-8'>
            <HideEpisodePanel id={props.id} epId={props.epId} />
            <SettingPanel title={t('Delete This Episode')} danger={true}>
                <SettingPanel.Content>
                    <p className='text-sm max-w-xl text-gray-700 pb-2'>
                        {t('delete-episode-explainer')}
                    </p>
                    <label className='text-sm flex items-center'>
                        <input
                            className='form-checkbox'
                            checked={confirmed}
                            onChange={e => setConfirmed(e.target.checked)}
                            type='checkbox'
                        />
                        <span className='pl-2'>
                            {t('Confirm to start the deletion process for the episode.')}
                        </span>
                    </label>
                </SettingPanel.Content>
                <Modal open={modalOpen} onClickOutside={handleCancel}>
                    <div className='pb-4'>
                        <div className='py-4 text-left'>
                            <div className='flex justify-between items-center pb-4'>
                                <p className='text-xl text-red-800 font-medium'>
                                    {t('Are you Sure?')}
                                </p>
                            </div>
                            <p
                                className={`px-2 text-sm rounded font-medium bg-yellow-50 text-yellow-900 py-2 mb-2 rounded`}
                            >
                                Deleting an episode is an irreversible operation! <br />
                                This action will have following effects:
                            </p>
                            <ul className='text-sm pl-4 mb-2 pr-8'>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t(
                                        'Your public feed will be remove any reference to this episode',
                                    )}
                                </li>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t(
                                        'Your audience will no longer be able to access this episode',
                                    )}
                                </li>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t("This episodes's analytics will no longer be accessible")}
                                </li>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t('Usage data for this episode will no longer be accessible')}
                                </li>
                            </ul>
                        </div>
                        <div className='flex justify-between items-end text-sm pt-6'>
                            <button
                                onClick={handleCancel}
                                disabled={loading}
                                className={`${
                                    loading ? 'pointer-events-none' : ''
                                } px-6 py-2 bg-gray-100 rounded-lg text-gray-600 hover:bg-gray-200 hover:text-gray-900 mr-2`}
                            >
                                {t('Go Back')}
                            </button>
                            <button
                                disabled={loading}
                                onClick={handleDeleteSubmit}
                                className={`${
                                    loading ? 'pointer-events-none' : ''
                                } border-red-700 bg-white
                         relative transition duration-150 border group hover:bg-red-700 hover:text-white rounded text-red-900 focus:outline-none px-6 py-2 transition ease-in-out duration-150`}
                            >
                                <span className='absolute inset-0 w-full h-full flex-center'>
                                    <svg
                                        className={`h-6 w-6 spin ${
                                            loading ? 'opacity-100' : 'opacity-0'
                                        }`}
                                        viewBox='0 0 24 24'
                                    >
                                        <path
                                            className='text-red-600'
                                            fill='currentColor'
                                            d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                        />
                                        <path
                                            className='text-gray-400'
                                            fill='currentColor'
                                            d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`${
                                        loading ? 'opacity-0' : 'opacity-100'
                                    } flex-center`}
                                >
                                    {t('Yes I understand, delete this Episode')}
                                </span>
                            </button>
                        </div>
                    </div>
                </Modal>
                <SettingPanel.Error error={error} />

                <SettingPanel.Actions>
                    <button
                        disabled={!confirmed}
                        onClick={() => setModalOpen(true)}
                        className={`${
                            confirmed
                                ? 'border-red-600 bg-red-600'
                                : 'pointer-events-none bg-gray-300 border-gray-300'
                        } transition duration-150 border hover:bg-white hover:text-red-900 rounded text-white focus:outline-none px-5 py-1 transition ease-in-out duration-150`}
                    >
                        {t('Delete Episode')}
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
        </div>
    )
}
const General = props => {
    const { t } = useTranslation()
    const { activeTeamId, auth, episode, loading } = useSelector(s => {
        const result = { activeTeamId: s.activeTeamId, auth: s.auth, episode: null, loading: true }

        const showEp = s.showEpisodes[props.id]
        if (showEp) {
            result.episode = showEp.data[props.epId]
            result.loading = showEp.loading
        }
        return result
    })

    const [data, setData] = React.useState({
        title: '',
        description: '',
        showNotes: '',
        isExplicit: true,
        episodeType: 'full',
        seasonNumber: 1,
        episodeNumber: 1,
        url: '',
    })

    React.useEffect(() => {
        if (!loading && episode) {
            setData(episode)
            setReleaseDate(new Date(episode.releaseDate))
            setChanged(false)
        }
    }, [episode, loading])

    const [changed, setChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (episode) {
            if (e.target.type === 'checkbox') {
                setChanged(episode[e.target.name] !== e.target.checked)
            } else {
                setChanged(episode[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value
        if (e.target.type === 'checkbox') val = e.target.checked
        setData({ ...data, [e.target.name]: val })
    }

    const [releaseDate, setReleaseDate] = React.useState(new Date())
    const handleDateChange = date => {
        setReleaseDate(date)
        setChanged(episode.releaseDate !== date.toISOString())
    }
    const handleDescriptionChange = value => {
        const newDesc = value.toString('html')
        setChanged(newDesc !== episode.description)
        setData({ ...data, description: newDesc })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setSaving(true)
        setError(null)
        if (episode.releaseDate !== releaseDate.toISOString()) {
            data.releaseDate = releaseDate.toISOString()
        }
        data.updatedBy = auth.data.id
        try {
            await updateEpisode(activeTeamId, props.id, props.epId, data)
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    if (loading) return <Spinner2 />
    if (!episode) return <EpisodeNotFound />

    return (
        <div className='space-y-8'>
            <SettingPanel onSubmit={handleSubmit} title={t('Episode Settings')}>
                <SettingPanel.Content>
                    <fieldset disabled={saving}>
                        <div className='flex-1 pt-3'>
                            <label htmlFor='title' className='text-sm block font-medium'>
                                {t('Episode Title')}
                            </label>
                            <input
                                className='form-input text-sm w-full mt-1'
                                name='title'
                                value={data.title}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='flex-1 mt-3'>
                            <label htmlFor='description' className='text-sm block font-medium'>
                                {t('Description')}
                            </label>
                            {data.description && (
                                <Editor
                                    className='w-full mt-1 form-textarea'
                                    initialValue={episode.description}
                                    value={data.description}
                                    height='h-48'
                                    placeholder={t('Short Description')}
                                    onChange={handleDescriptionChange}
                                    name='description'
                                />
                            )}
                        </div>
                        <div className='mt-5 flex flex-col'>
                            <label htmlFor='releaseDate' className='text-sm font-medium'>
                                {t('Release Date')}
                            </label>
                            <DatePicker
                                className='border px-3 py-2 text-sm rounded mt-1 w-full'
                                name='releaseDate'
                                date={releaseDate}
                                onChange={handleDateChange}
                            />
                        </div>
                        <div className='flex-1 mt-3 text-base'>
                            <label htmlFor='episodeType' className='text-sm block font-medium'>
                                {t('Episode Type')}
                            </label>
                            <select
                                onBlur={noop}
                                name='episodeType'
                                value={data.episodeType}
                                onChange={handleChange}
                                className='form-select w-full text-sm mt-1'
                            >
                                <option value='full'>{t('Full')}</option>
                                <option value='trailer'>{t('Trailer')}</option>
                                <option value='bonus'>{t('Bonus')}</option>
                            </select>
                        </div>
                        <div className='md:flex flex-1 mt-3 md:space-x-2'>
                            <div className='flex-1 text-base'>
                                <label htmlFor='episodeType' className='text-sm block font-medium'>
                                    {t('Episode Number')}
                                </label>
                                <input
                                    onBlur={noop}
                                    name='episodeNumber'
                                    type='number'
                                    value={data.episodeNumber}
                                    onChange={handleChange}
                                    className='mt-1 w-full form-input'
                                    placeholder={t('1')}
                                />
                            </div>
                            <div className='flex-1 text-base'>
                                <label htmlFor='episodeType' className='text-sm block font-medium'>
                                    {t('Season Number')}
                                </label>
                                <input
                                    onBlur={noop}
                                    name='seasonNumber'
                                    type='number'
                                    value={data.seasonNumber}
                                    onChange={handleChange}
                                    className='mt-1 w-full form-input'
                                    placeholder={t('1')}
                                />
                            </div>
                        </div>
                        <div className='flex items-center text-base'>
                            <div className='w-1/2 mt-3'>
                                <span className='text-sm font-medium'>
                                    {t('Parental Advisory')}
                                </span>
                                <label className='flex items-center'>
                                    <input
                                        onBlur={noop}
                                        className='mr-2 h-5 w-5 form-checkbox'
                                        name='isExplicit'
                                        checked={data.isExplicit}
                                        onChange={handleChange}
                                        type='checkbox'
                                    />
                                    {t('Is Explicit')}
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </SettingPanel.Content>

                <SettingPanel.Error error={error} />
                <SettingPanel.Actions>
                    <button
                        type='submit'
                        disabled={!changed}
                        className={`${saving ? 'pointer-events-none' : ''} ${
                            changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                        } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                    >
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                        <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>
                            {t('Save')}
                        </span>
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
            <Media {...props} />
            <EpisodeArtworkSettings {...props} />
        </div>
    )
}
Settings.General = General
Settings.Advanced = Advanced

export default Settings
