import React from 'react'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { format, isFuture } from 'date-fns'
import Spinner2 from '../components/spinner.js'
import SEO from '../components/seo.js'
import EpisodeNotFound from '../components/episode-404.js'
import Tutorial from '../components/tutorial'
import { useTranslation } from 'react-i18next'
import Icon from '../components/icon'
import { sanitizeHtml } from '../util.js'

import useClipboard from '../hooks/clipboard.js'
const prettyBytes = require('pretty-bytes')
const humanizeDuration = require('humanize-duration')

const Episode = props => {
    const { epId } = props

    //Update show state
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: 'episode/make_active', id: epId })
        return () => {
            dispatch({ type: 'episode/make_active', id: null })
        }
    }, [dispatch, epId])

    return <div className='mt-3 pt-16'>{props.children}</div>
}

const Overview = props => {
    const { t } = useTranslation()
    const { episode, show, loading, episodesCount } = useSelector(s => {
        const result = {
            episodesCount: -1,
        }
        const showEp = s.showEpisodes[props.id]
        if (s.shows.data) {
            result.show = s.shows.data[props.id]
        }
        if (showEp) {
            result.loading = showEp.loading
            result.episode = showEp.data[props.epId]
            if (showEp.data && !showEp.loading) {
                result.episodesCount = Object.keys(showEp.data).length
            }
        }
        return result
    })

    const [modalDismissed, setModalDismissed] = React.useState(false)
    const [copied, setCopied] = useClipboard()
    const handleCopy = () => show.feedUrl && setCopied(show.feedUrl)
    if (loading || !episode) return <Spinner2 />
    let prettyDuration = 'N/A'
    if (episode.mediaDuration) {
        prettyDuration = humanizeDuration(parseInt(episode.mediaDuration) * 1000)
    }

    const isScheduled = () => {
        const relDate = new Date(episode.releaseDate)
        return isFuture(relDate)
    }

    const showFirstEpisodeModal = episodesCount <= 1 && !modalDismissed

    return (
        <div className='sm:px-4'>
            <SEO title={t('Episode Overview')} />

            <div
                className={`${
                    showFirstEpisodeModal ? 'opacity-100' : 'pointer-events-none opacity-0'
                } transition duration-100 fixed z-50 w-full h-full top-0 left-0 flex items-center justify-center`}
            >
                <div className='absolute w-full h-full bg-gray-900 opacity-50' />
                <div className='bg-white md:max-w-3xl md:mx-auto rounded shadow-lg px-2 sm:px-6 py-2 z-50 h-full sm:h-auto overflow-y-auto'>
                    {showFirstEpisodeModal && (
                        <div className='pb-4'>
                            <div className='py-4 text-left'>
                                <div className='flex justify-between items-center pb-4'>
                                    <p className='text-lg text-gray-800 font-medium'>
                                        {t('Congrats! You created your very first episode')}
                                    </p>
                                </div>
                                <p
                                    className={`px-2 text-sm rounded font-medium bg-green-50 text-green-900 py-2 rounded`}
                                >
                                    {t('please-follow-next-steps')}
                                </p>
                                {show && (
                                    <div className='mt-3'>
                                        <div className='flex items-center space-x-1'>
                                            <span className='font-medium text-sm'>
                                                {t('Feed URL')}{' '}
                                            </span>
                                        </div>
                                        <div className='border border-gray-200 rounded overflow-hidden mt-1 flex'>
                                            <input
                                                defaultValue={show.feedUrl}
                                                readOnly
                                                className='form-input rounded-none border-none flex-1 w-full text-sm text-gray-800'
                                            />
                                            <button
                                                aria-label='copy to clipboard'
                                                onClick={handleCopy}
                                                className={`${
                                                    copied
                                                        ? 'bg-green-500 text-white'
                                                        : 'text-gray-500 hover:text-gray-800'
                                                } w-14 transition duration-300 border-l border-gray-200 px-1 py-2 text-sm focus:outline-none `}
                                            >
                                                <div className='flex-center w-full'>
                                                    <svg
                                                        className={`w-4 h-4 ${
                                                            copied && 'text-white'
                                                        }`}
                                                        fill='none'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        strokeWidth='2'
                                                        stroke='currentColor'
                                                        viewBox='0 0 24 24'
                                                    >
                                                        <path d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2'></path>
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <Tutorial show={show} />
                                </div>
                            </div>
                            <div className='flex justify-end items-end text-sm pt-6'>
                                <button
                                    onClick={() => setModalDismissed(true)}
                                    className={`px-6 py-2 bg-gray-100 rounded text-gray-600 hover:bg-gray-200 hover:text-gray-900 mr-2`}
                                >
                                    {t('Dismiss')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <h4 className='text-xl font-medium ml-2'>{t('Episode Details')}</h4>
            <div className='panel px-2 sm:px-8'>
                {episode ? (
                    <div className='space-y-3'>
                        <div className='flex justify-end'>
                            <Link
                                replace
                                to={`/shows/${props.id}/episodes/${props.epId}/settings`}
                                className='flex items-center border border-gray-400 hover:border-gray-900 rounded px-3 py-1 font-medium text-sm transition duration-150'
                            >
                                <Icon
                                    className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-800'
                                    name='pencil-alt'
                                />
                                Edit Episode
                            </Link>
                        </div>
                        <div className='md:flex space-y-3 md:space-y-0'>
                            {episode.artworkUrl && (
                                <div className='flex flex-col items-center mr-4'>
                                    <img
                                        className={`max-w-sm w-full h-full sm:w-56 sm:h-56 shadow-xl rounded object-cover`}
                                        alt={episode.title}
                                        src={episode.artworkUrl}
                                    />
                                </div>
                            )}
                            <div className='flex-1 space-y-3'>
                                <h2 className='text-lg font-medium'>{episode.title}</h2>
                                <div className='flex justify-end'>
                                    <div className='text-xs font-medium text-gray-500'>
                                        {isScheduled() && (
                                            <span className='mx-2 bg-green-100 px-2 py-1 rounded-full font-medium text-black'>
                                                {t('Scheduled')}
                                            </span>
                                        )}
                                        <span>{format(new Date(episode.releaseDate), 'PPp')}</span>
                                    </div>
                                </div>
                                {prettyDuration !== 'N/A' && (
                                    <span className='text-sm'>{prettyDuration}</span>
                                )}
                                {episode.url && (
                                    <audio
                                        preload='none'
                                        src={episode.url}
                                        controls={true}
                                        className={`bg-gray-100 rounded w-full`}
                                    >
                                        <track kind='captions' />
                                    </audio>
                                )}
                            </div>
                        </div>
                        <div className='space-y-1'>
                            <div className='text-sm font-medium'>{t('Description')}</div>
                            <div
                                className='editor border border-gray-100 break-words py-3 px-2 sm:px-4 text-sm'
                                dangerouslySetInnerHTML={{
                                    __html:
                                        sanitizeHtml(episode.description) || t('No description'),
                                }}
                            ></div>
                        </div>
                        <div className='space-y-1'>
                            <div className='text-sm font-medium'>{t('Details')}</div>
                            <div className='border capitalize border-gray-100 rounded max-w-xl grid row-gap-3 grid-cols-2 px-3 py-2 text-sm'>
                                <span>Visibility</span>
                                <span>{episode.episodeIsHidden ? t('Hidden') : t('Visible')}</span>
                                <span>Explicit</span>
                                <span>{episode.isExplicit ? t('Yes') : t('No')}</span>
                                <span>Size</span>
                                {episode.mediaSize ? (
                                    <span>{prettyBytes(parseInt(episode.mediaSize))}</span>
                                ) : (
                                    t('N/A')
                                )}
                                <span>Episode Type</span>
                                <span>{episode.episodeType}</span>
                                <span>Duration</span>
                                <span>{prettyDuration}</span>
                                <span>Created</span>
                                {episode.createdAt && (
                                    <span>{format(new Date(episode.createdAt), 'PPp')}</span>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <EpisodeNotFound />
                )}
            </div>
        </div>
    )
}

Episode.Overview = Overview

export default Episode
