import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../state/actions.js'

//Subscribes to all kinds of account data listners
export const useAccountData = props => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (!activeTeamId) return

        dispatch(actions.subscribeShows())
        dispatch(actions.subscribeMeta())
        dispatch(actions.subscribeSettings())
        dispatch(actions.subscribePlans())
        dispatch(actions.subscribeActivityFeed())
        dispatch(actions.subscribePaymentMethods())
        dispatch(actions.subscribeCurrentMonthUsage())
        dispatch(actions.subscribeMembers())
        dispatch(actions.subscribeInvites())

        return () => dispatch({ type: 'listener/cleanup' })
    }, [dispatch, activeTeamId])
    return null
}
