import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { validateBilling } from '../util.js'
import { useTranslation } from 'react-i18next'
import Modal from '../components/modal.js'
import Icon from '../components/icon.js'

export default props => {
    const [modalOpen, setModalOpen] = React.useState(true)
    const { t } = useTranslation()
    const { paymentMethods, shows, meta } = useSelector(s => {
        return {
            paymentMethods: s.paymentMethods,
            shows: s.shows,
            meta: s.meta,
        }
    })

    if (validateBilling(shows, paymentMethods, meta)) return null

    return (
        <div className='mx-auto w-full px-4'>
            <Modal open={modalOpen}>
                <div className='pb-4'>
                    <div className='py-4 text-left'>
                        <div className='flex justify-center items-center pb-4'>
                            <Icon
                                name='exclamation'
                                className='h-8 w-8 mr-2 text-red-600 animate-pulse'
                            />
                            <p className='text-xl text-red-800 font-medium'>
                                {t('Your Shows are Blocked')}
                            </p>
                        </div>
                        <p
                            className={`px-2 text-sm rounded bg-yellow-50 text-yellow-900 py-2 rounded`}
                        >
                            Your shows are currently <strong>not available to public</strong>{' '}
                            because your trial has ended and you do not have a valid payment method.
                            Please enable billing by going to settings and adding a{' '}
                            <strong>payment method</strong>
                        </p>
                    </div>
                    <div className='flex justify-end items-end text-sm'>
                        <Link
                            to='/settings/billing'
                            onClick={() => setModalOpen(false)}
                            className={` border-gray-700 bg-white
                         relative transition duration-150 border group hover:bg-gray-700 hover:text-white rounded text-gray-900 focus:outline-none px-6 py-2 transition ease-in-out duration-150`}
                        >
                            <span className={``}>{t('Go to Billing Settings')}</span>
                        </Link>
                    </div>
                </div>
            </Modal>
            <div className='mt-16 mx-auto text-center font-medium text-xs border border-red-500 bg-red-50 text-black px-4 py-1 rounded-sm'>
                <div className='space-x-1 flex justify-center items-center'>
                    <div className='mx-1 flex justify-center items-center'>
                        <svg
                            className='mr-1 w-5 h-5 text-yellow-300'
                            fill='currentColor'
                            viewBox='0 0 20 20'
                        >
                            <path
                                d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
                                clipRule='evenodd'
                                fillRule='evenodd'
                            ></path>
                        </svg>
                        <p>{t('Important!')}</p>
                    </div>
                    <p className='font-normal'>
                        {t('Your shows requires billing to be enabled.')}{' '}
                        <Link className='underline font-medium' to='/settings/billing'>
                            Click here
                        </Link>{' '}
                        {t('to add a payment method.')}
                        {/* @Todo translate multi line component*/}
                    </p>
                </div>
            </div>
        </div>
    )
}
