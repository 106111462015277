import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default props => {
    return (
        <DatePicker
            name={props.name}
            className={props.className}
            selected={props.date}
            showTimeSelect
            onChange={props.onChange}
            shouldCloseOnSelect={false}
            timeIntervals={60}
            dateFormat='MMMM d, yyyy h:mm aa'
            todayButton='Today' //@Todo internatlization? :i18n
        />
    )
}
