import React from 'react'
import { Link } from 'gatsby'
import { useSiteMetadata } from '../hooks/site-metadata.js'
import { useTranslation } from 'react-i18next'
import monoLogo from '../images/mono-logo.svg'

const links = [
    {
        category: 'Company',
        links: [
            { title: 'Home', href: 'https://mono.fm' },
            { title: 'About', href: 'https://mono.fm/about' },
            { title: 'Blog', href: 'https://mono.fm/blog' },
        ],
    },
    {
        category: 'Product',
        links: [{ title: 'Pricing', href: 'https://mono.fm/#pricing' }],
    },

    {
        category: 'Help',
        links: [
            { title: 'Documentation', href: 'https://mono.fm/docs' },
            {
                title: 'Support',
                href: 'mailto:support@mono.fm',
            },
        ],
    },
    {
        category: 'Legal',
        links: [
            { title: 'Privacy', href: 'https://mono.fm/privacy' },
            { title: 'Terms of Service', href: 'https://mono.fm/terms' },
        ],
    },
]

export default props => {
    const {
        links: { twitter },
    } = useSiteMetadata()
    const { t } = useTranslation()
    return (
        <footer className='text-sm mt-20 px-8 py-8 bg-white border-t border-gray-200 w-full '>
            <div className='max-w-5xl mx-auto'>
                <div className='grid grid-cols-2 row-gap-4 sm:flex justify-between'>
                    {links.map(l => {
                        return (
                            <div key={l.category} className='col-span-1 sm:w-auto mx-2'>
                                <h2 className='font-medium text-gray-600 border-b border-gray-200 inline-block pb-2 uppercase tracking-wide text-xs'>
                                    {t(l.category)}
                                </h2>
                                <ul className='mt-3'>
                                    {l.links.map(link => (
                                        <li key={link.url + link.title} className='my-1'>
                                            {link.url && (
                                                <Link
                                                    className='hover:text-black text-gray-700'
                                                    to={link.url}
                                                >
                                                    {t(link.title)}
                                                </Link>
                                            )}
                                            {link.href && (
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className='hover:text-black text-gray-700'
                                                    href={link.href}
                                                >
                                                    {t(link.title)}
                                                </a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                    })}
                </div>
                <div className='flex-col sm:flex-row flex justify-center items-center my-8'>
                    <div className='flex-1 px-0 text-center'>
                        <div className=''>
                            <Link
                                to='/'
                                className='flex justify-center sm:justify-start items-center'
                            >
                                <img
                                    alt='Mono FM'
                                    width='80px'
                                    height='100%'
                                    className='text-gray-300 group-hover:text-gray-500 transform duration-150 scale-95 group-hover:scale-100'
                                    src={monoLogo}
                                />
                            </Link>
                        </div>
                        <div className='text-xs text-center sm:text-left font-light my-2'>
                            <span>Copyright</span> © 2020 Mono FM. All rights reserved.
                        </div>
                    </div>
                    <div className='flex-1 text-center flex items-center justify-end font-medium text-gray-600'>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={twitter}
                            className='text-gray-500 hover:text-gray-700 border-r px-3 py-1 mr-3'
                        >
                            <svg
                                height='16'
                                viewBox='0 0 18 15'
                                aria-label='twitter'
                                fill='currentColor'
                            >
                                <path
                                    d='M18 1.684l-1.687 1.684v.28c0 .307-.05.602-.123.886-.04 2.316-.777 5.387-3.816 7.81C6.404 17.115 0 12.907 0 12.907c5.063 0 5.063-1.684 5.063-1.684-1.126 0-3.376-2.243-3.376-2.243.563.56 1.689 0 1.689 0C.56 7.295.56 5.61.56 5.61c.563.561 1.689 0 1.689 0C-.563 3.368 1.124.561 1.124.561 1.687 3.368 9 4.49 9 4.49l.093-.046A6.637 6.637 0 0 1 9 3.368C9 1.353 10.636 0 12.656 0c1.112 0 2.094.506 2.765 1.286l.329-.163L17.437 0l-1.122 2.245L18 1.684z'
                                    fillRule='nonzero'
                                />
                            </svg>
                        </a>
                        <a
                            className='hover:text-black'
                            target='_blank'
                            rel='noopener noreferrer'
                            href='mailto:support@mono.fm'
                        >
                            support@mono.fm
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
