import React from 'react'
import { useOnClickOutside } from '../hooks/on-click-outside.js'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../state/actions.js'
import { Spinner3 } from '../components/spinner.js'
import { useTranslation } from 'react-i18next'

export default props => {
    const [open, setOpen] = React.useState(false)
    const ref = React.useRef()
    useOnClickOutside(ref, () => setOpen(false))
    const { t } = useTranslation()

    const auth = useSelector(s => s.auth)
    const dispatch = useDispatch()
    const { activeTeamId, teams } = useSelector(s => ({
        activeTeamId: s.activeTeamId,
        teams: s.teams,
    }))
    if (!auth || !auth.data) return

    const teamsList = Object.values(teams.data)

    return (
        <div ref={ref} className='md:relative'>
            <div>
                <button
                    aria-label='Toggle Teams Dropdown'
                    onClick={() => setOpen(!open)}
                    type='button'
                    className='border border-gray-100 hover:border-gray-500 rounded p-1 inline-flex justify-center mx-3 text-gray-500 hover:text-gray-800 focus:outline-none active:border-gray-900 transition ease-in-out duration-150'
                >
                    <svg className='h-4 w-4' fill='currentColor' viewBox='0 0 20 20'>
                        <path
                            d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                            clipRule='evenodd'
                            fillRule='evenodd'
                        ></path>
                    </svg>
                </button>
            </div>

            {open && (
                <div className='absolute overflow-hidden z-50 left-0 right-0 md:left-0 border border-gray-100 text-sm text-gray-600 hover:text-gray-900 font-light mx-2 md:mx-0 mt-3 md:w-96 rounded shadow-xl'>
                    <div className='rounded bg-white shadow-xs'>
                        <h4 className='bg-gray-100 text-xs text-center font-medium px-4 py-2 '>
                            {t('Switch Accounts')}
                        </h4>
                        <div className='border-t border-gray-100' />
                        {teams.loading ? (
                            <div className='py-8'>
                                <Spinner3 className='w-6 h-6' />
                            </div>
                        ) : (
                            <>
                                <h4 className='text-xs text-gray-500 font-medium uppercase leading-none tracking-wide text-black px-4 py-2 mt-2'>
                                    {t('Personal Accounts')}
                                </h4>
                                <div className=''>
                                    <button
                                        disabled={activeTeamId === auth.data.id}
                                        onClick={() => {
                                            setOpen(false)
                                            dispatch(actions.switchTeams(auth.data.id, true))
                                        }}
                                        className={`${
                                            activeTeamId === auth.data.id && 'cursor-default'
                                        } flex items-center w-full text-left px-4 py-2 leading-5 font-medium hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                                    >
                                        {auth.data.photoUrl ? (
                                            <img
                                                className='inline-block text-white rounded-full'
                                                width='30'
                                                height='30'
                                                src={auth.data.photoUrl}
                                                alt={auth.data.name}
                                            />
                                        ) : (
                                            <svg
                                                className='my-auto h-8 w-8 border-gray-100 border rounded-full p-1 text-gray-500'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='2'
                                                    d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
                                                />
                                            </svg>
                                        )}

                                        <div>
                                            <span className='block pl-2'>{auth.data.name}</span>
                                            {activeTeamId === auth.data.id && (
                                                <span className='inline-block text-xs text-green-500 bg-green-100 rounded-full text-center px-2 ml-1 mt-1'>
                                                    {t('Active')}
                                                </span>
                                            )}
                                        </div>
                                    </button>
                                </div>
                                <div className='border-t border-gray-100' />
                                <h4 className='text-xs uppercase text-gray-500 font-medium leading-none tracking-wide text-black px-4 py-2 mt-1'>
                                    {t('Your Teams')}
                                </h4>
                                <div className='pb-2 overflow-hidden pr-2 md:pr-0'>
                                    {teamsList.length === 0 && (
                                        <p className='text-center py-2 text-gray-900'>
                                            {t('No teams available')}
                                        </p>
                                    )}
                                    {teamsList.map(team => (
                                        <div key={team.id} className='truncate'>
                                            <button
                                                disabled={activeTeamId === team.id}
                                                onClick={() => {
                                                    setOpen(false)
                                                    dispatch(actions.switchTeams(team.id, false))
                                                }}
                                                className={`${
                                                    activeTeamId === team.id && 'cursor-default'
                                                } flex items-center w-full text-left px-4 py-2 leading-5 font-medium hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                                            >
                                                {team.photoUrl ? (
                                                    <img
                                                        className='inline-block h-8 w-8 rounded-full border border-gray-200 text-white'
                                                        width='24'
                                                        height='24'
                                                        src={team.photoUrl}
                                                        alt={team.name}
                                                    />
                                                ) : (
                                                    <div className='h-8 w-8 flex-center rounded-full border border-gray-200'>
                                                        <svg
                                                            className='h-6 w-6 text-gray-500'
                                                            fill='none'
                                                            viewBox='0 0 24 24'
                                                            stroke='currentColor'
                                                        >
                                                            <path
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                                strokeWidth='2'
                                                                d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
                                                            />
                                                        </svg>
                                                    </div>
                                                )}
                                                <div>
                                                    <span className='block pl-2'>{team.name}</span>
                                                    {activeTeamId === team.id && (
                                                        <span className='inline-block text-xs text-green-500 bg-green-100 rounded-full text-center px-2 ml-1 mt-1'>
                                                            {t('Active')}
                                                        </span>
                                                    )}
                                                </div>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
