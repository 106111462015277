import React from 'react'
import SEO from '../components/seo.js'
import { useActivateShow, useShowSubscribe } from '../hooks/show.js'

const Show = ({ id, children }) => {
    useShowSubscribe(id)
    useActivateShow(id)

    return (
        <div className='mt-20'>
            <SEO title='Show Overview' />
            {children}
        </div>
    )
}

export default Show
