import React from 'react'
import Modal from '../../components/modal.js'
import SettingPanel from '../../components/setting-panel.js'
import * as actions from '../../state/actions.js'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

const makePermObject = permissions => {
    const result = {
        'shows:manage': {
            code: 'shows:manage',
            title: 'Manage Shows',
            description: 'Can create/edit shows and episodes',
            value: false,
        },
        'account:manage': {
            code: 'account:manage',
            title: 'Manage Account',
            description: 'Can update your account info including billing',
            value: false,
        },
        'usage:read': {
            code: 'usage:read',
            title: 'View Usage',
            description: "Can view your account's and show's usage data",
            value: false,
        },
        'analytics:read': {
            code: 'analytics:read',
            title: 'View Analytics',
            description: "Can view show's analytics",
            value: false,
        },
    }
    //@Todo this will crash if there is a new permission code
    permissions.forEach(p => {
        if (result[p]) {
            result[p].value = true
        } else {
            //@Todo log invalid permission, Is this newly added?
        }
    })
    return result
}

const MemberListItem = props => {
    const { member, activeTeamId } = props
    const permObject = makePermObject(member.permissions)
    const [expanded, setExpanded] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [thePerm, setThePerm] = React.useState('')
    const [updatingPermissions, setUpdatingPermissions] = React.useState(false)
    const handlePermissionChange = async e => {
        setThePerm(e.target.name)
        setUpdatingPermissions(true)
        try {
            await actions.updatePermission(
                e.target.name,
                e.target.checked,
                member.id,
                !!member.joinedAt,
                activeTeamId,
            )
        } catch (err) {
            //@Todo show error
        } finally {
            try {
                setUpdatingPermissions(false)
                setThePerm('')
            } catch (err) {}
        }
    }
    const handleRemoveMember = async e => {
        setLoading(true)
        if (!!member.joinedAt) {
            await actions.removeMember(member.id)
        } else {
            await actions.removeInvite(member.id)
        }
        setLoading(false)
    }
    return (
        <div className='hover:shadow'>
            <button
                type='button'
                onClick={() => setExpanded(!expanded)}
                className='w-full focus:outline-none active:outline-none px-4 py-2 text-sm flex items-center '
            >
                <p className='text-left w-2/5'>{member.name || 'Member'}</p>
                <p className='text-left w-2/5 '>{member.email || 'Member'}</p>
                {member.joinedAt ? (
                    <span className='w-1/5 px-3 text-center text-green-800 rounded-full'>
                        Joined
                    </span>
                ) : (
                    <span className='w-1/5 text-center px-1 bg-yellow-100 text-yellow-700 rounded-full'>
                        Invite Pending
                    </span>
                )}

                <div className=' px-2'>
                    <svg fill='currentColor' viewBox='0 0 20 20' className='w-5 h-5'>
                        {expanded ? (
                            <path
                                d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
                                clipRule='evenodd'
                                fillRule='evenodd'
                            ></path>
                        ) : (
                            <path
                                fillRule='evenodd'
                                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                clipRule='evenodd'
                            />
                        )}
                    </svg>
                </div>
            </button>
            {expanded && (
                <div className='border-t border-gray-100'>
                    <div className='py-3 px-4'>
                        <h4 className='font-medium text-sm'>Permissions</h4>
                        <ul className='mt-2 mb-3'>
                            {Object.values(permObject).map(perm => {
                                return (
                                    <li
                                        key={perm.code}
                                        className={`${
                                            loading && 'pointer-events-none'
                                        } mb-2 rounded py-1 px-4 hover:bg-gray-100 ${
                                            updatingPermissions &&
                                            thePerm === perm.code &&
                                            'pointer-events-none'
                                        }   `}
                                    >
                                        <label className='cursor-pointer flex items-center justify-between text-sm'>
                                            <div className=''>
                                                <span className='block '>{perm.title}</span>
                                                <span className='block text-xs'>
                                                    {perm.description}
                                                </span>
                                            </div>
                                            <div className='relative'>
                                                <span className='absolute inset-0 w-full h-full flex-center'>
                                                    <svg
                                                        className={`h-6 w-6 spin ${
                                                            updatingPermissions &&
                                                            thePerm === perm.code
                                                                ? 'opacity-100'
                                                                : 'opacity-0'
                                                        }`}
                                                        viewBox='0 0 24 24'
                                                    >
                                                        <path
                                                            className='text-gray-600'
                                                            fill='currentColor'
                                                            d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                                        />
                                                        <path
                                                            className='text-gray-400'
                                                            fill='currentColor'
                                                            d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                                        />
                                                    </svg>
                                                </span>
                                                <input
                                                    disabled={updatingPermissions}
                                                    onChange={handlePermissionChange}
                                                    checked={perm.value}
                                                    name={perm.code}
                                                    type='checkbox'
                                                    className={`form-checkbox text-gray-700 h-5 w-5 ${
                                                        updatingPermissions &&
                                                        thePerm === perm.code &&
                                                        'opacity-0'
                                                    }`}
                                                />
                                            </div>
                                        </label>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>

                    <div className='px-6 py-2 flex justify-end bg-gray-100 text-sm'>
                        <button
                            type='button'
                            onClick={handleRemoveMember}
                            className={`border-gray-200 bg-gray-100 relative transition duration-150 border hover:border-gray-600 hover:text-gray-900 rounded text-gray-600 focus:outline-none px-5 py-1 transition ease-in-out duration-150`}
                        >
                            <span className='absolute inset-0 w-full h-full flex-center'>
                                <svg
                                    className={`h-6 w-6 spin ${
                                        loading ? 'opacity-100' : 'opacity-0'
                                    }`}
                                    viewBox='0 0 24 24'
                                >
                                    <path
                                        className='text-gray-600'
                                        fill='currentColor'
                                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                    />
                                    <path
                                        className='text-gray-400'
                                        fill='currentColor'
                                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                    />
                                </svg>
                            </span>
                            <span className={`${loading ? 'opacity-0' : 'opacity-100'}`}>
                                Remove {!!member.joinedAt ? 'Member' : 'Invite'}
                            </span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default props => {
    const dispatch = useDispatch()
    let { activeTeamId, teamMembers, teams } = useSelector(s => {
        const tm = s.members
        return {
            teams: s.teams,
            activeTeamId: s.activeTeamId,
            teamMembers: {
                ...tm,
                data: { ...tm.data, ...s.invites.data },
            },
        }
    })
    const { t } = useTranslation()
    const [loading] = React.useState(false)

    console.log(teams)

    const [error, setError] = React.useState(null)
    const [modalOpen, setModalOpen] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)
    const closeModal = e => {
        setError(null)
        setData({
            email: '',
            permissions: {
                'shows:manage': false,
                'account:manage': false,
                'usage:read': false,
                'analytics:read': false,
            },
        })
        setModalOpen(false)
    }

    const [data, setData] = React.useState({
        email: '',
        permissions: {
            'shows:manage': false,
            'account:manage': false,
            'usage:read': false,
            'analytics:read': false,
        },
    })

    const handleChange = e => {
        const result = { ...data }
        if (e.target.name === 'email') {
            result.email = e.target.value
        } else {
            result.permissions[e.target.name] = e.target.checked
        }
        setData(result)
    }
    const handleNewInviteButton = e => {
        setModalOpen(true)
    }
    const submitInvite = async e => {
        e.preventDefault()
        setSubmitting(true)
        setError(null)
        const permArr = []
        Object.entries(data.permissions).forEach(([name, value]) => {
            if (value) permArr.push(name)
        })
        try {
            await actions.inviteMember(data.email, permArr)
            closeModal()
        } catch (err) {
            setError(err.message)
        } finally {
            setSubmitting(false)
        }
    }

    const validForm = () => {
        return data.email && data.email !== ''
    }

    const teamsList = Object.values(teams.data)
    return (
        <div className='space-y-8 px-1 sm:px-0'>
            <SettingPanel title={t('Your Account Members')}>
                <SettingPanel.Content>
                    <p className='text-sm max-w-xl pb-2'>{t('invite-member-explainer')}</p>
                    <ul className='rounded'>
                        {Object.values(teamMembers.data).map(m => {
                            return (
                                <li
                                    key={`${m.id}-${m.email}`}
                                    className='border border-gray-100 rounded mb-3 shadow-sm'
                                >
                                    <MemberListItem member={m} activeTeamId={activeTeamId} />
                                </li>
                            )
                        })}
                    </ul>
                </SettingPanel.Content>

                <Modal open={modalOpen}>
                    <div className='py-4 text-left'>
                        <div className='flex justify-between items-center pb-4'>
                            <p className='text-xl font-medium'>{t('Invite Member')}</p>
                        </div>
                        <p
                            className={`${
                                error ? 'opacity-100 shake mb-2' : 'opacity-0'
                            } text-sm text-center transition duration-150 italic text-red-600 bg-red-100 py-1 px-2 mt-1 rounded`}
                        >
                            {error}
                        </p>
                        <form onSubmit={submitInvite}>
                            <label>
                                <span className='text-sm font-medium block pl-1 pb-1'>
                                    {t('Email')}
                                </span>
                                <input
                                    disabled={submitting}
                                    value={data.email}
                                    placeholder={t("Invitee's email")}
                                    onChange={handleChange}
                                    name='email'
                                    type='email'
                                    className='form-input w-full'
                                />
                            </label>

                            <div className='py-3 pl-1'>
                                <h4 className='font-medium text-sm mb-2'>{t('Permissions')}</h4>
                                <div>
                                    <label className='hover:bg-gray-100 py-1 px-2 flex items-center justify-between text-sm'>
                                        <div className=''>
                                            <span className='block '>{t('Manage Shows')}</span>
                                            <span className='block text-xs'>
                                                {t('Can create/edit shows and episodes.')}
                                            </span>
                                        </div>
                                        <input
                                            checked={data.permissions['shows:manage']}
                                            onChange={handleChange}
                                            name='shows:manage'
                                            type='checkbox'
                                            className='form-checkbox text-gray-700 h-5 w-5'
                                        />
                                    </label>
                                    <label className='hover:bg-gray-100 py-1 px-2 flex items-center justify-between text-sm mt-2'>
                                        <div className=''>
                                            <span className='block '>{t('Manage Account')}</span>
                                            <span className='block text-xs'>
                                                {t(
                                                    'Can update your account info including billing.',
                                                )}
                                            </span>
                                        </div>
                                        <input
                                            checked={data.permissions['account:manage']}
                                            onChange={handleChange}
                                            name='account:manage'
                                            type='checkbox'
                                            className='form-checkbox text-gray-700 h-5 w-5'
                                        />
                                    </label>
                                    <label className='hover:bg-gray-100 py-1 px-2 flex items-center justify-between text-sm mt-2'>
                                        <div className=''>
                                            <span className='block '>{t('View Usage')}</span>
                                            <span className='block text-xs'>
                                                {t("Can view your account's and show's usage data")}
                                            </span>
                                        </div>
                                        <input
                                            checked={data.permissions['usage:read']}
                                            onChange={handleChange}
                                            name='usage:read'
                                            type='checkbox'
                                            className='form-checkbox text-gray-700 h-5 w-5'
                                        />
                                    </label>
                                    <label className='hover:bg-gray-100 py-1 px-2 flex items-center justify-between text-sm mt-2'>
                                        <div className=''>
                                            <span className='block '>{t('View Analytics')}</span>
                                            <span className='block text-xs'>
                                                {t("Can view show's analytics")}
                                            </span>
                                        </div>
                                        <input
                                            checked={data.permissions['analytics:read']}
                                            onChange={handleChange}
                                            name='analytics:read'
                                            type='checkbox'
                                            className='form-checkbox text-gray-700 h-5 w-5'
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className='flex justify-end items-end text-sm pt-6'>
                                <button
                                    type='button'
                                    onClick={closeModal}
                                    disabled={submitting}
                                    className={`${
                                        submitting ? 'pointer-events-none' : ''
                                    } px-6 py-2 bg-transparent rounded-lg text-gray-600 hover:bg-gray-100 hover:text-gray-900 mr-2`}
                                >
                                    {t('Cancel')}
                                </button>
                                <button
                                    disabled={submitting || !validForm()}
                                    type='submit'
                                    className={`${submitting ? 'pointer-events-none' : ''} ${
                                        !validForm()
                                            ? 'bg-gray-300 cursor-not-allowed border-gray-300'
                                            : 'bg-gray-600 border-gray-600'
                                    } 
                         relative transition duration-150 border group hover:bg-white hover:text-gray-900 rounded text-white focus:outline-none px-6 py-2 transition ease-in-out duration-150`}
                                >
                                    <span className='absolute inset-0 w-full h-full flex-center'>
                                        <svg
                                            className={`h-6 w-6 spin ${
                                                submitting ? 'opacity-100' : 'opacity-0'
                                            }`}
                                            viewBox='0 0 24 24'
                                        >
                                            <path
                                                className='text-gray-600'
                                                fill='currentColor'
                                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                            />
                                            <path
                                                className='text-gray-400'
                                                fill='currentColor'
                                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                            />
                                        </svg>
                                    </span>
                                    <span
                                        className={`${
                                            submitting ? 'opacity-0' : 'opacity-100'
                                        } flex-center`}
                                    >
                                        {t('Invite')}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>

                <SettingPanel.Actions>
                    <button
                        type='button'
                        disabled={loading}
                        onClick={handleNewInviteButton}
                        className={`border-gray-600 bg-gray-600 relative transition duration-150 border hover:bg-white hover:text-gray-900 rounded text-white focus:outline-none px-5 py-1 transition ease-in-out duration-150`}
                    >
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${loading ? 'opacity-100' : 'opacity-0'}`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                        <span className={`${loading ? 'opacity-0' : 'opacity-100'}`}>
                            + {t('Invite Member')}
                        </span>
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
            <SettingPanel title={t('Your Teams')}>
                <SettingPanel.Content>
                    <p className='text-sm max-w-xl pb-2'>
                        {t('All the teams where you have been invited.')}
                    </p>
                    {teamsList.length > 0 ? (
                        <ul className='pb-8 rounded space-y-1'>
                            {teamsList.map(m => {
                                return (
                                    <li
                                        key={`${m.id}-${m.name}`}
                                        className='flex justify-between items-center text-sm border px-4 py-2 border-gray-100 rounded shadow-sm'
                                    >
                                        <span>{m.name}</span>
                                        <span className='text-xs'>
                                            Joined {format(new Date(m.joinedAt), 'PPp')}
                                        </span>
                                        <button
                                            onClick={() => dispatch(actions.switchTeams(m.id))}
                                            className='border hover:border-transparent border-gray-300 hover:bg-gray-900 hover:text-white bg-white text-xs leading-none px-2 py-1 rounded'
                                        >
                                            {t('Switch Teams')}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    ) : (
                        <div className='flex-center py-4 text-sm'>
                            {t("You haven't been invited to any team yet")}
                        </div>
                    )}
                </SettingPanel.Content>
            </SettingPanel>
        </div>
    )
}
