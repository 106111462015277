import React from 'react'
import '../../node_modules/react-vis/dist/style.css'
import { useLocalStorage } from '../hooks/localstorage.js'
import { useSelector } from 'react-redux'
import * as actions from '../state/actions.js'
import { formatDistanceToNow } from 'date-fns'
import { usePermissions } from '../hooks/permissions.js'
import NoPermissions from '../components/no-permissions.js'
import prettyBytes from 'pretty-bytes'
import { Spinner2 } from '../components/spinner.js'
import ListView from '../components/charting/listview.js'
import alphaCode from '../components/charting/alphaCode'
import Container from '../components/charting/container.js'
import SEO from '../components/seo.js'
import ReferrerLabel from '../components/charting/referrer-label.js'
import EpisodeLabel from '../components/charting/episode-label.js'
import countryCodes from '../data/countries.js'
import { noop } from '../util.js'
import { useTranslation } from 'react-i18next'
import {
    RadialChart,
    FlexibleXYPlot,
    XAxis,
    YAxis,
    Hint,
    HorizontalGridLines,
    AreaSeries,
} from 'react-vis'
import Map from '../components/charting/map'

const emptyAnalytics = {
    totalDownloads: 0,
    bandwidthUsedinGB: 0,
    uniqueVisitors: 0,
    topEpisodes: [],
    topDevices: [],
    topReferrers: [],
    topCountries: [],
    bytesSent: 0,
    downloadsSeries: [],
    bandwidthSeries: [],
}

export default props => {
    const canView = usePermissions('analytics:read')
    const [range, setRange] = useLocalStorage('analyticsRange', 'last30')
    const { analytics, loading } = actions.useShowAnalytics(props.id, range)
    const showEpisodes = useSelector(s => s.showEpisodes[props.id])
    const { t } = useTranslation()
    const [topCountryHover, setTopCountryHover] = React.useState(null)

    let episodeCount = 0
    if (showEpisodes && showEpisodes.data) {
        episodeCount = Object.keys(showEpisodes.data).length
    }

    let data = emptyAnalytics
    if (analytics) data = analytics

    let topCountries = data.topCountries || []
    topCountries = topCountries.sort((a, b) => b.value - a.value)

    let updatedAt = new Date()
    if (analytics && analytics.updatedAt) updatedAt = new Date(analytics.updatedAt)

    const [hovered, setHovered] = React.useState(null)
    const yMax = React.useMemo(() => {
        if (!data.downloadSeries) return 1000
        const max = data.downloadSeries.reduce((acc, row) => Math.max(acc, row.y), -Infinity)
        return max + max * 0.1 //Add some top buffer
    }, [data.downloadSeries])

    const series = {}
    data.topCountries.forEach(item => {
        series[alphaCode[item.title]] = Math.ceil((data.totalDownloads * item.value) / 100)
    })

    return (
        <div className='px-4 space-y-3'>
            <SEO title={t('Show Analytics')} />
            <div className='sm:flex justify-between items-center'>
                <h4 className='text-xl font-medium ml-2'>
                    {t('Show Analytics')}{' '}
                    <sup className='text-xs uppercase tracking-wide text-teal-500'>Beta</sup>
                </h4>
                <div className='flex items-center justify-end'>
                    <span className='text-xs text-gray-500 px-3 italic'>
                        Updated {formatDistanceToNow(updatedAt, { addSuffix: true })}
                    </span>
                    <select
                        onBlur={noop}
                        disabled={loading}
                        onChange={e => setRange(e.target.value)}
                        value={range}
                        className='form-select text-sm py-2'
                    >
                        <option value='last30'>{t('Last 30 Days')}</option>
                        <option value='last7'>{t('Last 7 Days')}</option>
                        <option value='last6m'>{t('Last 6 Months')}</option>
                        <option value='last1y'>{t('Last 1 Year')}</option>
                        <option value='lifetime'>{t('Lifetime')}</option>
                    </select>
                </div>
            </div>
            {loading ? (
                <Spinner2 />
            ) : canView ? (
                <div className='sm:grid grid-cols-12 grid-rows-1 gap-2'>
                    <Container className='col-span-12'>
                        <div className='px-4 py-0 flex-center divide-x divide-gray-200'>
                            <div className='flex-1 text-sm py-4 text-center'>
                                <span className='uppercase tracking-wide text-xs font-medium text-gray-600'>
                                    {t('Unique Downloads')}
                                </span>
                                <h4 className='text-2xl sm:text-5xl text-gray-700'>
                                    {data.totalDownloads}
                                </h4>
                            </div>
                            <div className='flex-1 text-sm py-4 text-center'>
                                <span className='uppercase tracking-wide text-xs font-medium text-gray-600'>
                                    {t('Total Episodes')}
                                </span>
                                <h4 className='flex-center text-2xl sm:text-5xl text-gray-700'>
                                    {episodeCount}
                                </h4>
                            </div>
                        </div>
                    </Container>
                    <Container
                        className='col-span-8'
                        title={t('Unique Downloads')}
                        helpText={t('Total number of downloads in given time range')}
                    >
                        <div className='px-3 h-96'>
                            {data.downloadSeries && data.downloadSeries.length > 0 ? (
                                <FlexibleXYPlot
                                    onMouseLeave={event => {
                                        setHovered(null)
                                    }}
                                    yDomain={[0, yMax]}
                                    getX={d => new Date(d.x)}
                                    xType='time-utc'
                                >
                                    <HorizontalGridLines />
                                    <XAxis tickLabelAngle={0} tickTotal={3} />
                                    <YAxis
                                        tickFormat={val => (Math.round(val) === val ? val : '')}
                                    />
                                    <AreaSeries
                                        onNearestX={d => setHovered(d)}
                                        data={data.downloadSeries}
                                    />

                                    {hovered && (
                                        <Hint value={hovered}>
                                            <div className='text-right bg-gray-900 text-white text-xs space-y-1 px-2 py-1 rounded'>
                                                <p>
                                                    {t('Date')}: {hovered.x}
                                                </p>
                                                <p>
                                                    {t('Downloads')}: {hovered.y}
                                                </p>
                                            </div>
                                        </Hint>
                                    )}
                                </FlexibleXYPlot>
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='col-span-4 h-116' title={t('Top Countries')}>
                        <div className='h-full overflow-y-auto'>
                            <div className='flex flex-col items-center'>
                                {topCountries.length > 0 && (
                                    <RadialChart
                                        onValueMouseOver={v => setTopCountryHover(v)}
                                        onSeriesMouseOut={() => setTopCountryHover(null)}
                                        width={200}
                                        height={190}
                                        padAngle={0.04}
                                        innerRadius={55}
                                        radius={70}
                                        getAngle={d => d.value}
                                        getLabel={d => d.value > 10 && d.title}
                                        showLabels
                                        labelsRadiusMultiplier={1.3}
                                        labelsStyle={{ fontSize: 12.5 }}
                                        data={data.topCountries}
                                    >
                                        {!!topCountryHover && (
                                            <Hint value={topCountryHover}>
                                                <div className='bg-gray-900 text-white text-xs space-y-1 px-2 py-1 rounded'>
                                                    <p>
                                                        {countryCodes[topCountryHover.title] ||
                                                            t('Others')}{' '}
                                                        {topCountryHover.value.toFixed(2)}%
                                                    </p>
                                                </div>
                                            </Hint>
                                        )}
                                    </RadialChart>
                                )}
                            </div>
                            {topCountries.length > 0 ? (
                                <ListView
                                    value={topCountries}
                                    unit='%'
                                    formatTitle={title => countryCodes[title] || t('Others')}
                                    formatValue={v => v.toFixed(1)}
                                />
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='hidden sm:block col-span-12 h-128' title={t('Map')}>
                        <Map series={series} />
                    </Container>
                    <Container className='col-span-6 h-96' title={t('Top Episodes')}>
                        <div className='h-full overflow-y-auto'>
                            {data.topEpisodes && data.topEpisodes.length > 0 ? (
                                <ListView
                                    value={data.topEpisodes}
                                    formatTitle={t => (
                                        <EpisodeLabel
                                            showId={props.id}
                                            epId={t}
                                            showEpisodes={showEpisodes}
                                        />
                                    )}
                                    unit=''
                                />
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='col-span-6 h-96' title={t('Top Clients')}>
                        <div className='h-full overflow-y-auto'>
                            {data.topDevices && data.topDevices.length > 0 ? (
                                <ListView
                                    value={data.topDevices}
                                    unit='%'
                                    formatValue={v => v.toFixed(2)}
                                />
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                    <Container className='col-span-5 h-full' title={t('Top Referrers')}>
                        <div className='h-80 overflow-y-auto'>
                            {data.topReferrers && data.topReferrers.length > 0 ? (
                                <ListView
                                    value={data.topReferrers}
                                    unit=''
                                    formatTitle={t => <ReferrerLabel title={t} />}
                                />
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>

                    <Container className='col-span-7' title={t('Bandwidth Used (MB)')}>
                        <div className='px-3 h-80'>
                            {data.bandwidthSeries && data.bandwidthSeries.length > 0 ? (
                                <FlexibleXYPlot
                                    onMouseLeave={event => {
                                        setHovered(null)
                                    }}
                                    getX={d => new Date(d.x)}
                                    getY={d => d.y / (1024 * 1024)}
                                    xType='time-utc'
                                >
                                    <HorizontalGridLines />
                                    <XAxis tickLabelAngle={0} tickTotal={3} />
                                    <YAxis
                                        tickFormat={val => (Math.round(val) === val ? val : '')}
                                    />
                                    <AreaSeries
                                        onNearestX={(datapoint, event) => {
                                            setHovered(datapoint)
                                        }}
                                        data={data.bandwidthSeries}
                                    />
                                    {hovered && (
                                        <Hint value={hovered}>
                                            <div className='text-right bg-gray-900 text-white text-xs space-y-1 px-2 py-1 rounded'>
                                                <p>
                                                    {t('Date')}: {hovered.x}
                                                </p>
                                                <p>
                                                    {t('Bandwidth')}: {prettyBytes(hovered.y)}
                                                </p>
                                            </div>
                                        </Hint>
                                    )}
                                </FlexibleXYPlot>
                            ) : (
                                <div className='flex-center h-full text-gray-600'>
                                    {t('No data found')}
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            ) : (
                <NoPermissions />
            )}
        </div>
    )
}
