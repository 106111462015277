import React from 'react'
const LoadingState = props => {
    return (
        <div className='relative bg-white shadow-xl border-t-4 border-gray-600 rounded-md mt-8 mx-2 px-8 py-12 flex flex-col justify-center items-center'>
            <span className='absolute inset-0 w-full h-full flex-center'>
                <svg className={`h-6 w-6 spin opacity-100`} viewBox='0 0 24 24'>
                    <path
                        className='text-gray-600'
                        fill='currentColor'
                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                    />
                    <path
                        className='text-gray-400'
                        fill='currentColor'
                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                    />
                </svg>
            </span>
        </div>
    )
}
export default LoadingState
