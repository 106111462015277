const categories = [
    {
        name: 'None',
        subCategories: [],
    },
    {
        name: 'Arts',
        subCategories: [
            'Books',
            'Design',
            'Fashion & Beauty',
            'Food',
            'Performing Arts',
            'Visual Arts',
        ],
    },
    {
        name: 'Business',
        subCategories: [
            'Careers',
            'Entrepreneurship',
            'Investing',
            'Management',
            'Marketing',
            'Non-Profit',
        ],
    },
    {
        name: 'Comedy',
        subCategories: ['Comedy Interviews', 'Improv', 'Stand-Up'],
    },
    {
        name: 'Education',
        subCategories: ['Courses', 'How To', 'Language Learning', 'Self-Improvement'],
    },
    {
        name: 'Fiction',
        subCategories: ['Comedy Fiction', 'Drama', 'Science Fiction'],
    },
    {
        name: 'Government',
        subCategories: [],
    },
    {
        name: 'History',
        subCategories: [],
    },
    {
        name: 'Health & Fitness',
        subCategories: [
            'Alternative Health',
            'Fitness',
            'Medicine',
            'Mental Health',
            'Nutrition',
            'Sexuality',
        ],
    },
    {
        name: 'Kids & Family',
        subCategories: ['Education for Kids', 'Parenting', 'Pets & Animals', 'Stories for Kids'],
    },
    {
        name: 'Leisure',
        subCategories: [
            'Animation & Manga',
            'Automotive',
            'Aviation',
            'Crafts',
            'Games',
            'Hobbies',
            'Home & Garden',
            'Video Games',
        ],
    },
    {
        name: 'Music',
        subCategories: ['Music Commentary', 'Music History', 'Music Interviews'],
    },
    {
        name: 'News',
        subCategories: [
            'Business News',
            'Daily News',
            'Entertainment News',
            'News Commentary',
            'Politics',
            'Sports News',
            'Tech News',
        ],
    },
    {
        name: 'Religion & Spirituality',
        subCategories: [
            'Buddhism',
            'Christianity',
            'Hinduism',
            'Islam',
            'Judaism',
            'Religion',
            'Spirituality',
        ],
    },
    {
        name: 'Science',
        subCategories: [
            'Astronomy',
            'Chemistry',
            'Earth Sciences',
            'Life Sciences',
            'Mathematics',
            'Natural Sciences',
            'Nature',
            'Physics',
            'Social Sciences',
        ],
    },
    {
        name: 'Society & Culture',
        subCategories: [
            'Documentary',
            'Personal Journals',
            'Philosophy',
            'Places & Travel',
            'Relationships',
        ],
    },
    {
        name: 'Sports',
        subCategories: [
            'Baseball',
            'Basketball',
            'Cricket',
            'Fantasy Sports',
            'Football',
            'Golf',
            'Hockey',
            'Rugby',
            'Running',
            'Soccer',
            'Swimming',
            'Tennis',
            'Volleyball',
            'Wilderness',
            'Wrestling',
        ],
    },
    {
        name: 'Technology',
        subCategories: [],
    },
    {
        name: 'True Crime',
        subCategories: [],
    },
    {
        name: 'TV & Film',
        subCategories: [
            'After Shows',
            'Film History',
            'Film Interviews',
            'Film Reviews',
            'TV Reviews',
        ],
    },
]

export const categoryForOptions = () => {
    const result = []

    categories.forEach(cat => {
        result.push(cat.name)
        cat.subCategories.forEach(sCat => {
            result.push(cat.name + ' : : ' + sCat)
        })
    })
    return result
}

export default categories
