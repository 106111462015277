import React from 'react'

export default props => {
    return (
        <div className='fixed inset-0 min-h-screen flex-center bg-gray-50'>
            <svg className={`h-10 w-10 spin`} viewBox='0 0 24 24'>
                <path
                    className='text-gray-600'
                    fill='currentColor'
                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                />
                <path
                    className='text-gray-400'
                    fill='currentColor'
                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                />
            </svg>
        </div>
    )
}

//@Todo really refactor these crappy spinners, these should be more generic and usable
export const Spinner2 = props => {
    return (
        <div className='flex-center bg-gray-50'>
            <svg className={`h-8 w-8 spin ${props.className}`} viewBox='0 0 24 24'>
                <path
                    className='text-gray-600'
                    fill='currentColor'
                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                />
                <path
                    className='text-gray-400'
                    fill='currentColor'
                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                />
            </svg>
        </div>
    )
}
//@Todo merge all these spinners into one
export const Spinner3 = props => {
    return (
        <div className='flex-center'>
            <svg className={`spin ${props.className || 'h-8 w-8'}`} viewBox='0 0 24 24'>
                <path
                    className='text-gray-600'
                    fill='currentColor'
                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                />
                <path
                    className='text-gray-400'
                    fill='currentColor'
                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                />
            </svg>
        </div>
    )
}
