import React from 'react'
import { Link } from 'gatsby'
import Modal from '../../components/modal.js'
import SEO from '../../components/seo.js'
import NoPermissions from '../../components/no-permissions.js'
import SettingPanel from '../../components/setting-panel.js'
import Teams from './teams.js'
import { useOnClickOutside } from '../../hooks/on-click-outside.js'
import { usePermissions } from '../../hooks/permissions.js'
import * as actions from '../../state/actions.js'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

//@Todo hardcoded key
const stripeKey = 'pk_live_J2zzJo2pKzHtntLJqjqUQo7s002mwLoPIN'
const stripePromise = loadStripe(stripeKey)

const routes = [
    { title: 'General', to: '/settings' },
    { title: 'Billing', to: '/settings/billing' },
    { title: 'Teams', to: '/settings/teams' },
    // { title: 'Advanced', to: '/settings/advanced' },
]

const DummyLoadingCard = () => {
    return (
        <div
            className={`flex items-center justify-center block text-center text-sm text-teal-500 border-gray-50 shadow rounded border hover:shadow-lg px-8 py-6 transition duration-150`}
        >
            <svg className={`h-5 w-5 mr-1 spin`} viewBox='0 0 24 24'>
                <path
                    className='text-gray-600'
                    fill='currentColor'
                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                />
                <path
                    className='text-gray-400'
                    fill='currentColor'
                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                />
            </svg>
            Hold on. It takes a few seconds for us to process the new card.
        </div>
    )
}

const Card = props => {
    const { method } = props
    const dispatch = useDispatch()
    const [toggleOpen, setToggleOpen] = React.useState(false)
    const ref = React.useRef()
    useOnClickOutside(ref, () => setToggleOpen(false))
    const { t } = useTranslation()

    const toggle = e => setToggleOpen(!toggleOpen)

    const [confirmed, setConfirmed] = React.useState(false)
    const handleCancel = () => setConfirmed(false)
    const handleDelete = () => {
        setToggleOpen(false)
        setConfirmed(true)
    }

    const handleDeleteSubmit = async () => {
        setToggleOpen(false)
        dispatch(actions.deleteCard(method.id))
    }
    const handleSetDefault = async () => {
        setToggleOpen(false)
        dispatch(actions.setCardDefault(method.id))
    }

    return (
        <div className='text-center'>
            <div
                className={`${
                    method.deleting ? 'border-red-500' : 'border-gray-50 shadow'
                } flex items-center text-left justify-between rounded border hover:shadow-lg px-8 py-6 transition duration-150`}
            >
                {method.loading ? (
                    <span className='inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${
                                method.loading ? 'opacity-100' : 'opacity-0'
                            }`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className={`${method.deleting ? 'text-red-600' : 'text-gray-600'}`}
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className={`${method.deleting ? 'text-red-400' : 'text-gray-400'}`}
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                ) : (
                    <>
                        <span className='flex-1 block text-gray-500 font-semibold'>
                            <span className='sm:inline hidden'>************</span>
                            <span className='inline sm:hidden'>**</span>
                            {method.last4}
                        </span>
                        <span className='flex-1 block uppercase text-sm tracking-widest text-gray-500 font-medium'>
                            {method.brand}
                        </span>
                        <span className='flex-1 block text-sm text-gray-600 font-medium'>
                            <span className='hidden sm:inline'>{t('Expiring')}:</span>{' '}
                            {method.expiryMonth} / {method.expiryYear}
                        </span>

                        <span className='hidden sm:block pr-4 text-sm text-gray-600 font-medium'>
                            <svg
                                fill='currentColor'
                                className={`h-5 w-5 text-teal-400 ${
                                    method.isDefault
                                        ? 'opacity-100'
                                        : 'opacity-0 pointer-events-none'
                                }`}
                                viewBox='0 0 20 20'
                            >
                                <path
                                    d='M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                    clipRule='evenodd'
                                    fillRule='evenodd'
                                ></path>
                            </svg>
                        </span>

                        <div className='relative' ref={ref}>
                            <button
                                aria-label='Toggle Dropdown'
                                type='button'
                                onClick={toggle}
                                className='rounded border-2 border-transparent hover:border-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none focus:border-gray-300 focus:shadow-outline active:text-gray-900 transition ease-in-out duration-150'
                            >
                                <svg fill='currentColor' viewBox='0 0 20 20' className='w-5 h-5'>
                                    <path
                                        fillRule='evenodd'
                                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                            </button>
                            {toggleOpen && (
                                <div className='origin-bottom-right absolute z-50 border border-gray-100 overflow-hidden right-0 text-sm text-gray-600 hover:text-gray-900 font-light w-36 rounded shadow-xl'>
                                    <div className='rounded bg-white shadow-xs'>
                                        {!method.isDefault && (
                                            <div className=''>
                                                <button
                                                    onClick={handleSetDefault}
                                                    className={`px-4 py-3 block w-full group leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                                                >
                                                    <span className=''>{t('Set as Default')}</span>
                                                </button>
                                            </div>
                                        )}
                                        <div className=''>
                                            <button
                                                onClick={handleDelete}
                                                className={`px-2 py-3 block w-full group leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                                            >
                                                <span className=''>{t('Delete Card')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <Modal open={confirmed}>
                <div className='pb-4'>
                    <div className='py-4 text-left'>
                        <div className='flex justify-between items-center pb-4'>
                            <p className='text-xl text-red-800 font-medium'>{t('Are you Sure?')}</p>
                        </div>
                        <p
                            className={`px-2 text-sm rounded bg-yellow-50 text-yellow-900 py-2 rounded`}
                        >
                            Sure you want to delete this card ending in {method.last4}? <br />
                            Deleting the last payment method will{' '}
                            <strong>disable your podcasts.</strong>
                            {/* @Todo translate formatted text*/}
                        </p>
                    </div>
                    <div className='flex justify-between items-end text-sm'>
                        <button
                            onClick={handleCancel}
                            disabled={method.deleting}
                            className={`${
                                method.deleting ? 'pointer-events-none' : ''
                            } px-6 py-2 bg-gray-100 rounded-lg text-gray-600 hover:bg-gray-200 hover:text-gray-900 mr-2`}
                        >
                            {t('Go Back')}
                        </button>
                        <button
                            disabled={method.deleting}
                            onClick={handleDeleteSubmit}
                            className={`${
                                method.deleting ? 'pointer-events-none' : ''
                            } border-red-700 bg-white
                         relative transition duration-150 border group hover:bg-red-700 hover:text-white rounded text-red-900 focus:outline-none px-6 py-2 transition ease-in-out duration-150`}
                        >
                            <span className='absolute inset-0 w-full h-full flex-center'>
                                <svg
                                    className={`h-6 w-6 spin ${
                                        method.deleting ? 'opacity-100' : 'opacity-0'
                                    }`}
                                    viewBox='0 0 24 24'
                                >
                                    <path
                                        className='text-red-600'
                                        fill='currentColor'
                                        d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                    />
                                    <path
                                        className='text-gray-400'
                                        fill='currentColor'
                                        d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                    />
                                </svg>
                            </span>
                            <span className={`${method.deleting && 'opacity-0'}`}>
                                {t('Delete Card')}
                            </span>
                        </button>
                    </div>
                </div>
            </Modal>

            <span
                className={`${
                    method.error ? 'opacity-100 shake' : 'opacity-0'
                } block mt-2 text-sm text-red-500`}
            >
                {method.error}
            </span>
        </div>
    )
}

const Settings = props => {
    const { t } = useTranslation()
    const canView = usePermissions('account:manage')
    return (
        <div className='sm:mt-6'>
            <SEO title={t('Account Settings')} />
            {canView ? (
                <>
                    <div className='sm:fixed sm:w-24 sm:ml-4 text-center sm:text-left text-sm text-gray-500 font-medium'>
                        {routes.map(r => (
                            <Link
                                key={r.to}
                                to={r.to}
                                activeClassName='text-gray-900'
                                className={`block py-2 mb-2 hover:text-gray-900`}
                            >
                                {t(r.title)}
                            </Link>
                        ))}
                    </div>
                    <div className='sm:ml-40'>{props.children}</div>
                </>
            ) : (
                <NoPermissions />
            )}
        </div>
    )
}

const General = props => {
    const { t } = useTranslation()
    const { activeTeamId, settings, auth } = useSelector(s => ({
        auth: s.auth,
        settings: s.settings,
        activeTeamId: s.activeTeamId,
    }))

    React.useEffect(() => {
        if (settings.data) {
            const newData = settings.data
            if (!newData.teamName && auth.data) newData.teamName = auth.data.name
            setData(newData)
            setChanged(false)
        }
    }, [settings, auth])

    const [data, setData] = React.useState({ teamName: '' })
    const [error, setError] = React.useState(null)
    const [changed, setChanged] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        try {
            await actions.updateAccountSettings(activeTeamId, data)
        } catch (err) {
            setError(err.message)
        }
        setLoading(false)
    }
    const handleChange = e => {
        const changedData = { ...data, [e.target.name]: e.target.value }
        setData(changedData)
        setChanged(changedData[e.target.value] === data[e.target.value])
    }
    if (!settings.data) return null

    return (
        <div className='space-y-8 px-1 sm:px-0'>
            <SettingPanel title={t('Team Name')} onSubmit={handleSubmit}>
                <SettingPanel.Content>
                    <label htmlFor='teamName'>
                        <input
                            disabled={loading}
                            name='teamName'
                            className='form-input w-full max-w-sm mt-2'
                            value={data.teamName}
                            onChange={handleChange}
                        />
                    </label>
                </SettingPanel.Content>
                <SettingPanel.Error error={error} />
                <SettingPanel.Actions>
                    <button
                        type='submit'
                        disabled={!changed}
                        className={`${
                            changed
                                ? 'border-gray-600 bg-gray-600'
                                : 'pointer-events-none bg-gray-300 border-gray-300'
                        } relative transition duration-150 border hover:bg-white hover:text-gray-900 rounded text-white focus:outline-none px-5 py-1 transition ease-in-out duration-150`}
                    >
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${loading ? 'opacity-100' : 'opacity-0'}`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                        <span className={`${loading ? 'opacity-0' : 'opacity-100'}`}>
                            {t('Save')}
                        </span>
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
        </div>
    )
}
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            //     color: '#32325d',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '16px',
            '::placeholder': {
                color: 'gray',
            },
        },
        // invalid: {
        //     color: '#fa755a',
        //     iconColor: '#fa755a',
        // },
    },
}

const PaymentHandlerComp = props => {
    const stripe = useStripe()
    const elements = useElements()

    const [error, setError] = React.useState(null)
    const [complete, setComplete] = React.useState(false)
    const [modalOpen, setModalOpen] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)

    const paymentMethods = useSelector(s => s.paymentMethods)
    const methods = Object.values(paymentMethods.data)
    const { t } = useTranslation()

    React.useEffect(() => {
        setSubmitted(false)
    }, [methods.length])

    const handleChange = e => {
        if (e.error) {
            setError(e.error.message)
        } else {
            setError(null)
        }
        setComplete(e.complete)
    }

    const closeModal = e => {
        setError(null)
        setModalOpen(false)
    }

    const handleBillingSubmit = async e => {
        e.preventDefault()
        if (!stripe || !elements) return
        setSubmitting(true)

        //@Todo handle this error
        const intent = await actions.api('intent_setup')

        const result = await stripe.confirmCardSetup(intent.clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: 'User', //@Todo incomplete
                },
            },
        })

        if (result.error) {
            console.error(result.error)
            setError(result.error.message)
            setSubmitting(false)
        } else {
            setSubmitting(false)
            setSubmitted(true)
            setModalOpen(false)
        }
    }

    const handleNewCardButton = e => {
        setComplete(false)
        setModalOpen(true)
    }

    return (
        <SettingPanel title={t('Payment Methods')} loading={paymentMethods.loading}>
            <SettingPanel.Content>
                <Modal open={modalOpen}>
                    <div className='py-4 text-left'>
                        <div className='flex justify-between items-center pb-4'>
                            <p className='text-xl font-medium'>{t('Add New Card')}</p>
                        </div>
                        <label
                            htmlFor='payment-method'
                            className={`${
                                submitting ? 'pointer-events-none' : ''
                            } py-4 px-2 bg-gray-100 rounded max-w-lg block text-lg font-medium`}
                        >
                            <CardElement
                                onChange={handleChange}
                                hidePostalCode={true}
                                options={CARD_ELEMENT_OPTIONS}
                            />
                        </label>

                        <p
                            className={`${
                                error ? 'opacity-100 shake' : 'opacity-0'
                            } text-sm text-center transition duration-150 italic text-red-600 bg-red-100 py-1 px-2 mt-1 rounded`}
                        >
                            {error}
                        </p>
                        <p className={`text-sm py-1 mt-1 mb-1 rounded`}>
                            {t(`
                            I authorise Mono FM to send instructions to the financial institution
                            that issued my card to take payments from my card account in accordance
                            with the terms of my agreement with you.
                        `)}
                        </p>
                        <div className='flex justify-end items-end text-sm pt-6'>
                            <button
                                onClick={closeModal}
                                disabled={submitting}
                                className={`${
                                    submitting ? 'pointer-events-none' : ''
                                } px-6 py-2 bg-transparent rounded-lg text-gray-600 hover:bg-gray-100 hover:text-gray-900 mr-2`}
                            >
                                {t('Cancel')}
                            </button>
                            <button
                                disabled={submitting || !complete}
                                onClick={handleBillingSubmit}
                                className={`${submitting ? 'pointer-events-none' : ''} ${
                                    complete ? '' : 'cursor-not-allowed hover:border-red-700'
                                } border-gray-600 bg-gray-600
                             relative transition duration-150 border group hover:bg-white hover:text-gray-900 rounded text-white focus:outline-none px-6 py-2 transition ease-in-out duration-150`}
                            >
                                <span className='absolute inset-0 w-full h-full flex-center'>
                                    <svg
                                        className={`h-6 w-6 spin ${
                                            submitting ? 'opacity-100' : 'opacity-0'
                                        }`}
                                        viewBox='0 0 24 24'
                                    >
                                        <path
                                            className='text-gray-600'
                                            fill='currentColor'
                                            d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                        />
                                        <path
                                            className='text-gray-400'
                                            fill='currentColor'
                                            d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`${submitting ? 'opacity-0' : 'opacity-100'} ${
                                        !complete && 'group-hover:text-red-600 group-hover:shake'
                                    } flex-center`}
                                >
                                    <svg
                                        fill='currentColor'
                                        viewBox='0 0 20 20'
                                        className={`w-4 h-4 mr-1 text-gray-400 group-hover:text-gray-500 transition duration-300 transform 
                                                        ${
                                                            complete
                                                                ? 'group-hover:rotate-90'
                                                                : 'group-hover:text-red-400'
                                                        }
                                                        `}
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                    {t('Add Card')}
                                </span>
                            </button>
                        </div>
                    </div>
                </Modal>

                {paymentMethods.loading ? (
                    <div className='relative'>
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${
                                    paymentMethods.loading ? 'opacity-100' : 'opacity-0'
                                }`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                    </div>
                ) : (
                    <>
                        <ul className='w-full mt-2 space-y-3'>
                            {methods.length === 0 && (
                                <div className='flex-center'>
                                    <div className='flex-center w-10 h-10 rounded-full bg-gray-100 text-gray-500 mr-1'>
                                        <svg
                                            fill='currentColor'
                                            viewBox='0 0 20 20'
                                            className='w-4 h-4'
                                        >
                                            <path d='M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z'></path>
                                            <path
                                                fillRule='evenodd'
                                                d='M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z'
                                                clipRule='evenodd'
                                            ></path>
                                        </svg>
                                    </div>
                                    <span className='text-gray-600 ml-1 '>{t('No Cards')}</span>
                                </div>
                            )}
                            {methods.map((method, index) => (
                                <li key={method.id}>
                                    <Card method={method} />
                                </li>
                            ))}
                            {submitted && (
                                <li>
                                    <DummyLoadingCard />
                                </li>
                            )}
                        </ul>
                    </>
                )}
            </SettingPanel.Content>
            <SettingPanel.Actions>
                <button
                    type='button'
                    onClick={handleNewCardButton}
                    className={`border-gray-600 bg-gray-600
                    relative transition duration-150 border group hover:bg-white hover:text-gray-900 rounded text-white focus:outline-none px-5 py-1 transition ease-in-out duration-150`}
                >
                    {t('Add New Card')}
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

//@Todo remove hardcoded plan pricing down here
const YourPlan = props => {
    const { t } = useTranslation()
    const plans = useSelector(s => s.plans)
    const plansList = Object.values(plans.data)
    return (
        <SettingPanel title={t('Your Plans')} loading={plans.loading}>
            <SettingPanel.Content>
                <ul className='space-y-2'>
                    {plansList.map(plan => {
                        return (
                            <li key={plan.name} className='border border-gray-50 rounded'>
                                <h3 className='py-2 px-3 text-sm font-medium bg-gray-100'>
                                    {t(plan.name)}
                                </h3>
                                <div className='flex rounded items-stretch'>
                                    <ul className='w-full text-sm flex-1 border border-gray-100'>
                                        <li className='grid grid-cols-12 divide-x divide-gray-100'>
                                            <span className='col-span-5 px-4 py-2'>
                                                {t('Bandwidth (Downloads)')}
                                            </span>
                                            <span className='col-span-7 px-4 py-2 text-center'>
                                                {t('$1 per 50GB per month')}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </SettingPanel.Content>
            <SettingPanel.Actions>
                <button
                    disabled={false}
                    className={`pointer-events-none bg-gray-300 border-gray-300 relative transition duration-150 border hover:bg-white hover:text-gray-900 rounded text-white focus:outline-none px-5 py-1 transition ease-in-out duration-150`}
                >
                    {t('Add Addons')}
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}
const Billing = props => {
    return (
        <div className='space-y-8 px-1 sm:px-0'>
            <Elements stripe={stripePromise}>
                <PaymentHandlerComp />
            </Elements>
            <YourPlan />
        </div>
    )
}
Settings.General = General
// Settings.Advanced = Advanced
Settings.Billing = Billing
Settings.Teams = Teams

export default Settings
