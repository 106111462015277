import React from 'react'
import firebase from '../lib/firebase'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default props => {
    const auth = useSelector(s => s.auth)
    const { t } = useTranslation()

    const [sent, setSent] = React.useState(false)
    const [sending, setSending] = React.useState(false)
    const resendVerification = async () => {
        setSending(true)
        const user = firebase.auth().currentUser
        //@Todo handle failure
        await user.sendEmailVerification()
        setSending(false)
        setSent(true)
    }

    if (auth.loading || auth.initting || !auth.data || auth.data.emailVerified) return null

    return (
        <div className='mx-auto w-full px-4'>
            <div className='mx-auto text-center font-medium text-xs border border-yellow-200 bg-yellow-50 text-black px-4 py-1 rounded-sm'>
                <div className='space-x-1 flex justify-center items-center'>
                    {sent ? (
                        <span className='inline-block ml-2 text-green-600 font-medium'>
                            {t(
                                'Done! Check your inbox, click the link and refresh this page once you are done.',
                            )}
                        </span>
                    ) : (
                        <div className='space-x-1 flex-center'>
                            {sending ? (
                                <div>
                                    <span className='ml-2 flex items-center justify-center'>
                                        <svg className={`h-4 w-4 spin`} viewBox='0 0 24 24'>
                                            <path
                                                className='text-gray-600'
                                                fill='currentColor'
                                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                            />
                                            <path
                                                className='text-gray-400'
                                                fill='currentColor'
                                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                            />
                                        </svg>
                                    </span>
                                </div>
                            ) : (
                                <div className='mx-1 flex justify-center items-center'>
                                    <svg
                                        className='mr-1 w-5 h-5 text-yellow-300'
                                        fill='currentColor'
                                        viewBox='0 0 20 20'
                                    >
                                        <path
                                            d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
                                            clipRule='evenodd'
                                            fillRule='evenodd'
                                        ></path>
                                    </svg>
                                    <p>
                                        {t(
                                            'Important! Email not verified. Check your inbox for verification link.',
                                        )}
                                        <button
                                            onClick={resendVerification}
                                            className='ml-2 font-medium focus:outline-none hover:text-black border-b py-0'
                                        >
                                            {t('Resend Email')}
                                        </button>
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
