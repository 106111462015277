import React from 'react'
import SEO from '../components/seo.js'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
    const { t } = useTranslation()
    return (
        <div className=''>
            <SEO title='404' />
            <div className='w-full h-full bg-white flex items-center justify-center '>
                <div className='max-w-sm m-8'>
                    <div className='text-black text-5xl md:text-15xl font-black'>404</div>
                    <div className='w-16 h-1 bg-purple-light my-3 md:my-6'></div>
                    <p className='text-grey-darker text-2xl md:text-3xl font-light mb-8 leading-normal'>
                        {t('404')}
                    </p>
                    <Link
                        to='/'
                        className='bg-transparent text-grey-darkest font-bold uppercase tracking-wide py-3 px-6 border-2 border-grey-light hover:border-grey rounded-lg'
                    >
                        {t('Go Home')}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage
