import React from 'react'
import * as actions from '../state/actions.js'
import { useSelector } from 'react-redux'
import { hasPermissions } from '../util.js'

export const usePermissions = name => {
    const { activeTeamId, userId } = useSelector(s => {
        const result = {
            activeTeamId: s.activeTeamId,
        }
        if (s.auth.data) {
            result.userId = s.auth.data.id
        }
        return result
    })

    const [canView, setCanView] = React.useState(true)
    React.useEffect(() => {
        if (activeTeamId === userId) return
        const run = async () => {
            const result = await actions.getPermissions(activeTeamId)
            setCanView(hasPermissions(result, name))
        }
        run()
    }, [activeTeamId, userId, name])
    return canView
}
