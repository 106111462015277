import React from 'react'

const SettingPanel = props => {
    const handleSubmit = e => {
        e.preventDefault()
        if (props.onSubmit) props.onSubmit(e)
    }

    return (
        <form
            onSubmit={handleSubmit}
            className={`overflow-hidden border shadow sm:shadow-xl rounded-md bg-white flex flex-col ${
                props.danger ? 'border-red-400' : 'border-gray-100'
            } `}
        >
            <div className='px-4 sm:px-8 pt-4 pb-2'>
                <h4 className='block text-lg font-medium'>{props.title}</h4>
            </div>
            {props.loading === true ? (
                <div className='relative my-auto h-24'>
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${
                                props.loading ? 'opacity-100' : 'opacity-0'
                            }`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                </div>
            ) : (
                props.children
            )}
        </form>
    )
}

const Content = props => {
    return <div className='px-4 sm:px-8'>{props.children}</div>
}

const Actions = props => {
    return (
        <div className='mt-6 px-6 py-3 flex justify-end bg-gray-100 text-sm'>{props.children}</div>
    )
}

const Error = props => {
    if (!props.error) return null
    return (
        <p className='mx-6 my-2 px-3 py-px bg-red-100 font-medium rounded shake text-sm text-center'>
            {props.error}
        </p>
    )
}
SettingPanel.Content = Content
SettingPanel.Actions = Actions
SettingPanel.Error = Error

export default SettingPanel
