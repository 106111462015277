import React from 'react'
import { useOnClickOutside } from '../hooks/on-click-outside.js'
import Select, { components } from 'react-select'
import i18next from 'i18next'
import { useLocalStorage } from '../hooks/localstorage.js'

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <svg viewBox='0 0 20 20' fill='currentColor' className='chevron-down w-5 h-5'>
                <path
                    fillRule='evenodd'
                    d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                    clipRule='evenodd'
                ></path>
            </svg>
        </components.DropdownIndicator>
    )
}

export default props => {
    const [open, setOpen] = React.useState(false)
    const ref = React.useRef()
    useOnClickOutside(ref, () => setOpen(false))
    const [currentLang, setCurrentLang] = useLocalStorage('language', 'en')
    React.useEffect(() => {
        i18next.changeLanguage(currentLang)
    }, [currentLang])

    const options = [
        { value: 'en', label: 'English' },
        // { value: 'es', label: 'Español' },
        { value: 'jp', label: '日本語' },
    ]

    const makeValue = key => {
        try {
            return options.filter(item => item.value === key)[0]
        } catch (err) {
            return options[0]
        }
    }
    const handleChange = v => {
        // actions.changeLang(e.target.value) //@Todo use the redux dispatch to this
        i18next.changeLanguage(v.value)
        setCurrentLang(v.value)
    }

    const styles = {
        control: base => ({
            ...base,
            border: '0.5px solid lightgray',
            minHeight: '25px',
            width: '100px',
        }),
        menu: base => ({
            ...base,
            width: '120px',
        }),
        valueContainer: base => ({
            ...base,
        }),
        container: base => ({
            ...base,
            fontSize: '0.85rem',
        }),
        option: base => ({
            ...base,
            fontSize: '0.85rem',
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: '4px 8px',
        }),
    }

    return (
        <Select
            isSearchable={false}
            components={{ IndicatorSeparator: null, DropdownIndicator }}
            defaultValue={makeValue(currentLang)}
            classNamePrefx='lang'
            styles={styles}
            onChange={handleChange}
            options={options}
        />
    )
}
