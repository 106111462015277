import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { subscribeEpisodes, subscribeShowUsage } from '../state/actions.js'

//Deletes the listener of show when its deleted
export const useShowDeleted = props => {
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (props.location.state && props.location.state.deletedShow) {
            dispatch({ type: 'listener/cleanup_show', id: props.location.state.deletedShow })
        }
    }, [props.location, dispatch])
    return null
}

export const useShow = showId => {
    const show = useSelector(s => {
        try {
            return s.shows.data[showId]
        } catch (err) {
            return null
        }
    })
    return show
}

//Update show state and make it active
export const useActivateShow = id => {
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: 'show/make_active', id })
        return () => {
            dispatch({ type: 'show/make_active', id: null })
        }
    }, [dispatch, id])

    return null
}

//Subscribes to show related episodes, usage etc.
export const useShowSubscribe = id => {
    const dispatch = useDispatch()
    const { show, epListener, usageListener } = useSelector(s => {
        return {
            show: s.shows.data[id],
            epListener: s.listeners[`episodes-${id}`],
            usageListener: s.listeners[`usage-${id}`],
        }
    })

    React.useEffect(() => {
        if (show && !epListener) {
            dispatch(subscribeEpisodes(id))
        }
        if (show && !usageListener) {
            dispatch(subscribeShowUsage(id))
        }
    }, [dispatch, id, show, epListener, usageListener])
    return null
}
