import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SEO from '../../components/seo.js'
import ExplicitTag from '../../components/explicit-tag'
import { useLocalStorage } from '../../hooks/localstorage.js'
import LoadingState from '../../components/loading-state.js'
import EmptyEpisodes from '../../components/episode/empty-episodes.js'
import SortBox from '../../components/sort-box.js'
import EpisodeCard from '../../components/episode/card.js'

const Episodes = props => {
    const { id } = props
    const { t } = useTranslation()

    const { episodes, loading } = useSelector(s => {
        const result = {
            loading: s.showEpisodes.loading,
            episodes: s.showEpisodes[id],
        }
        return result
    })

    const [currentSortBy, setCurrentSortBy] = useLocalStorage('episodeSortBy', 'createdAt')
    const options = [
        { value: 'releaseDate', label: 'Date Released' },
        { value: 'createdAt', label: 'Date Created' },
    ]
    if (loading || !episodes || !episodes.data) return <LoadingState />
    let epList = Object.values(episodes.data)
    if (epList.length === 0) return <EmptyEpisodes id={props.id} />

    if (currentSortBy === 'releaseDate') {
        epList.sort((first, second) => {
            const result = first.releaseDate < second.releaseDate
            return result ? 1 : -1
        })
    }
    if (currentSortBy === 'createdAt') {
        epList.sort((first, second) => {
            const result = first.createdAt < second.createdAt
            return result ? 1 : -1
        })
    }

    return (
        <div className='sm:px-4 h-full'>
            <SEO title='Show Episodes' />
            <div className='flex w-full items-center justify-between'>
                <h4 className='text-xl font-medium ml-2'>
                    All Episodes <span className='text-base'>(Total: {epList.length})</span>
                </h4>
                <SortBox
                    options={options}
                    onChange={setCurrentSortBy}
                    defaultValue={currentSortBy}
                />
            </div>
            <div className='bg-white shadow-xl border-t-4 border-gray-600 rounded-md mt-4 px-1 sm:px-8 pt-6 pb-12'>
                <ul className='max-w-3xl'>
                    {epList.map(e => {
                        return (
                            <li key={e.id} className='w-full mb-2'>
                                <EpisodeCard episode={e} id={props.id} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Episodes
