import React from 'react'
import { Link, navigate } from 'gatsby'
import { api, updateShow } from '../../state/actions.js'
import languages from '../../data/languages.js'
import categoriesList from '../../data/categories.js'
import Modal from '../../components/modal.js'
import SettingPanel from '../../components/setting-panel.js'
import FeedUrl from '../../components/feed-url.js'
import SEO from '../../components/seo.js'
import Editor from '../../components/rich-text-editor.js'
import ArtworkSettings from './artwork.js'
import { useSelector } from 'react-redux'
import ShowNotFound from '../../components/show-404.js'
import Website from './website.js'
import { noop } from '../../util.js'
import { useTranslation } from 'react-i18next'
const categories = []
categoriesList.forEach(cat => {
    categories.push(cat.name)
    cat.subCategories.forEach(sCat => {
        categories.push(cat.name + ' : : ' + sCat)
    })
})

const Settings = props => {
    const { id } = props
    const { t } = useTranslation()

    const routes = [
        { title: 'General', to: `/shows/${id}/settings` },
        { title: 'Website', to: `/shows/${id}/settings/website` },
        { title: 'Advanced', to: `/shows/${id}/settings/advanced` },
    ]

    return (
        <div className='mt-6'>
            <SEO title='Show Settings' />
            <div className='sm:fixed sm:w-24 sm:ml-4 text-center sm:text-left text-sm text-gray-600'>
                {routes.map(r => (
                    <Link
                        key={r.to}
                        to={r.to}
                        activeClassName='font-semibold'
                        replace
                        className={`block py-2 mb-2 hover:text-gray-900`}
                    >
                        {t(r.title)}
                    </Link>
                ))}
            </div>
            <div className='sm:ml-40'>{props.children}</div>
        </div>
    )
}

const HideShowPanel = props => {
    const { t } = useTranslation()
    const { show, loading, activeTeamId, auth } = useSelector(s => ({
        loading: s.shows.loading,
        show: s.shows.data[props.id],
        auth: s.auth,
        activeTeamId: s.activeTeamId,
    }))

    const [data, setData] = React.useState({
        showIsHidden: false,
    })

    React.useEffect(() => {
        if (!loading && show) {
            setData({ showIsHidden: !!show.showIsHidden })
            setChanged(false)
        }
    }, [show, loading])

    const [changed, setChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (show) {
            if (e.target.type === 'checkbox') {
                setChanged(show[e.target.name] !== e.target.checked)
            } else {
                setChanged(show[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value

        try {
            if (e.target.type === 'checkbox') {
                val = e.target.checked
            } else {
                val = parseInt(e.target.value)
                if (val < 0) {
                    val = 0
                }
            }
        } catch (err) {}
        setData({ ...data, [e.target.name]: val })
    }

    const handleSubmit = async e => {
        setSaving(true)
        setError(null)
        try {
            await updateShow(activeTeamId, props.id, {
                showIsHidden: data.showIsHidden,
                updatedBy: auth.data.id,
            })
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    return (
        <SettingPanel title={t('Hide Show In Apple Podcasts')} loading={loading}>
            <SettingPanel.Content>
                <p className='text-sm max-w-xl text-gray-700 pb-2'>{t('hide-show-explainer')}</p>

                {data && (
                    <div className='flex items-stretch space-x-4 mt-2 text-sm'>
                        <div className='flex flex-col items-center'>
                            <label className='text-sm flex-1 flex items-center '>
                                <input
                                    onBlur={noop}
                                    name='showIsHidden'
                                    className='w-5 h-5 form-checkbox mr-3'
                                    checked={data.showIsHidden}
                                    onChange={handleChange}
                                    type='checkbox'
                                />
                                <span>{t('Yes, Hide this show in Apple Podcasts')}</span>
                            </label>
                        </div>
                    </div>
                )}
            </SettingPanel.Content>
            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    onClick={handleSubmit}
                    disabled={!changed || loading}
                    className={`${saving ? 'pointer-events-none' : ''} ${
                        changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                    } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                >
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>{t('Save')}</span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

const ShowCompletedPanel = props => {
    const { t } = useTranslation()
    const { show, loading, activeTeamId, auth } = useSelector(s => ({
        loading: s.shows.loading,
        show: s.shows.data[props.id],
        auth: s.auth,
        activeTeamId: s.activeTeamId,
    }))

    const [data, setData] = React.useState({
        showIsComplete: false,
    })

    React.useEffect(() => {
        if (!loading && show) {
            setData({ showIsComplete: !!show.showIsComplete })
            setChanged(false)
        }
    }, [show, loading])

    const [error, setError] = React.useState(null)
    const [changed, setChanged] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (show) {
            if (e.target.type === 'checkbox') {
                setChanged(show[e.target.name] !== e.target.checked)
            } else {
                setChanged(show[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value

        try {
            if (e.target.type === 'checkbox') {
                val = e.target.checked
            } else {
                val = parseInt(e.target.value)
                if (val < 0) {
                    val = 0
                }
            }
        } catch (err) {}
        setData({ ...data, [e.target.name]: val })
    }

    const handleSubmit = async e => {
        setSaving(true)
        setError(null)
        try {
            await updateShow(activeTeamId, props.id, {
                showIsComplete: data.showIsComplete,
                updatedBy: auth.data.id,
            })
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    return (
        <SettingPanel title={t('Mark Show as Completed')} loading={loading}>
            <SettingPanel.Content>
                <p className='text-sm max-w-xl text-gray-700 pb-2'>
                    {t('mark-show-complete-explainer')}
                </p>

                {data && (
                    <div className='flex items-stretch space-x-4 mt-2 text-sm'>
                        <div className='flex flex-col items-center'>
                            <label className='text-sm flex-1 flex items-center '>
                                <input
                                    onBlur={noop}
                                    name='showIsComplete'
                                    className='w-5 h-5 form-checkbox mr-3'
                                    checked={data.showIsComplete}
                                    onChange={handleChange}
                                    type='checkbox'
                                />
                                <span>This show is completed</span>
                            </label>
                        </div>
                    </div>
                )}
            </SettingPanel.Content>
            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    onClick={handleSubmit}
                    disabled={!changed || loading}
                    className={`${saving ? 'pointer-events-none' : ''} ${
                        changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                    } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                >
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>{t('Save')}</span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

const LimitEpisodesPanel = props => {
    const { t } = useTranslation()
    const { show, loading, activeTeamId, auth } = useSelector(s => ({
        loading: s.shows.loading,
        show: s.shows.data[props.id],
        auth: s.auth,
        activeTeamId: s.activeTeamId,
    }))

    const [data, setData] = React.useState({
        limitEpisodes: false,
        limitCount: 20,
    })

    React.useEffect(() => {
        if (!loading && show) {
            setData({ limitEpisodes: !!show.limitEpisodes, limitCount: show.limitCount || 20 })
            setChanged(false)
        }
    }, [show, loading])

    const [changed, setChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (show) {
            if (e.target.type === 'checkbox') {
                setChanged(show[e.target.name] !== e.target.checked)
            } else {
                setChanged(show[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value

        try {
            if (e.target.type === 'checkbox') {
                val = e.target.checked
            } else {
                val = parseInt(e.target.value)
                if (val < 0) {
                    val = 0
                }
            }
        } catch (err) {}
        setData({ ...data, [e.target.name]: val })
    }

    const handleLimitSubmit = async e => {
        setSaving(true)
        setError(null)
        try {
            await updateShow(activeTeamId, props.id, {
                limitEpisodes: data.limitEpisodes,
                limitCount: data.limitCount,
                updatedBy: auth.data.id,
            })
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    return (
        <SettingPanel title={t('Limit Episodes in Feed')} loading={loading}>
            <SettingPanel.Content>
                <p className='text-sm max-w-xl text-gray-700 pb-2'>
                    {t('limit-episode-explainer')}
                </p>

                {data && (
                    <div className='flex items-stretch space-x-4 mt-2 text-sm'>
                        <div className='flex flex-col items-center'>
                            <span>{t('Enable')}</span>
                            <label
                                htmlFor='limitEpisodes'
                                className='text-sm flex-1 flex items-center '
                            >
                                <input
                                    onBlur={noop}
                                    name='limitEpisodes'
                                    className='w-5 h-5 form-checkbox'
                                    checked={data.limitEpisodes}
                                    onChange={handleChange}
                                    type='checkbox'
                                />
                            </label>
                        </div>
                        <div className={`flex-1`}>
                            <label htmlFor='limitCount' className='text-sm flex items-center'>
                                {t('No. of episodes to show')}
                            </label>

                            <input
                                onBlur={noop}
                                name='limitCount'
                                type='number'
                                value={data.limitCount}
                                onChange={handleChange}
                                disabled={!data.limitEpisodes}
                                className={`
                                        ${
                                            data.limitEpisodes
                                                ? 'opacity-100'
                                                : 'opacity-50 pointer-events-none'
                                        } mt-1 form-input text-sm transition ease-in-out duration-150                        `}
                                placeholder='20'
                            />
                        </div>
                    </div>
                )}
            </SettingPanel.Content>
            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    onClick={handleLimitSubmit}
                    disabled={!changed || loading}
                    className={`${saving ? 'pointer-events-none' : ''} ${
                        changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                    } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                >
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>{t('Save')}</span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

const MigratePanel = props => {
    const { t } = useTranslation()
    const { show, loading } = useSelector(s => ({
        loading: s.shows.loading,
        show: s.shows.data[props.id],
    }))

    const [data, setData] = React.useState({
        isRedirected: false,
        redirectUrl: '',
    })

    React.useEffect(() => {
        if (!loading && show) {
            setData({ isRedirected: !!show.isRedirected, redirectUrl: show.redirectUrl || '' })
            setChanged(false)
        }
    }, [show, loading])

    const [error, setError] = React.useState(null)
    const [changed, setChanged] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (show) {
            if (e.target.type === 'checkbox') {
                setChanged(show[e.target.name] !== e.target.checked)
            } else {
                setChanged(show[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value
        if (e.target.type === 'checkbox') val = e.target.checked
        setData({ ...data, [e.target.name]: val })
    }

    const handleMigrateSubmit = async e => {
        setSaving(true)
        setError(null)
        try {
            await api('show_migrate', {
                id: props.id,
                isRedirected: data.isRedirected,
                redirectUrl: data.redirectUrl,
            })
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    const isURL = str => {
        var pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i',
        ) // fragment locator
        return pattern.test(str)
    }

    const isValidRedirectURL = () => {
        if (data.isRedirected && data.redirectUrl !== '') {
            return isURL(data.redirectUrl)
        } else {
            return true
        }
    }

    return (
        <SettingPanel title={t('Redirect Show')} loading={loading}>
            <SettingPanel.Content>
                <p className='text-sm max-w-xl text-gray-700 pb-2'>
                    {t('redirect-show-explainer')}
                </p>
                {data && (
                    <div className='flex items-stretch space-x-4 mt-2 text-sm'>
                        <div className='flex flex-col items-center'>
                            <span>{t('Enable')}</span>
                            <label
                                htmlFor='isRedirected'
                                className='text-sm flex-1 flex items-center '
                            >
                                <input
                                    onBlur={noop}
                                    name='isRedirected'
                                    className='w-5 h-5 form-checkbox'
                                    checked={data.isRedirected}
                                    onChange={handleChange}
                                    type='checkbox'
                                />
                            </label>
                        </div>
                        <div className={`flex-1`}>
                            <label
                                htmlFor='redirectUrl'
                                className='text-sm flex justify-between items-center'
                            >
                                <span>{t('Redirect URL')}</span>

                                {!isValidRedirectURL() && (
                                    <span
                                        className={`py-1 px-2 duration-75 shake transition-opacity text-center text-gray-800 rounded leading-none bg-yellow-100 text-xs`}
                                    >
                                        {t(`
                                        URL seems invalid, please make sure this is correct before
                                        submitting.
                                    `)}
                                    </span>
                                )}
                            </label>
                            <input
                                onBlur={noop}
                                name='redirectUrl'
                                type='url'
                                value={data.redirectUrl}
                                onChange={handleChange}
                                disabled={!data.isRedirected}
                                className={`
                                        ${
                                            data.isRedirected
                                                ? 'opacity-100'
                                                : 'opacity-50 pointer-events-none'
                                        } mt-1 form-input w-full text-sm transition ease-in-out duration-150                        `}
                                placeholder='https://your-new-feed-url.com'
                            />
                        </div>
                    </div>
                )}
            </SettingPanel.Content>
            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    onClick={handleMigrateSubmit}
                    disabled={!changed}
                    className={`${saving ? 'pointer-events-none' : ''} ${
                        changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                    } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                >
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>{t('Save')}</span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

const Advanced = props => {
    const { t } = useTranslation()
    const { show, activeTeamId } = useSelector(s => ({
        activeTeamId: s.activeTeamId,
        show: s.shows.data[props.id],
    }))
    const [confirmed, setConfirmed] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const [modalOpen, setModalOpen] = React.useState(false)

    const handleCancel = e => {
        if (!loading) {
            setConfirmed(false)
            setModalOpen(false)
        }
    }
    const handleDeleteSubmit = async e => {
        setLoading(true)
        setError(null)
        try {
            await api('show_delete', { id: props.id, accountId: activeTeamId })
            navigate(`/`, { state: { deletedShow: props.id } })
        } catch (err) {
            setError(err.message)
        }
        setModalOpen(false)
        setLoading(false)
    }

    if (!loading && !show) {
        return (
            <div className='space-y-8'>
                <ShowNotFound />
            </div>
        )
    }

    let deleteModalTitle = t('Delete This Show.')
    if (show && show.title) {
        deleteModalTitle = `Delete Show '${show.title}'.`
    }

    return (
        <div className='space-y-8'>
            <LimitEpisodesPanel id={props.id} />
            <ShowCompletedPanel id={props.id} />
            <HideShowPanel id={props.id} />
            <MigratePanel id={props.id} />
            <SettingPanel title={t('Delete This Show')} danger={true}>
                <SettingPanel.Content>
                    <p className='text-sm max-w-xl text-gray-700 pb-2'>
                        {t('delete-show-explainer')}
                    </p>
                    <label className='text-sm flex items-center'>
                        <input
                            onBlur={noop}
                            className='form-checkbox'
                            checked={confirmed}
                            onChange={e => setConfirmed(e.target.checked)}
                            type='checkbox'
                        />
                        <span className='pl-2'>{t('delete-confirmation')}</span>
                    </label>
                </SettingPanel.Content>
                <Modal open={modalOpen} onClickOutside={handleCancel}>
                    <div className='pb-4'>
                        <div className='py-4 text-left'>
                            <div className='pb-4'>
                                <p className='text-xl text-red-800 font-medium'>
                                    {deleteModalTitle} {t('Are you Sure?')}
                                </p>
                            </div>
                            <p
                                className={`px-2 text-sm rounded font-medium bg-yellow-50 text-yellow-900 py-2 mb-2 rounded`}
                            >
                                Deleting a show is an irreversible operation! <br />
                                This action will have following effects:
                            </p>
                            <ul className='text-sm pl-4 mb-2 pr-8'>
                                <li className='pb-2'>
                                    &bull; {t('Your public feed will be removed')}
                                </li>
                                <li className='pb-2'>
                                    &bull; {t('All the episodes in this show will be removed')}
                                </li>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t('Your audience will no longer be able to access this show')}
                                </li>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t("All of this show's analytics will no longer be accessible")}
                                </li>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t('Usage data for this show will no longer be accessible')}
                                </li>
                                <li className='pb-2'>
                                    &bull;{' '}
                                    {t('Any unpaid usage will be added to your next invoice')}
                                </li>
                            </ul>
                        </div>
                        <div className='flex justify-between items-end text-sm pt-6'>
                            <button
                                onClick={handleCancel}
                                disabled={loading}
                                className={`${
                                    loading ? 'pointer-events-none' : ''
                                } px-6 py-2 bg-gray-100 rounded-lg text-gray-600 hover:bg-gray-200 hover:text-gray-900 mr-2`}
                            >
                                {t('Go Back')}
                            </button>
                            <button
                                disabled={loading}
                                onClick={handleDeleteSubmit}
                                className={`${
                                    loading ? 'pointer-events-none' : ''
                                } border-red-700 bg-white
                         relative transition duration-150 border group hover:bg-red-700 hover:text-white rounded text-red-900 focus:outline-none px-6 py-2 transition ease-in-out duration-150`}
                            >
                                <span className='absolute inset-0 w-full h-full flex-center'>
                                    <svg
                                        className={`h-6 w-6 spin ${
                                            loading ? 'opacity-100' : 'opacity-0'
                                        }`}
                                        viewBox='0 0 24 24'
                                    >
                                        <path
                                            className='text-red-600'
                                            fill='currentColor'
                                            d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                        />
                                        <path
                                            className='text-gray-400'
                                            fill='currentColor'
                                            d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`${
                                        loading ? 'opacity-0' : 'opacity-100'
                                    } flex-center`}
                                >
                                    {t('Yes I understand, delete this Show')}
                                </span>
                            </button>
                        </div>
                    </div>
                </Modal>
                <SettingPanel.Error error={error} />
                <SettingPanel.Actions>
                    <button
                        disabled={!confirmed}
                        onClick={() => setModalOpen(true)}
                        className={`${
                            confirmed
                                ? 'border-red-600 bg-red-600'
                                : 'pointer-events-none bg-gray-300 border-gray-300'
                        } transition duration-150 border hover:bg-white hover:text-red-900 rounded text-white focus:outline-none px-5 py-1 transition ease-in-out duration-150`}
                    >
                        {t('Delete Show')}
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
        </div>
    )
}

const AuthorInfo = props => {
    const { t } = useTranslation()
    const { activeTeamId, auth, show, loading } = useSelector(s => ({
        auth: s.auth,
        activeTeamId: s.activeTeamId,
        loading: s.shows.loading,
        show: s.shows.data[props.id],
    }))

    const [data, setData] = React.useState({
        author: '',
        email: '',
        copyright: '',
        website: '',
    })

    React.useEffect(() => {
        if (!loading && show) {
            setData(show)
            setChanged(false)
        }
    }, [show, loading])

    const [changed, setChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (show) {
            setChanged(show[e.target.name] !== e.target.value)
        }
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleSubmit = async e => {
        e.preventDefault()
        setSaving(true)
        setError(null)
        data.updatedBy = auth.data.id
        try {
            await updateShow(activeTeamId, props.id, data)
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    return (
        <SettingPanel title={t('Author Info')} onSubmit={handleSubmit} loading={loading}>
            <SettingPanel.Content>
                {show && (
                    <fieldset disabled={saving}>
                        <div className='sm:flex sm:space-x-3'>
                            <div className='flex-1 mt-3'>
                                <label htmlFor='author' className='text-sm block font-medium'>
                                    {t('Author Name')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-sm w-full'
                                    name='author'
                                    value={data.author}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex-1 mt-3'>
                                <label htmlFor='email' className='text-sm block font-medium'>
                                    {t('Email')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-sm w-full'
                                    name='email'
                                    value={data.email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='sm:flex sm:space-x-3'>
                            <div className='flex-1 mt-3'>
                                <label htmlFor='website' className='text-sm font-medium'>
                                    {t('Website')}
                                </label>
                                <input
                                    onBlur={noop}
                                    name='website'
                                    value={data.website}
                                    onChange={handleChange}
                                    className='w-full form-input text-sm '
                                    placeholder='https://yoursite.com'
                                    spellCheck='false'
                                />
                            </div>
                            <div className='flex-1 mt-3'>
                                <label htmlFor='copyright' className='text-sm font-medium'>
                                    {t('Copyright')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-sm w-full'
                                    name='copyright'
                                    value={data.copyright}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </fieldset>
                )}
            </SettingPanel.Content>
            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    type='submit'
                    disabled={!changed}
                    className={`${saving ? 'pointer-events-none' : ''} ${
                        changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                    } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                >
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>{t('Save')}</span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

const CategoryPanel = props => {
    const { t } = useTranslation()
    const { activeTeamId, auth, show, loading } = useSelector(s => ({
        auth: s.auth,
        activeTeamId: s.activeTeamId,
        loading: s.shows.loading,
        show: s.shows.data[props.id],
    }))

    const [data, setData] = React.useState({
        category1: '',
        category2: '',
        category3: '',
    })

    React.useEffect(() => {
        if (!loading && show) {
            setData(show)
            setChanged(false)
        }
    }, [show, loading])

    const [changed, setChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (show) {
            setChanged(show[e.target.name] !== e.target.value)
        }
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleSubmit = async e => {
        e.preventDefault()
        setSaving(true)
        setError(null)
        data.updatedBy = auth.data.id
        try {
            await updateShow(activeTeamId, props.id, data)
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    return (
        <SettingPanel title={t('Categories')} onSubmit={handleSubmit} loading={loading}>
            <SettingPanel.Content>
                {show && (
                    <fieldset disabled={saving} className='space-y-3'>
                        <div className='mt-1 sm:flex flex-col'>
                            <label htmlFor='category1' className='text-sm font-medium'>
                                {t('Category 1')}
                            </label>
                            <select
                                onBlur={noop}
                                name='category1'
                                value={data.category1}
                                onChange={handleChange}
                                className='mt-1 form-select'
                            >
                                {categories.map(cat => (
                                    <option key={cat}>{cat}</option>
                                ))}
                            </select>
                        </div>
                        <div className='mt-1 flex flex-col'>
                            <label htmlFor='category2' className='text-sm font-medium'>
                                {t('Category 2')}
                            </label>
                            <select
                                onBlur={noop}
                                value={data.category2}
                                onChange={handleChange}
                                name='category2'
                                className='mt-1 form-select'
                            >
                                {categories.map(cat => (
                                    <option key={cat}>{cat}</option>
                                ))}
                            </select>
                        </div>
                        <div className='mt-1 flex flex-col'>
                            <label htmlFor='category3' className='text-sm font-medium'>
                                {t('Category 3')}
                            </label>
                            <select
                                onBlur={noop}
                                name='category3'
                                value={data.category3}
                                onChange={handleChange}
                                className='mt-1 form-select'
                            >
                                {categories.map(cat => (
                                    <option key={cat}>{cat}</option>
                                ))}
                            </select>
                        </div>
                    </fieldset>
                )}
            </SettingPanel.Content>
            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    type='submit'
                    disabled={!changed}
                    className={`${saving ? 'pointer-events-none' : ''} ${
                        changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                    } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                >
                    <span className='absolute inset-0 w-full h-full flex-center'>
                        <svg
                            className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>{t('Save')}</span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}

const General = props => {
    const { t } = useTranslation(0)
    const { activeTeamId, auth, show, loading } = useSelector(s => ({
        auth: s.auth,
        activeTeamId: s.activeTeamId,
        loading: s.shows.loading,
        show: s.shows.data[props.id],
    }))

    const [data, setData] = React.useState({
        title: '',
        description: '',
        language: 'en',
        showType: 'episodic',
        isExplicit: true,
    })

    React.useEffect(() => {
        if (!loading && show) {
            setData(show)
            setChanged(false)
        }
    }, [show, loading])

    const [changed, setChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    const handleChange = e => {
        if (show) {
            if (e.target.type === 'checkbox') {
                setChanged(show[e.target.name] !== e.target.checked)
            } else {
                setChanged(show[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value
        if (e.target.type === 'checkbox') val = e.target.checked
        setData({ ...data, [e.target.name]: val })
    }
    const handleDescriptionChange = value => {
        const newDesc = value.toString('html')
        setChanged(newDesc !== show.description)
        setData({ ...data, description: newDesc })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setSaving(true)
        setError(null)
        data.updatedBy = auth.data.id
        try {
            await updateShow(activeTeamId, props.id, data)
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }

    if (!loading && !show) {
        return (
            <div className='space-y-8'>
                <ShowNotFound />
            </div>
        )
    }

    return (
        <div className='space-y-8'>
            <SettingPanel title={t("Show's Feed URL")} loading={loading}>
                <SettingPanel.Content>
                    <p className='text-sm max-w-xl text-gray-700 pb-2'>
                        {t(`
                        This is your feed URL. Use it to submit show to Apple Podcasts, Spotify etc.
                    `)}
                    </p>
                    {show && <FeedUrl hideHint={true} hideTitle={true} show={show} />}
                </SettingPanel.Content>
            </SettingPanel>
            <SettingPanel title={t('Show Details')} onSubmit={handleSubmit} loading={loading}>
                <SettingPanel.Content>
                    {show && (
                        <fieldset disabled={saving}>
                            <div className='flex-1 pt-3'>
                                <label htmlFor='title' className='text-sm block font-medium'>
                                    {t('Show Title')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-sm w-full mt-1'
                                    name='title'
                                    value={data.title}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex-1 mt-3'>
                                <label htmlFor='description' className='text-sm block font-medium'>
                                    {t('Description')}
                                </label>
                                <Editor
                                    className='w-full mt-1 form-textarea'
                                    initialValue={show.description}
                                    value={data.description}
                                    height='h-48'
                                    placeholder={t('Show Description')}
                                    onChange={handleDescriptionChange}
                                />
                            </div>
                            <div className='flex-1 mt-3 text-base'>
                                <label htmlFor='showType' className='text-sm block font-medium'>
                                    {t('Show Type')}
                                </label>
                                <div className='mt-1 flex items-center'>
                                    <select
                                        onBlur={noop}
                                        name='showType'
                                        value={data.showType}
                                        onChange={handleChange}
                                        className='w-full form-select text-sm mt-1'
                                    >
                                        <option value='episodic'>{t('Episodic')}</option>
                                        <option value='serial'>{t('Serial')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex mt-3 text-base'>
                                <div className='w-1/2'>
                                    <label
                                        htmlFor='isExplicit'
                                        className='text-sm block font-medium'
                                    >
                                        {t('Parental Advisory')}
                                    </label>
                                    <label className='mt-2 flex items-center'>
                                        <input
                                            onBlur={noop}
                                            className='mr-2 h-5 w-5 form-checkbox'
                                            name='isExplicit'
                                            checked={data.isExplicit}
                                            onChange={handleChange}
                                            type='checkbox'
                                        />
                                        {t('Is Explicit')}
                                    </label>
                                </div>
                                <div className='w-1/2'>
                                    <label htmlFor='language' className='text-sm block font-medium'>
                                        {t('Language')}
                                    </label>
                                    <select
                                        onBlur={noop}
                                        name='language'
                                        className='mt-1 form-select w-full'
                                        value={data.language}
                                        onChange={handleChange}
                                    >
                                        {languages.map(l => (
                                            <option key={l.code} value={l.code}>
                                                {l.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </fieldset>
                    )}
                </SettingPanel.Content>
                <SettingPanel.Error error={error} />
                <SettingPanel.Actions>
                    <button
                        type='submit'
                        disabled={!changed}
                        className={`${saving ? 'pointer-events-none' : ''} ${
                            changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                        } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                    >
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                        <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>
                            {t('Save')}
                        </span>
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
            <CategoryPanel {...props} />
            <AuthorInfo {...props} />
            <ArtworkSettings {...props} />
        </div>
    )
}

Settings.General = General
Settings.Website = Website
Settings.Advanced = Advanced

export default Settings
