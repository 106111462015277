import React from 'react'
import { useSelector } from 'react-redux'
import ShowNotFound from '../../components/show-404.js'
import SettingPanel from '../../components/setting-panel.js'
import * as actions from '../../state/actions.js'
import { noop } from '../../util.js'
import useClipboard from '../../hooks/clipboard.js'
import { useTranslation } from 'react-i18next'

const UrlPanel = props => {
    const { t } = useTranslation()
    const { activeTeamId, show, loading } = useSelector(s => ({
        activeTeamId: s.activeTeamId,
        loading: s.shows.loading,
        show: s.shows.data[props.id],
    }))
    const [data, setData] = React.useState({
        websiteEndpoint: '',
    })
    React.useEffect(() => {
        if (!loading && show) {
            setData(show)
            setChanged(false)
        }
    }, [show, loading])

    const [changed, setChanged] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [saving, setSaving] = React.useState(false)
    const [copied, setCopied] = useClipboard()
    const handleCopy = () => url && setCopied(url)

    const handleSubmit = async e => {
        setError(null)
        e.preventDefault()
        setSaving(true)
        try {
            await actions.renameWebsite(activeTeamId, props.id, data.websiteEndpoint)
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }
    const handleChange = e => {
        if (show) {
            if (e.target.type === 'checkbox') {
                setChanged(show[e.target.name] !== e.target.checked)
            } else {
                setChanged(show[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value

        setError(null)
        setData({ ...data, [e.target.name]: val })
    }

    if (!loading && !show) {
        return (
            <div className='space-y-8'>
                <ShowNotFound />
            </div>
        )
    }
    let url = `https://podcasts.mono.fm/`
    if (show && show.websiteEndpoint) {
        url += show.websiteEndpoint
    }

    return (
        <div className='space-y-8'>
            <SettingPanel title={t("Show's Website URL")} loading={loading} onSubmit={handleSubmit}>
                <SettingPanel.Content>
                    <p className='text-sm max-w-xl text-gray-700 pb-2'>
                        {t('show-website-explainer')}
                    </p>

                    <div className='text-sm pb-2'>
                        <div className='text-xs flex items-center'>
                            <a
                                href={url}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='group flex items-center hover:underline text-blue-600'
                            >
                                {url}
                                <svg
                                    className={`w-4 h-4 opacity-0 ml-1 transition duration-300 group-hover:opacity-100`}
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    viewBox='0 0 24 24'
                                    stroke='currentColor'
                                >
                                    <path d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'></path>
                                </svg>
                            </a>
                            <button
                                type='button'
                                aria-label='copy to clipboard'
                                onClick={handleCopy}
                                className={`${
                                    copied
                                        ? 'bg-green-500 text-white'
                                        : 'text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                                } w-6 transition duration-300 px-1 py-1 text-sm focus:outline-none `}
                            >
                                <div className='flex-center w-full'>
                                    <svg
                                        className={`w-4 h-4 ${!!copied && 'text-white'}`}
                                        fill='none'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        stroke='currentColor'
                                        viewBox='0 0 24 24'
                                    >
                                        <path d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2'></path>
                                    </svg>{' '}
                                </div>
                            </button>
                            {!!copied && <span className='ml-1 text-xs'>{t('Copied')}</span>}
                        </div>
                        <div className='border rounded text-sm overflow-hidden w-full mt-3 py-1'>
                            <fieldset disabled={saving}>
                                <label htmlFor='websiteEndpoint' className='pl-2 '>
                                    https://podcasts.mono.fm/
                                </label>
                                <input
                                    onBlur={noop}
                                    name='websiteEndpoint'
                                    value={data.websiteEndpoint}
                                    onChange={handleChange}
                                    className={`form-input focus:pl-2 focus:font-medium transition duration-300 border-none text-sm pl-0 py-1 rounded-sm ${
                                        error && 'shake text-red-900'
                                    }`}
                                />
                            </fieldset>
                        </div>
                    </div>
                </SettingPanel.Content>
                <SettingPanel.Error error={error} />
                <SettingPanel.Actions>
                    <button
                        type='submit'
                        disabled={!changed}
                        className={`${saving ? 'pointer-events-none' : ''} ${
                            changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                        } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                    >
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                        <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>
                            {t('Update Url')}
                        </span>
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
        </div>
    )
}
export default props => {
    const { t } = useTranslation()
    const { activeTeamId, auth, show, loading } = useSelector(s => ({
        auth: s.auth,
        activeTeamId: s.activeTeamId,
        loading: s.shows.loading,
        show: s.shows.data[props.id],
    }))
    const FORM_DEFAULTS = {
        websiteEndpoint: '',

        //Links
        appleUrl: '',
        spotifyUrl: '',
        googleUrl: '',
        youtubeUrl: '',
        twitterUrl: '',
        instagramUrl: '',
    }
    const [data, setData] = React.useState(FORM_DEFAULTS)
    React.useEffect(() => {
        if (!loading && show) {
            setData(show)
            setChanged(false)
        }
    }, [show, loading])

    const [error, setError] = React.useState(null)
    const [changed, setChanged] = React.useState(false)
    const [saving, setSaving] = React.useState(false)

    const handleSubmit = async e => {
        e.preventDefault()
        setSaving(true)
        setError(null)
        try {
            await actions.updateShow(activeTeamId, props.id, {
                ...data,
                updatedBy: auth.data.id,
            })
        } catch (err) {
            setError(err.message)
        }
        setSaving(false)
    }
    const handleChange = e => {
        if (show) {
            if (e.target.type === 'checkbox') {
                setChanged(show[e.target.name] !== e.target.checked)
            } else {
                setChanged(show[e.target.name] !== e.target.value)
            }
        }
        let val = e.target.value

        setData({ ...data, [e.target.name]: val })
    }

    if (!loading && !show) {
        return (
            <div className='space-y-8'>
                <ShowNotFound />
            </div>
        )
    }

    return (
        <div className='space-y-8'>
            <UrlPanel {...props} />
            <SettingPanel title={t('Links')} onSubmit={handleSubmit} loading={loading}>
                <SettingPanel.Content>
                    <p className='text-sm max-w-xl text-gray-700 pb-2'>{t('links-explainer')}</p>

                    {show && (
                        <fieldset disabled={saving}>
                            <div className='flex-1 mt-3 text-base max-w-lg'>
                                <label htmlFor='appleUrl' className='text-sm block font-medium'>
                                    {t('Apple Podcasts Link')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-xs w-full mt-1'
                                    name='appleUrl' //@Todo store these somewhere better than directly on /shows in db
                                    value={data.appleUrl}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex-1 mt-3 text-base max-w-lg'>
                                <label htmlFor='appleUrl' className='text-sm block font-medium'>
                                    {t('Spotify Link')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-xs w-full mt-1'
                                    name='spotifyUrl' //@Todo store these somewhere better than directly on /shows in db
                                    value={data.spotifyUrl}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex-1 mt-3 text-base max-w-lg'>
                                <label htmlFor='appleUrl' className='text-sm block font-medium'>
                                    {t('Google Podcasts Link')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-xs w-full mt-1'
                                    name='googleUrl' //@Todo store these somewhere better than directly on /shows in db
                                    value={data.googleUrl}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex-1 mt-3 text-base max-w-lg'>
                                <label htmlFor='appleUrl' className='text-sm block font-medium'>
                                    {t('YouTube Link')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-xs w-full mt-1'
                                    name='youtubeUrl' //@Todo store these somewhere better than directly on /shows in db
                                    value={data.youtubeUrl}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex-1 mt-3 text-base max-w-lg'>
                                <label htmlFor='appleUrl' className='text-sm block font-medium'>
                                    {t('Twitter Link')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-xs w-full mt-1'
                                    name='twitterUrl' //@Todo store these somewhere better than directly on /shows in db
                                    value={data.twitterUrl}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex-1 mt-3 text-base max-w-lg'>
                                <label htmlFor='appleUrl' className='text-sm block font-medium'>
                                    {t('Instagram Link')}
                                </label>
                                <input
                                    onBlur={noop}
                                    className='form-input text-xs w-full mt-1'
                                    name='instagramUrl' //@Todo store these somewhere better than directly on /shows in db
                                    value={data.instagramUrl}
                                    onChange={handleChange}
                                />
                            </div>
                        </fieldset>
                    )}
                </SettingPanel.Content>
                <SettingPanel.Error error={error} />
                <SettingPanel.Actions>
                    <button
                        type='submit'
                        disabled={!changed}
                        className={`${saving ? 'pointer-events-none' : ''} ${
                            changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                        } relative transition duration-150 font-medium border rounded px-5 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white `}
                    >
                        <span className='absolute inset-0 w-full h-full flex-center'>
                            <svg
                                className={`h-6 w-6 spin ${saving ? 'opacity-100' : 'opacity-0'}`}
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='text-gray-600'
                                    fill='currentColor'
                                    d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                />
                                <path
                                    className='text-gray-400'
                                    fill='currentColor'
                                    d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                />
                            </svg>
                        </span>
                        <span className={`${saving ? 'opacity-0' : 'opacity-100'}`}>
                            {t('Save')}
                        </span>
                    </button>
                </SettingPanel.Actions>
            </SettingPanel>
        </div>
    )
}
