import React from 'react'
import { api } from '../state/actions.js'
import SEO from '../components/seo.js'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import ParseFeedResult from '../components/import-feed-result.js'
import Error from '../components/error.js'

const ImportShow = props => {
    const { t } = useTranslation()
    const [loading, setLoading] = React.useState(false)
    const [url, setUrl] = React.useState('')
    const [error, setError] = React.useState(null)
    const [feed, setFeed] = React.useState(null)
    const handleSubmit = async (e, draft) => {
        e.preventDefault()
        setLoading(true)
        setError(null)

        try {
            const feed = await api('feed_parse', { url: url.trim() })
            setFeed(feed)
        } catch (err) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    const handleImport = async e => {
        e.preventDefault()
        setLoading(true)
        setError(null)

        try {
            const show = await api('show_import', { url: url.trim() })
            navigate(`/import-success?feedUrl=${show.feedUrl}&id=${show.id}`)
        } catch (err) {
            console.log('Getting error, isnide', err)
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }
    const handleReset = e => {
        setLoading(false)
        setError(null)
        setFeed(null)
        setUrl('')
    }

    const handleChange = e => setUrl(e.target.value)

    return (
        <div className='mx-auto max-w-5xl px-1'>
            <SEO title='Import Show' />
            <h2 className='text-xl font-medium'>{t('Import an Existing Show')}</h2>
            {feed ? (
                <ParseFeedResult
                    loading={loading}
                    feed={feed}
                    onReset={handleReset}
                    onImport={handleImport}
                    error={error}
                />
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className='mt-4 flex flex-col rounded shadow-lg bg-white border-t-4 border-gray-800 pt-3 pb-6'
                >
                    <div className='pb-6 px-2 sm:px-6 flex flex-col items-center'>
                        <h2 className='py-5 uppercase tracking-wide text-sm text-center font-medium text-gray-600'>
                            {t('Enter your RSS Feed URL')}
                        </h2>
                        <div className='sm:flex border rounded w-full overflow-hidden'>
                            <input
                                name='url'
                                disabled={loading}
                                value={url}
                                type='url'
                                onChange={handleChange}
                                className='form-input w-full text-center font-mono rounded-none  border-none'
                                placeholder='http://your_feed_url.com/rss'
                            />
                            <button
                                type='submit'
                                disabled={loading || !url}
                                className={`${loading ? 'pointer-events-none' : ''} ${
                                    !url
                                        ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                                        : 'text-gray-100 bg-gray-800'
                                } relative w-full sm:w-auto transition duration-150 font-medium px-6 py-2 focus:outline-none focus:shadow-outline text-white text-sm hover:bg-gray-600`}
                            >
                                <span className='absolute inset-0 w-full h-full flex-center'>
                                    <svg
                                        className={`h-6 w-6 spin ${
                                            loading ? 'opacity-100' : 'opacity-0'
                                        }`}
                                        viewBox='0 0 24 24'
                                    >
                                        <path
                                            className='text-gray-600'
                                            fill='currentColor'
                                            d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                        />
                                        <path
                                            className='text-gray-400'
                                            fill='currentColor'
                                            d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                        />
                                    </svg>
                                </span>
                                <span className={`${loading ? 'opacity-0' : 'opacity-100'}`}>
                                    {t('Submit')}
                                </span>
                            </button>
                        </div>
                        <Error msg={error} />
                    </div>
                </form>
            )}
        </div>
    )
}

export default ImportShow
