import React from 'react'

const Error = ({ msg }) => {
    if (!msg || msg === '') return null
    return (
        <span className='block px-3 py-1 bg-red-100 text-center text-red-900 font-medium mt-4 shake text-sm'>
            Error: {msg}
        </span>
    )
}

export default Error
