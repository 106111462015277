import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { useSelector } from 'react-redux'
import LanguageDropdown from '../components/language-dropdown.js'
import ProfileDropdown from '../components/profile-dropdown.js'
import MobileProfileDropdown from '../components/mobile-profile-dropdown.js'
import TeamsDropdown from '../components/teams-dropdown.js'
// import Notifications from '../components/notifications.js'
import Icon from '../components/icon.js'
import * as actions from '../state/actions'
import { truncate } from '../util.js'
import { useTranslation } from 'react-i18next'
import monoLogo from '../images/mono-logo.svg'

const TrialTag = props => {
    const { t } = useTranslation()
    return (
        <div className='relative group cursor-default'>
            <span className='flex items-center bg-green-50 group-hover:bg-green-100 text-green-900 font-semibold text-xs px-4 py-1 rounded-full'>
                <svg
                    className='hidden md:block flex-shrink-0 text-red-500 w-4 h-4 mr-1'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                >
                    <path
                        fillRule='evenodd'
                        d='M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z'
                        clipRule='evenodd'
                    ></path>
                </svg>
                <span className='inline md:hidden'>{t('Trial')}</span>
                <span className='hidden md:inline'>{t('30 Day Trial')}</span>
            </span>
            <div className='group-hover:block hidden absolute top-0 right-0 w-64 z-10 rounded mt-7 bg-gray-900 text-white text-xs px-4 py-3'>
                <p>{t('Trial Description')}</p>
            </div>
        </div>
    )
}

const makeMenu = (loc, show, episode, loading) => {
    if (!loc || loading) return [[], false]

    let isInner = false
    let isSuperInner = false
    let menu = [
        { title: 'Shows', to: '/', icon: 'collection' },
        { title: 'Usage', to: '/usage', icon: 'chart-bar' },
        { title: 'Activity', to: '/activity', icon: 'eye' },
        { title: 'Account Settings', to: '/settings', icon: 'settings' },
    ]

    if (loc.pathname.includes('/create') && show && loc.pathname.includes('shows')) {
        const { id } = show
        menu = [
            { title: 'Overview', to: `/shows/${id}`, icon: 'info' },
            { title: 'Episodes', to: `/shows/${id}/episodes`, icon: 'list' },
            { title: 'Analytics', to: `/shows/${id}/analytics`, icon: 'chart-pie' },
            { title: 'Usage', to: `/shows/${id}/usage`, icon: 'chart-bar' },
            { title: 'Settings', to: `/shows/${id}/settings`, icon: 'settings' },
        ]
        isInner = true
    }

    if (
        loc.pathname !== '/' &&
        !loc.pathname.includes('/import') &&
        !loc.pathname.includes('/create')
    ) {
        if (show) {
            const { id } = show
            menu = [
                { title: 'Overview', to: `/shows/${id}`, icon: 'info' },
                { title: 'Episodes', to: `/shows/${id}/episodes`, icon: 'list' },
                { title: 'Analytics', to: `/shows/${id}/analytics`, icon: 'chart-pie' },
                { title: 'Usage', to: `/shows/${id}/usage`, icon: 'chart-bar' },
                { title: 'Show Settings', to: `/shows/${id}/settings`, icon: 'settings' },
            ]
            isInner = true
        }
    }

    if (loc.pathname.includes(`/episodes/`) && !loc.pathname.includes('/episodes/create')) {
        if (show && episode) {
            const { id } = show
            const { id: epId } = episode
            menu = [
                {
                    title: 'Episode Overview',
                    to: `/shows/${id}/episodes/${epId}`,
                    icon: 'info',
                },
                //Note: We don't need dedicated episode analytics page as users
                //are not using this much. Let's comment this and see if the
                //world breaks or not.
                // {
                //     title: 'Analytics',
                //     to: `/shows/${id}/episodes/${epId}/analytics`,
                //     icon: 'chart-pie',
                // },
                {
                    title: 'Episode Settings',
                    to: `/shows/${id}/episodes/${epId}/settings`,
                    icon: 'settings',
                },
            ]
            isInner = true
            isSuperInner = true
        }
    }

    return [menu, isInner, isSuperInner]
}

export default props => {
    const { team, show, episode, loading, meta } = useSelector(s => {
        const result = {
            meta: s.meta,
            activeTeamId: s.activeTeamId,
            loading: s.auth.loading || s.shows.loading,
        }
        if (result.activeTeamId) {
            if (result.activeTeamId === s.auth.data.id) {
                result.team = s.auth.data
            } else {
                result.team = s.teams.data[result.activeTeamId]
            }
        }
        if (s.shows.data && s.activeShowId) {
            result.show = s.shows.data[s.activeShowId]
        }
        if (
            s.showEpisodes[s.activeShowId] &&
            s.showEpisodes[s.activeShowId].data &&
            s.activeEpisodeId
        ) {
            result.episode = s.showEpisodes[s.activeShowId].data[s.activeEpisodeId]
        }
        return result
    })
    const { t } = useTranslation()
    const loc = useLocation()
    const [mobileHeaderOpen, setMobileHeaderOpen] = React.useState(false)

    const closeMobileHeader = () => {
        setMobileHeaderOpen(false)
    }
    const toggleMobileHeader = () => {
        setMobileHeaderOpen(!mobileHeaderOpen)
    }

    const isTrial = () => {
        if (meta && !meta.loading && meta.data && meta.data.subscriptionStatus) {
            return meta.data.subscriptionStatus === 'trialing'
        }
        return false
    }
    const [menu, isInner, isSuperInner] = makeMenu(loc, show, episode, loading)

    return (
        <header className='fixed z-20 w-full pt-1 bg-white shadow-sm'>
            <div>
                <nav className='my-1 px-2 w-full max-w-5xl flex items-center justify-between mx-auto '>
                    <div className='flex items-center'>
                        <Link
                            to='/'
                            className='flex items-center group text-gray-700 hover:text-black'
                        >
                            <img
                                alt='Mono FM'
                                width='80px'
                                height='100%'
                                className='text-gray-300 group-hover:text-gray-500 transform duration-150 scale-95 group-hover:scale-100'
                                src={monoLogo}
                            />
                        </Link>

                        <div className='flex-center ml-3 group'>
                            <svg
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                stroke='currentColor'
                                viewBox='0 0 24 24'
                                className='w-5 h-5 text-gray-300 group-hover:text-gray-500'
                            >
                                <path d='M9 5l7 7-7 7'></path>
                            </svg>
                            {team && (
                                <>
                                    <Link to='/' className='ml-2 flex-center'>
                                        <div className='h-7 w-7 flex-center overflow-hidden border-2 border-gray-500 rounded-full'>
                                            {team.photoUrl ? (
                                                <img
                                                    className='object-cover h-full w-full'
                                                    alt='Profile'
                                                    width='100%'
                                                    height='100%'
                                                    src={team.photoUrl}
                                                />
                                            ) : (
                                                <svg
                                                    className='my-auto h-5 w-5 text-gray-500'
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    stroke='currentColor'
                                                >
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        strokeWidth='2'
                                                        d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        <span className='pl-2 text-sm font-medium'>
                                            {truncate(team.name, 15)}
                                        </span>
                                    </Link>
                                    <TeamsDropdown />
                                </>
                            )}
                        </div>
                        <div className='hidden md:flex justify-center items-center'>
                            {show &&
                                loc.pathname.includes('/shows/') &&
                                !loc.pathname.includes('/import') &&
                                !loc.pathname.includes('/create') && (
                                    <div className='group flex-center'>
                                        <svg
                                            fill='none'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                            stroke='currentColor'
                                            viewBox='0 0 24 24'
                                            className='w-5 h-5 text-gray-300 group-hover:text-gray-500'
                                        >
                                            <path d='M9 5l7 7-7 7'></path>
                                        </svg>
                                        <Link
                                            to={`/shows/${show.id}`}
                                            className='flex-center text-sm font-medium px-3 py-1 rounded text-gray-600 hover:text-gray-900 hover:bg-gray-100'
                                        >
                                            {truncate(show.title)}
                                        </Link>
                                    </div>
                                )}
                            {show &&
                                episode &&
                                loc.pathname.includes('/episodes/') &&
                                !loc.pathname.includes('/episodes/create') && (
                                    <div className='group flex-center'>
                                        <svg
                                            fill='none'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                            stroke='currentColor'
                                            viewBox='0 0 24 24'
                                            className='w-5 h-5 text-gray-300 group-hover:text-gray-500'
                                        >
                                            <path d='M9 5l7 7-7 7'></path>
                                        </svg>
                                        <Link
                                            to={`/shows/${show.id}/episodes/${episode.id}`}
                                            className='text-sm font-medium px-3 py-1 rounded text-gray-600 hover:text-gray-900 hover:bg-gray-200'
                                        >
                                            {truncate(episode.title)}
                                        </Link>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className='hidden md:flex items-center space-x-3 text-sm text-gray-500'>
                        {isTrial() && <TrialTag />}
                        <LanguageDropdown />
                        <ProfileDropdown />
                    </div>
                    <div className='flex md:hidden items-center'>
                        <div className='mr-1'>{isTrial() && <TrialTag />}</div>
                        <button
                            onClick={toggleMobileHeader}
                            className='rounded w-10 h-10 p-2 text-gray-700 block md:hidden focus:outline-none focus:shadow-outline'
                        >
                            <svg
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                            >
                                {mobileHeaderOpen ? (
                                    <path d='M6 18L18 6M6 6l12 12'></path>
                                ) : (
                                    <path d='M4 6h16M4 12h8m-8 6h16'></path>
                                )}
                            </svg>
                        </button>
                    </div>
                </nav>
                {mobileHeaderOpen && (
                    <div className='block md:hidden mx-auto '>
                        <div className='px-4 py-2 flex-center border-b border-gray-100'>
                            <LanguageDropdown />
                        </div>
                        <div className='px-4 py-2 border-b border-gray-100'>
                            <MobileProfileDropdown />
                        </div>

                        {!loading && (
                            <div className='w-full flex justify-center md:justify-end items-center text-sm font-medium'>
                                {show ? (
                                    <Link
                                        onClick={closeMobileHeader}
                                        to={`/shows/${show.id}/create`}
                                        className='hover:bg-gray-100 rounded px-3 py-2 text-gray-600 hover:text-gray-900 transition ease-in-out duration-150 flex justify-center items-center group'
                                    >
                                        <svg
                                            fill='currentColor'
                                            viewBox='0 0 20 20'
                                            className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                                                clipRule='evenodd'
                                            />
                                        </svg>
                                        <span>{t('Create Episode')}</span>
                                    </Link>
                                ) : (
                                    <div className='flex justify-end items-center space-x-3 font-medium'>
                                        <Link
                                            onClick={closeMobileHeader}
                                            to={`/import`}
                                            className='hover:bg-gray-100 rounded px-3 py-2 text-gray-600 hover:text-gray-900 transition ease-in-out duration-150 flex justify-center items-center group'
                                        >
                                            <Icon
                                                type='solid'
                                                name='download'
                                                className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                                            />
                                            <span>{t('Import Show')}</span>
                                        </Link>
                                        <Link
                                            onClick={closeMobileHeader}
                                            to={`/create`}
                                            className='hover:bg-gray-100 rounded px-3 py-2 text-gray-600 hover:text-gray-900 transition ease-in-out duration-150 flex justify-center items-center group'
                                        >
                                            <Icon
                                                type='solid'
                                                name='plus'
                                                className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                                            />

                                            <span>{t('Create Show')}</span>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isInner && (
                <div className='w-full max-w-5xl pl-6'>
                    <Link
                        className='text-sm font-medium flex flex-shrink-0 group items-center px-1 md:px-2 pt-3 pb-2 hover:text-black border-b-2 border-transparent'
                        to='/'
                    >
                        <Icon
                            name='arrow-left'
                            className='w-4 h-4 mr-1 text-gray-400 group-hover:text-gray-600 transition duration-300'
                        />
                        {t('Back to your Account')}
                    </Link>
                    {show &&
                        loc.pathname.includes('/shows/') &&
                        !loc.pathname.includes('/import') &&
                        !loc.pathname.includes('/create') && (
                            <div className='group flex items-center'>
                                <Link
                                    to={`/shows/${show.id}`}
                                    className='flex-center text-sm font-medium px-1 pt-4 pb-0 leading-0 rounded text-gray-600 hover:text-gray-900'
                                >
                                    <svg
                                        fill='none'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        stroke='currentColor'
                                        viewBox='0 0 24 24'
                                        className='mr-1 w-5 h-5 text-gray-400 group-hover:text-gray-500'
                                    >
                                        <path d='M9 5l7 7-7 7'></path>
                                    </svg>
                                    <span className='pt-1 uppercase tracking-wide text-xs leading-0'>
                                        Show:{' '}
                                    </span>
                                    <span className='ml-2 font-medium leading-0'>
                                        {truncate(show.title, 60)}
                                    </span>
                                </Link>
                            </div>
                        )}

                    {show &&
                        episode &&
                        loc.pathname.includes('/episodes/') &&
                        !loc.pathname.includes('/episodes/create') && (
                            <div className='ml-4 group flex items-center'>
                                <Link
                                    to={`/shows/${show.id}/episodes/${episode.id}`}
                                    className='flex-center text-sm font-medium px-3 pt-4 pb-2 leading-0 rounded text-gray-600 hover:text-gray-900'
                                >
                                    <svg
                                        fill='none'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        stroke='currentColor'
                                        viewBox='0 0 24 24'
                                        className='mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500'
                                    >
                                        <path d='M9 5l7 7-7 7'></path>
                                    </svg>
                                    <span className='pt-1 uppercase tracking-wide text-xs leading-0'>
                                        episode:{' '}
                                    </span>
                                    <span className='ml-2 font-medium leading-0'>
                                        {truncate(episode.title, 60)}
                                    </span>
                                </Link>
                            </div>
                        )}
                </div>
            )}
            <nav className='w-full max-w-5xl flex items-center justify-center md:justify-between mx-auto '>
                <div className='items-center flex w-full overflow-y-hidden overflow-x-auto text-sm'>
                    {menu &&
                        menu.map(r => {
                            return (
                                <Link
                                    partiallyActive={r.title === 'Settings'} //@Todo === "Settings" WTF??
                                    className='flex flex-shrink-0 group items-center mx-2 px-1 md:px-2 py-4 hover:text-black border-b-2 border-transparent'
                                    activeClassName='text-gray-800 border-gray-900'
                                    key={r.to}
                                    to={r.to}
                                    replace
                                >
                                    <Icon
                                        name={r.icon}
                                        className='w-4 h-4 mr-1 text-gray-400 group-hover:text-gray-600 transition duration-300'
                                    />
                                    {t(r.title)}
                                </Link>
                            )
                        })}
                </div>
                {!loading && (
                    <div className='hidden md:flex justify-center md:justify-end items-center text-sm font-medium'>
                        {show ? (
                            <Link
                                to={`/shows/${show.id}/create`}
                                className='flex-shrink-0 bg-gray-800 hover:bg-white text-white rounded my-1 px-3 py-2 leading-none border border-transparent hover:border-gray-900 hover:text-gray-900 transition ease-in-out duration-150 flex justify-center items-center group'
                            >
                                <svg
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                    className='w-4 h-4 mr-1 text-gray-100 group-hover:text-gray-800 transition duration-300 transform group-hover:rotate-90'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                <span>{t('Create Episode')}</span>
                            </Link>
                        ) : (
                            <div className='flex justify-end items-center space-x-3 font-medium'>
                                <Link
                                    to={`/import`}
                                    className='flex-shrink-0 hover:bg-gray-100 rounded px-3 py-2 text-gray-600 hover:text-gray-900 transition ease-in-out duration-150 flex justify-center items-center justify-center group'
                                >
                                    <Icon
                                        type='solid'
                                        name='download'
                                        className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                                    />
                                    <span>{t('Import Show')}</span>
                                </Link>
                                <Link
                                    to={`/create`}
                                    className='flex-shrink-0 hover:bg-gray-100 rounded px-3 py-2 text-gray-600 hover:text-gray-900 transition ease-in-out duration-150 flex justify-center items-center justify-center group'
                                >
                                    <Icon
                                        type='solid'
                                        name='plus'
                                        className='w-4 h-4 mr-1 text-gray-500 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                                    />

                                    <span>{t('Create Show')}</span>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </nav>
        </header>
    )
}
