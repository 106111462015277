import React from 'react'
import useClipboard from '../hooks/clipboard.js'
import PopupText from '../components/popup-text.js'
import Tutorial from '../components/tutorial'
import { useSelector } from 'react-redux'
// import { validateBilling } from '../util.js'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const getStatus = (show, shows, paymentMethods) => {
    // if (!validateBilling(shows, paymentMethods)) return 'needs billing'
    // @Todo implement new needs billing
    if (show.isRedirected) return 'redirected'
    if (!show.feedStatus) return 'pending'
    return show.feedStatus
}

export default props => {
    const { show, hideTitle } = props
    const { t } = useTranslation()

    const { paymentMethods, shows } = useSelector(s => {
        return {
            paymentMethods: s.paymentMethods,
            shows: s.shows,
        }
    })

    const [copied, setCopied] = useClipboard()
    const [modalOpen, setModalOpen] = React.useState(false)
    const handleCopy = () => show.feedUrl && setCopied(show.feedUrl)

    const showStatus = getStatus(show, shows, paymentMethods)
    return (
        <div className={props.className || 'pb-5'}>
            <div className='text-sm font-medium group pb-1'>
                {!hideTitle && (
                    <div className='flex items-center space-x-1'>
                        <span className='font-medium text-sm'>{t('Feed URL')} </span>
                        <PopupText
                            position='right'
                            inline={true}
                            text={t('feed-explainer')}
                            textClass=''
                            iconClass='mt-1 h-4 w-4'
                        />
                    </div>
                )}
                <div className='border rounded overflow-hidden mt-1 flex'>
                    <div
                        className={`hidden sm:inline-flex font-normal group capitalize space-x-1 border-r flex items-center px-4 cursor-default w-auto bg-white ${
                            showStatus === 'active' ? 'text-green-500' : 'text-red-600'
                        }`}
                    >
                        <span>&#x25cf;</span>
                        <span>{t(showStatus)}</span>
                    </div>
                    <input
                        readOnly
                        placeholder='Feed creation in progress, Please wait.'
                        defaultValue={show.feedUrl}
                        type='text'
                        className='form-input font-mono rounded-none border-none flex-1 w-full text-sm text-gray-800'
                    />
                    <button
                        aria-label='copy to clipboard'
                        onClick={handleCopy}
                        className={`${
                            copied ? 'bg-green-500 text-white' : 'text-gray-500 hover:text-gray-800'
                        } w-24 transition duration-300 border-l px-1 py-2 text-sm focus:outline-none `}
                    >
                        <div className='flex-center w-full'>
                            {!copied && (
                                <svg
                                    className='w-4 h-4 mr-1'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                >
                                    <path d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2'></path>
                                </svg>
                            )}
                            <span className=''>{copied ? t('Copied!') : t('Copy')}</span>
                        </div>
                    </button>
                </div>
                {show.isRedirected && (
                    <div className='mx-auto w-full mt-1'>
                        <div className='mx-auto text-center font-medium text-xs border border-yellow-200 bg-yellow-50 text-black px-4 rounded-sm'>
                            <div className='space-x-1 flex justify-center items-center'>
                                <div className='flex justify-center items-center'>
                                    <svg
                                        className='w-5 h-5 text-yellow-300'
                                        fill='currentColor'
                                        viewBox='0 0 20 20'
                                    >
                                        <path
                                            d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
                                            clipRule='evenodd'
                                            fillRule='evenodd'
                                        ></path>
                                    </svg>
                                </div>
                                <p className='font-normal'>
                                    This feed URL is redirecting to {show.redirectUrl}.{' '}
                                    <Link
                                        className='underline font-medium'
                                        to={`/shows/${show.id}/settings/advanced`}
                                    >
                                        Click Here
                                    </Link>{' '}
                                    to change
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className='sm:flex justify-end items-center'>
                <button
                    className='block text-center hover:border-gray-400 border-b border-transparent text-blue-600 hover:text-blue-800 font-normal text-sm focus:outline-none focus:shadow-outline'
                    onClick={() => setModalOpen(true)}
                >
                    {t('how-to-submit-explainer')}
                </button>

                <div
                    className={`${
                        modalOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
                    } transition duration-100 fixed z-50 w-full h-full top-0 left-0 flex items-center justify-center`}
                >
                    <div className='absolute w-full h-full bg-gray-900 opacity-50' />
                    <div className='bg-white md:max-w-3xl md:mx-auto rounded shadow-lg px-2 sm:px-6 py-2 z-50 h-full sm:h-auto overflow-y-auto'>
                        {modalOpen && (
                            <div className='pb-4'>
                                <div className='pt-4'>
                                    <p className='text-lg text-gray-800 font-medium'>
                                        {t('how-to-submit-explainer')}
                                    </p>
                                </div>
                                <div className='pb-4 text-left'>
                                    {show && (
                                        <div className='mt-3'>
                                            <div className='flex items-center space-x-1'>
                                                <span className='font-medium text-sm'>
                                                    {t('Your Feed URL')}{' '}
                                                </span>
                                            </div>
                                            <div className='border border-gray-200 rounded overflow-hidden mt-1 flex'>
                                                <input
                                                    defaultValue={show.feedUrl}
                                                    readOnly
                                                    className='form-input rounded-none border-none flex-1 w-full text-sm text-gray-800'
                                                />
                                                <button
                                                    aria-label='copy to clipboard'
                                                    onClick={handleCopy}
                                                    className={`${
                                                        copied
                                                            ? 'bg-green-500 text-white'
                                                            : 'text-gray-500 hover:text-gray-800'
                                                    } w-14 transition duration-300 border-l border-gray-200 px-1 py-2 text-sm focus:outline-none `}
                                                >
                                                    <div className='flex-center w-full'>
                                                        <svg
                                                            className={`w-4 h-4 ${
                                                                copied && 'text-white'
                                                            }`}
                                                            fill='none'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            strokeWidth='2'
                                                            stroke='currentColor'
                                                            viewBox='0 0 24 24'
                                                        >
                                                            <path d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2'></path>
                                                        </svg>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <Tutorial show={show} />
                                    </div>
                                </div>
                                <div className='flex justify-end items-end text-sm pt-6'>
                                    <button
                                        onClick={() => setModalOpen(false)}
                                        className={`px-6 py-2 bg-gray-100 rounded text-gray-600 hover:bg-gray-200 hover:text-gray-900 mr-2`}
                                    >
                                        {t('Dismiss')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
