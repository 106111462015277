import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

const EmptyEpisodes = props => {
    const { t } = useTranslation()
    return (
        <div className='bg-white shadow-xl border-t-4 border-gray-600 rounded-md mt-8 mx-2 px-8 py-12 flex flex-col items-center'>
            <div className='flex items-center'>
                <div className='flex-center w-16 h-16 rounded-full bg-gray-100 text-gray-500 mr-1'>
                    <svg fill='currentColor' viewBox='0 0 20 20' className='w-6 h-6'>
                        <path
                            fillRule='evenodd'
                            d='M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z'
                            clipRule='evenodd'
                        />
                    </svg>
                </div>
                <span className='text-xl text-gray-600 ml-1 '>{t('No Episodes')}</span>
            </div>
            <p className='text-center text-sm text-gray-600 mt-2'>
                {t("You haven't created any episodes yet.")}
                <br /> {t('Try creating one.')}
            </p>
            <div className='flex-center mt-8'>
                <Link
                    to={`/shows/${props.id}/create`}
                    className='border border-gray-800 rounded px-6 py-2 hover:text-gray-800 text-white text-sm hover:bg-white bg-gray-800 group flex-center transition duration-150'
                >
                    <svg
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        className='w-4 h-4 mr-1 text-gray-200 group-hover:text-gray-600 transition duration-300 transform group-hover:rotate-90'
                    >
                        <path
                            fillRule='evenodd'
                            d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
                            clipRule='evenodd'
                        />
                    </svg>
                    <span>{t('Create Episode')}</span>
                </Link>
            </div>
        </div>
    )
}
export default EmptyEpisodes
