import React from 'react'
import { useLocation } from '@reach/router'
import { trackError } from '../util'

const useQueryParams = () => {
    const loc = useLocation()
    const result = {}
    try {
        const sParams = new URLSearchParams(loc.search)
        for (let item of sParams.entries()) {
            result[item[0]] = item[1]
        }
    } catch (err) {
        trackError(err)
    }
    return result
}

export default useQueryParams
