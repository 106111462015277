import React from 'react'
import { useTranslation } from 'react-i18next'

export default props => {
    const { t } = useTranslation()
    return (
        <div className='my-4 flex items-center justify-center'>
            <svg
                viewBox='0 0 20 20'
                fill='currentColor'
                className='w-8 h-8 mr-4 shake text-red-400 bg-red-50 rounded-full p-2'
            >
                <path
                    fill-rule='evenodd'
                    d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z'
                    clip-rule='evenodd'
                ></path>
            </svg>
            <span>{t('You do not have permission to view this page')}</span>
        </div>
    )
}
