import React from 'react'
import { Translation, useTranslation } from 'react-i18next'

export default props => {
    const { t } = useTranslation()
    const [openId, setOpenId] = React.useState('apple')
    const changeId = id => {
        if (openId !== id) {
            setOpenId(id)
        } else {
            setOpenId('none')
        }
    }

    const renderFeedUrl = () => {
        if (props.show && props.show.feedUrl) {
            return (
                <>
                    <span className='hidden sm:inline bg-gray-100 rounded-sm px-4 py-2 leading-none font-medium text-xs'>
                        {props.show.feedUrl}
                    </span>
                    <span className='sm:hidden'>
                        your <strong>Feed URL </strong> from above
                    </span>
                </>
            )
        }
        return (
            <span className=''>
                your <strong>Feed URL </strong> from above
            </span>
        )
    }

    return (
        <article className='py-2 space-y-1'>
            <div className='border border-gray-100 rounded px-1'>
                <button
                    onClick={() => changeId('apple')}
                    className={`w-full px-3 py-2 font-medium focus:outline-none flex ${
                        openId !== 'apple' && 'hover:bg-gray-100'
                    }`}
                >
                    <span className='text-left flex-1'>{t('Submit to Apple Podcasts')}</span>
                    <svg viewBox='0 0 20 20' fill='currentColor' className='w-6 h-6 text-gray-400'>
                        {openId === 'apple' ? (
                            <path
                                fillRule='evenodd'
                                d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
                                clipRule='evenodd'
                            ></path>
                        ) : (
                            <path
                                fillRule='evenodd'
                                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                clipRule='evenodd'
                            ></path>
                        )}
                    </svg>
                </button>
                {openId === 'apple' && (
                    <div className='ml-3 prose prose-sm'>
                        <ol>
                            <li>
                                <Translation>
                                    {(t, { i18n }) => {
                                        //@Todo very hacky way of doing jp translations here.
                                        //Try using the i18n Trans Component

                                        if (i18n.language === 'jp') {
                                            return (
                                                <>
                                                    <a
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        href='https://podcastsconnect.apple.com/'
                                                    >
                                                        https://podcastsconnect.apple.com/
                                                    </a>{' '}
                                                    をクリックしてAppleIDをログインしてください。
                                                </>
                                            )
                                        }
                                        return (
                                            <>
                                                Go to{' '}
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://podcastsconnect.apple.com/'
                                                >
                                                    https://podcastsconnect.apple.com/
                                                </a>{' '}
                                                and login with your Apple ID or create one.
                                            </>
                                        )
                                    }}
                                </Translation>
                            </li>
                            <li>
                                <Translation>
                                    {(t, { i18n }) => {
                                        //@Todo very hacky way of doing jp translations here.
                                        //Try using the i18n Trans Component

                                        if (i18n.language === 'jp') {
                                            return (
                                                <>
                                                    "New Feed"
                                                    エリアにあなたのフィードURL入力してください。
                                                    {renderFeedUrl() || 'あなたのフィードURL'}
                                                </>
                                            )
                                        }
                                        return (
                                            <>
                                                In the "New Feed" area input{' '}
                                                {renderFeedUrl() || 'the Feed URL from above'}
                                            </>
                                        )
                                    }}
                                </Translation>
                            </li>
                            <li>{t('click-validate-apple')}</li>
                            <li>{t('Click Submit and wait for approval (~1 week)')}</li>
                        </ol>
                    </div>
                )}
            </div>
            <div className='border border-gray-100 rounded px-1'>
                <button
                    onClick={() => changeId('spotify')}
                    className={`w-full px-3 py-2 font-medium focus:outline-none flex ${
                        openId !== 'spotify' && 'hover:bg-gray-100'
                    }`}
                >
                    <span className='text-left flex-1'>{t('Submit to Spotify')}</span>
                    <svg viewBox='0 0 20 20' fill='currentColor' className='w-6 h-6 text-gray-400'>
                        {openId === 'spotify' ? (
                            <path
                                fillRule='evenodd'
                                d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
                                clipRule='evenodd'
                            ></path>
                        ) : (
                            <path
                                fillRule='evenodd'
                                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                clipRule='evenodd'
                            ></path>
                        )}
                    </svg>
                </button>
                {openId === 'spotify' && (
                    <div className='ml-3 prose prose-sm'>
                        <Translation>
                            {(t, { i18n }) => {
                                //@Todo very hacky way of doing jp translations here.
                                //Try using the i18n Trans Component

                                if (i18n.language === 'jp') {
                                    return (
                                        <p>
                                            <a
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href='https://podcasters.spotify.com/'
                                            >
                                                https://podcasters.spotify.com/
                                            </a>{' '}
                                            をクリックしてSpotifyにログインしてくさだい。
                                        </p>
                                    )
                                }
                                return (
                                    <p>
                                        Login to{' '}
                                        <a
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href='https://podcasters.spotify.com/'
                                        >
                                            https://podcasters.spotify.com/
                                        </a>{' '}
                                        with your Spotify account, or create a new account. Then,
                                        follow these steps:
                                    </p>
                                )
                            }}
                        </Translation>
                        <ol>
                            <li>
                                <Translation>
                                    {(t, { i18n }) => {
                                        //@Todo very hacky way of doing jp translations here.
                                        //Try using the i18n Trans Component

                                        if (i18n.language === 'jp') {
                                            return <>"GET STARTED"をクリックしてください。</>
                                        }
                                        return (
                                            <>
                                                Click <strong>GET STARTED</strong>.
                                            </>
                                        )
                                    }}
                                </Translation>
                            </li>
                            <li>
                                <Translation>
                                    {(t, { i18n }) => {
                                        //@Todo very hacky way of doing jp translations here.
                                        //Try using the i18n Trans Component

                                        if (i18n.language === 'jp') {
                                            return (
                                                <>
                                                    "New Feed"
                                                    エリアにあなたのフィードURL入力してください。
                                                    {renderFeedUrl() || 'あなたのフィードURL'}
                                                </>
                                            )
                                        }
                                        return (
                                            <>
                                                In the input field insert{' '}
                                                {renderFeedUrl() || 'your Feed URL from above'}
                                            </>
                                        )
                                    }}
                                </Translation>
                            </li>
                            <li>{t('spotify-verify-email-explainer')}</li>
                            <li>{t('spotify-category-explainer')}</li>
                            <li>{t('spotify-submit-explainer')}</li>
                        </ol>
                    </div>
                )}
            </div>

            <div className='border border-gray-100 rounded px-1'>
                <button
                    onClick={() => changeId('google')}
                    className={`w-full px-3 py-2 font-medium focus:outline-none flex ${
                        openId !== 'google' && 'hover:bg-gray-100'
                    }`}
                >
                    <span className='text-left flex-1'>{t('Submit to Google Podcasts')}</span>
                    <svg viewBox='0 0 20 20' fill='currentColor' className='w-6 h-6 text-gray-400'>
                        {openId === 'google' ? (
                            <path
                                fillRule='evenodd'
                                d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
                                clipRule='evenodd'
                            ></path>
                        ) : (
                            <path
                                fillRule='evenodd'
                                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                clipRule='evenodd'
                            ></path>
                        )}
                    </svg>
                </button>
                {openId === 'google' && (
                    <div className='ml-3 prose prose-sm'>
                        <ol>
                            <li>
                                <Translation>
                                    {(t, { i18n }) => {
                                        //@Todo very hacky way of doing jp translations here.
                                        //Try using the i18n Trans Component

                                        if (i18n.language === 'jp') {
                                            return (
                                                <>
                                                    <a
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        href='https://podcastsmanager.google.com/'
                                                    >
                                                        https://podcastsmanager.google.com/
                                                    </a>{' '}
                                                    をクリックしてGoogle にログインしてくさだい。
                                                </>
                                            )
                                        }
                                        return (
                                            <>
                                                Go to{' '}
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://podcastsmanager.google.com/'
                                                >
                                                    https://podcastsmanager.google.com/
                                                </a>{' '}
                                                and login with your google account
                                            </>
                                        )
                                    }}
                                </Translation>
                            </li>
                            <li>{t('Click Start Now')}</li>
                            <li>
                                <Translation>
                                    {(t, { i18n }) => {
                                        //@Todo very hacky way of doing jp translations here.
                                        //Try using the i18n Trans Component

                                        if (i18n.language === 'jp') {
                                            return (
                                                <>
                                                    "New Feed"
                                                    エリアにあなたのフィードURL入力してください。
                                                    {renderFeedUrl() || 'あなたのフィードURL'}
                                                </>
                                            )
                                        }
                                        return (
                                            <>
                                                In the input field insert{' '}
                                                {renderFeedUrl() || 'your Feed URL from above'}
                                            </>
                                        )
                                    }}
                                </Translation>
                            </li>
                            <li>{t('follow-next-instructions')}</li>
                            <li>{t('spotify-submit-explainer')}</li>
                        </ol>
                    </div>
                )}
            </div>
        </article>
    )
}
