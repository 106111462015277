import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import prettyBytes from 'pretty-bytes'
import SEO from '../components/seo.js'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { usePermissions } from '../hooks/permissions.js'
import NoPermissions from '../components/no-permissions.js'

const nullUsage = {
    periodEnd: null,
    periodStart: null,
    bytesSent: 0,
}

export default props => {
    const { t } = useTranslation()
    const canView = usePermissions('usage:read')
    const { show, usage } = useSelector(s => {
        return {
            show: s.shows.data[props.id],
            usage: s.showUsage[props.id] || { loading: true },
        }
    })
    const currentUsage = usage.data || nullUsage

    let startDate
    let endDate
    if (currentUsage.periodStart) {
        startDate = new Date(currentUsage.periodStart * 1000)
    }
    if (currentUsage.periodEnd) {
        endDate = new Date(currentUsage.periodEnd * 1000)
    }

    return (
        <div className='space-y-4'>
            <SEO title={t('Show Usage')} />
            <div className='text-center bg-white rounded-md shadow-xl mt-8 border-t-4 border-gray-600 pt-8 pb-12'>
                <h1 className='text-2xl'>Usage {show && `for show: ${show.title}`}</h1>
                <p className='text-sm text-gray-600'>{t('Summary of the usage on this show')}</p>

                {usage.loading ? (
                    <div className='w-full mt-2 flex-center mx-auto'>
                        <svg className='h-8 w-8 spin' viewBox='0 0 24 24'>
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </div>
                ) : canView ? (
                    <>
                        <div>
                            {startDate && endDate && (
                                <span className='inline-block text-sm text-red-500 uppercase tracking-wide mt-6'>
                                    {format(startDate, 'PP')} - {format(endDate, 'PP')}{' '}
                                </span>
                            )}
                            <ul className='flex justify-center items-center mt-4 '>
                                <li className='flex flex-col w-48'>
                                    <span className='text-2xl tracking-wide uppercase'>
                                        {prettyBytes(currentUsage.bytesSent)}
                                    </span>
                                    <span className='text-sm text-gray-600'>{t('Bandwidth')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className='mt-10'>
                            <h1 className='font-medium text-sm'>{t('Past Usage and Invoices')}</h1>
                            <span className='inline-block text-sm mt-2'>
                                To view your current period bill and invoices go to{' '}
                                <Link
                                    className='italic font-medium border-gray-500 hover:text-teal-500 hover:border-teal-400 transition duration-150 border-b-2 py-1'
                                    to='/usage'
                                >
                                    Your Account
                                </Link>{' '}
                                Usage Page
                            </span>
                        </div>
                    </>
                ) : (
                    <NoPermissions />
                )}
            </div>
        </div>
    )
}
