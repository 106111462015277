import React from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../state/actions.js'
import SettingPanel from '../../components/setting-panel.js'
import { useTranslation } from 'react-i18next'

//@Todo copy paste getExtension
const getExtension = file => {
    if (!file) return null
    const ext = file.name.split('.').pop()
    return ext
}

export default props => {
    const { t } = useTranslation()
    const { activeTeamId, auth, episode } = useSelector(s => {
        const result = {
            auth: s.auth,
            activeTeamId: s.activeTeamId,
        }
        const showEp = s.showEpisodes[props.id]
        if (showEp) {
            result.episode = showEp.data[props.epId]
        }
        return result
    })
    const [error, setError] = React.useState(null)
    const [newMediaUrl, setNewMediaUrl] = React.useState(null)
    const [mediaFile, setMediaFile] = React.useState(null)
    const [updating, setUpdating] = React.useState(false)
    const [uploadProgress, setUploadProgress] = React.useState(0)
    const [changed, setChanged] = React.useState(false)
    const mediaRef = React.useRef()
    const handleChange = e => {
        if (e.target.name === 'mediaFile') {
            if (e.target.files && e.target.files.length) {
                setNewMediaUrl(URL.createObjectURL(e.target.files[0]))
                setMediaFile(e.target.files[0])
                setChanged(newMediaUrl !== episode.url)
            }
        }
    }
    const handleSubmit = async e => {
        e.preventDefault()
        setUpdating(true)
        setError(null)
        //@Todo current
        try {
            const data = {
                updatedBy: auth.data.id,
            }

            //Set the duration before submitting
            if (mediaRef.current && mediaRef.current.duration) {
                data.mediaDuration = parseInt(mediaRef.current.duration)
            }
            if (mediaFile) {
                data.mediaSize = parseInt(mediaFile.size)
                data.mediaExtension = getExtension(mediaFile)
            }

            await actions.updateEpisode(activeTeamId, props.id, props.epId, data)

            await actions.setEpisodeMedia(activeTeamId, props.id, props.epId, mediaFile, v =>
                setUploadProgress(v * 100),
            )
        } catch (err) {
            setError(err.message)
        } finally {
            setUpdating(false)
            setUploadProgress(0)
            setChanged(false)
        }
    }
    if (!episode) return null
    return (
        <SettingPanel title={t('Episode Media')}>
            <SettingPanel.Content>
                <div className='flex flex-col sm:flex-row items-stretch justify-center mt-2'>
                    {episode.url && (
                        <div className='flex-1 sm:mr-3 rounded overflow-hidden flex-center'>
                            <audio
                                preload='metadata'
                                ref={mediaRef}
                                src={newMediaUrl || episode.url}
                                controls={true}
                                className={`${
                                    episode.url ? '' : 'hidden'
                                } flex-1 sm:mr-3 text-center text-sm font-medium px-3 bg-gray-100 rounded overflow-hidden flex-center`}
                            >
                                <track kind='captions' />
                            </audio>
                        </div>
                    )}

                    <div
                        className={`${
                            !!episode.url ? 'sm:w-1/2' : 'w-full'
                        } overflow-hidden relative rounded border border-dashed`}
                    >
                        <label className='h-full text-center text-sm flex flex-col justify-center items-center px-4 py-6 rounded-lg shadow-lg cursor-pointer'>
                            <svg
                                fill='currentColor'
                                viewBox='0 0 20 20'
                                className='text-teal-500 w-8 h-8'
                            >
                                <path
                                    fillRule='evenodd'
                                    d='M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z'
                                    clipRule='evenodd'
                                />
                            </svg>
                            <span className='mt-2'>{t('Upload a file or drag and drop')}</span>
                            <span className='block sm:ml-2 text-sm'>
                                {t('Upload audio (Recommended type .mp3)')}
                            </span>
                            <input
                                onChange={handleChange}
                                name='mediaFile'
                                type='file'
                                className='hidden'
                                accept='.mp3,.m4a,.aac,.mp4,.m4p,.m4r,.ogg,.oga,.wma'
                            />
                        </label>
                    </div>
                </div>
            </SettingPanel.Content>
            <SettingPanel.Error error={error} />
            <SettingPanel.Actions>
                <button
                    onClick={handleSubmit}
                    disabled={updating}
                    className={`${updating ? 'pointer-events-none' : ''} ${
                        changed ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'
                    } relative transition duration-150 font-medium border rounded px-3 py-1 focus:outline-none focus:shadow-outline hover:text-gray-800 text-white text-sm hover:bg-white ${
                        uploadProgress > 99.9 && 'border-green-400 bg-green-400'
                    }`}
                >
                    <span className='absolute inset-0 w-full h-full flex-center text-xs'>
                        <svg
                            className={`h-6 w-6 spin ${updating ? 'opacity-100' : 'opacity-0'}`}
                            viewBox='0 0 24 24'
                        >
                            <path
                                className='text-gray-600'
                                fill='currentColor'
                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                            />
                            <path
                                className='text-gray-400'
                                fill='currentColor'
                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                            />
                        </svg>
                    </span>
                    <span>
                        {updating
                            ? `${t('Uploading')}: ${uploadProgress.toFixed(0)}%`
                            : t('Update')}
                    </span>
                </button>
            </SettingPanel.Actions>
        </SettingPanel>
    )
}
