import React from 'react'
import { Link } from 'gatsby'
const EpisodeLabel = ({ showId, epId, showEpisodes }) => {
    let result = epId
    let found = false
    if (showEpisodes && showEpisodes.data) {
        const ep = showEpisodes.data[epId]
        if (ep) {
            result = ep.title
            found = true
        }
    }

    return found ? (
        <Link
            className='hover:border-gray-700 border-b border-transparent'
            to={`/shows/${showId}/episodes/${epId}/analytics`}
        >
            {result}
        </Link>
    ) : (
        <p className='cursor-default text-gray-400'>{result}</p>
    )
}
export default EpisodeLabel
