import React from 'react'
import { Line } from 'rc-progress'
import { useTranslation } from 'react-i18next'

export default ({ show }) => {
    const { t } = useTranslation()

    if (!show) return null

    let percent = 0
    if (show.importTotalCount > 0) {
        percent = 100 * ((show.importProgress || 0) / show.importTotalCount)
    }

    if (!show.importTotalCount) return null
    if (show.importStatus === 'complete') return null

    return (
        <div className='shadow-md px-4 py-4 rounded mb-4'>
            <div className='flex items-center justify-between py-1'>
                <div className='flex items-center'>
                    <span className='font-medium text-sm mr-1'>
                        {t('Importing Episodes. This could take while.')}
                    </span>
                    <svg className={`h-5 w-5 spin`} viewBox='0 0 24 24'>
                        <path
                            className='text-gray-600'
                            fill='currentColor'
                            d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                        />
                        <path
                            className='text-gray-400'
                            fill='currentColor'
                            d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                        />
                    </svg>
                </div>
                <span className='text-sm italic font-medium'>
                    {t('Processing')}: {show.importProgress} {t('of')} {show.importTotalCount}
                </span>
            </div>
            <Line percent={percent} strokeWidth='1' strokeColor='#252f3f' />
        </div>
    )
}
