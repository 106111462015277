import React from 'react'
import { useOnClickOutside } from '../hooks/on-click-outside.js'
import firebase from '../lib/firebase'
import { Link, navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import Icon from '../components/icon.js'
import { useTranslation } from 'react-i18next'

export default props => {
    const auth = useSelector(s => s.auth)

    const [open, setOpen] = React.useState(false)
    const ref = React.useRef()
    useOnClickOutside(ref, () => setOpen(false))
    const { t } = useTranslation()

    //Auto close dropdown while navigating
    React.useEffect(() => {
        !open && setOpen(false)
    }, [open])

    const handleLogout = e => {
        firebase.auth().signOut()
        navigate('/')
    }

    if (!auth.data || !auth.data.id) return null

    return (
        <div ref={ref} className='relative'>
            <div>
                <span className='rounded-md'>
                    <button
                        aria-label='Toggle Dropdown'
                        onClick={() => setOpen(!open)}
                        type='button'
                        className='inline-flex justify-center h-8 w-8 rounded-full overflow-hidden shadow-solid text-gray-600 hover:text-gray-800 focus:outline-none focus:border-gray-300 focus:shadow-outline active:text-gray-900 transition ease-in-out duration-150'
                    >
                        {auth.data.photoUrl ? (
                            <img
                                className='inline-block h-full w-full text-white'
                                width='24'
                                height='24'
                                src={auth.data.photoUrl}
                                alt=''
                            />
                        ) : (
                            <svg
                                className='my-auto h-5 w-5 text-gray-500'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke='currentColor'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
                                />
                            </svg>
                        )}
                    </button>
                </span>
            </div>

            {open && (
                <div className='origin-top-right z-20 absolute overflow-hidden right-0 text-sm text-gray-500 hover:text-gray-900 w-48 rounded shadow-2xl'>
                    <div className='rounded bg-white shadow-xs py-2 overflow-hidden'>
                        <div className='bg-gray-100 rounded'>
                            <p className='px-4 py-3 text-xs'>
                                Logged in as:
                                <br />
                                <strong className='font-medium'>{auth.data.email} </strong>
                            </p>
                        </div>
                        <div className='border-t border-gray-100' />
                        <div className=''>
                            <Link
                                to='/'
                                onClick={() => setOpen(!open)}
                                className='group flex items-center space-x-2 block px-4 py-3 leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                            >
                                <Icon
                                    name='collection'
                                    className='h-4 w-4 text-gray-400 group-hover:text-gray-500'
                                />
                                <span className=''>{t('Shows')}</span>
                            </Link>
                        </div>
                        <div className='border-t border-gray-100' />
                        <div className=''>
                            <Link
                                to='/usage'
                                onClick={() => setOpen(!open)}
                                className='group flex items-center space-x-2 block px-4 py-3 leading-5  hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                            >
                                <Icon
                                    name='chart-bar'
                                    className='h-4 w-4 text-gray-400 group-hover:text-gray-500'
                                />
                                <span className=''>{t('Usage')}</span>
                            </Link>
                        </div>
                        <div className='border-t border-gray-100' />

                        <div className=''>
                            <a
                                onClick={() => setOpen(!open)}
                                className='group flex items-center space-x-2 block px-4 py-3 leading-5  hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                                href='https://mono.fm/docs'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <Icon
                                    name='docs'
                                    className='h-4 w-4 text-gray-400 group-hover:text-gray-500'
                                />
                                <span className=''>{t('Docs')}</span>
                            </a>
                        </div>
                        <div className='border-t border-gray-100' />
                        <div className=''>
                            <a
                                onClick={() => setOpen(!open)}
                                className='group flex items-center space-x-2 block px-4 py-3 leading-5  hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                                href='https://mono.fm/blog'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <Icon
                                    name='blog'
                                    className='h-4 w-4 text-gray-400 group-hover:text-gray-500'
                                />
                                <span className=''>{t('Blog')}</span>
                            </a>
                        </div>

                        <div className='border-t border-gray-100' />
                        <div className=''>
                            <a
                                onClick={() => setOpen(!open)}
                                rel='noopener noreferrer'
                                href='mailto:support@mono.fm'
                                className='group flex items-center space-x-2 block px-4 py-3 leading-5  hover:bg-gray-100 group focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                            >
                                <Icon
                                    name='mail'
                                    className='h-4 w-4 text-gray-400 group-hover:text-gray-500'
                                />
                                <span className=''>{t('Support')}</span>
                            </a>
                        </div>
                        <div className='border-t border-gray-100' />
                        <div className=''>
                            <Link
                                onClick={() => setOpen(!open)}
                                to='/settings'
                                className='group flex items-center space-x-2 block px-4 py-3 leading-5  hover:bg-gray-100 group focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                            >
                                <Icon
                                    name='settings'
                                    className='h-4 w-4 text-gray-400 group-hover:text-gray-500'
                                />
                                <span className=''>{t('Settings')}</span>
                            </Link>
                        </div>
                        <div className='border-t border-gray-100' />
                        <div className=''>
                            <button
                                onClick={handleLogout}
                                className='group flex items-center space-x-2 block w-full text-left px-4 py-3 leading-5 hover:bg-gray-100 focus:outline-none font-medium focus:bg-gray-100 focus:text-gray-900'
                            >
                                <Icon
                                    name='logout'
                                    className='h-4 w-4 text-gray-400 group-hover:text-gray-500'
                                />
                                <span className=''>{t('Logout')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
